import React, { Suspense } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

import Push from '../../app-views/message/push';

import Sms from '../../app-views/message/sms';

export const BlankViews = (props) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        <Route exact path='/push/*' element={<Push {...props}/>} />
        <Route exact path='/sms/*'  element={<Sms {...props}/>} />  
        <Route exact path='*' element={<Navigate to="/notfound" />} />
      </Routes>
    </Suspense>
  );
};

export default BlankViews;
