import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

const IntlMessage = (props) => <FormattedMessage {...props} />;
// const IntlMessage = (props) => {
//   const { formatMessage } = useIntl();
//   return formatMessage({ id: props.id });
// };

export default injectIntl(IntlMessage, {
  withRef: false,
});
