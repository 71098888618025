import React from 'react';
import { ReadOutlined } from '@ant-design/icons';
import { Card } from 'antd';
import { FormattedMessage } from 'react-intl';
import { guideConfig } from '../../../config';
import utils from 'utils';

const Jobmanagement = () => {
  const isKo = utils.getCurrentLanguage() === 'ko';

  return (
    <Card>
      <h4 className="mb-4">
        <FormattedMessage id="guide.title" />
      </h4>
      <div className="mb-4">
        <ReadOutlined className="mr-2" />
        <a
          href={
            isKo
              ? guideConfig?.dashboard?.guide?.ko
              : guideConfig?.dashboard?.guide?.en
          }
          target="_blank"
          className="text-reset"
        >
          <FormattedMessage id="guide.dashboard" />
        </a>
      </div>
      <div className="mb-4">
        <ReadOutlined className="mr-2" />
        <a
          href={isKo ? guideConfig?.aos?.ko : guideConfig?.aos?.en}
          target="_blank"
          className="text-reset"
        >
          <FormattedMessage id="guide.aos" />
        </a>
      </div>
      <div className="mb-4">
        <ReadOutlined className="mr-2" />
        <a
          href={isKo ? guideConfig?.ios?.ko : guideConfig?.ios?.en}
          target="_blank"
          className="text-reset"
        >
          <FormattedMessage id="guide.ios" />
        </a>
      </div>
      <div className="mb-4">
        <ReadOutlined className="mr-2" />
        <a
          href={
            isKo ? guideConfig?.javascript?.ko : guideConfig?.javascript?.en
          }
          target="_blank"
          className="text-reset"
        >
          <FormattedMessage id="guide.javascript" />
        </a>
      </div>
      <div className="mb-4">
        <ReadOutlined className="mr-2" />
        <a
          href={isKo ? guideConfig?.openapi?.ko : guideConfig?.openapi?.en}
          target="_blank"
          className="text-reset"
        >
          <FormattedMessage id="guide.openapi" />
        </a>
      </div>

      {/*<h4>UI Kit</h4>
      <div className="mb-3">
        <ReadOutlined /> AOS UI Kit 가이드
        <Button
          className="ml-2"
          shape="round"
          icon={<DownloadOutlined />}
          size="small"
        >
          Download
        </Button>
      </div>
      <div className="mb-3">
        <ReadOutlined /> IOS UI Kit 가이드
        <Button
          className="ml-2"
          shape="round"
          icon={<DownloadOutlined />}
          size="small"
        >
          Download
        </Button>
      </div>
      <div className="mb-3">
        <ReadOutlined /> React UI Kit 가이드
        <Button
          className="ml-2"
          shape="round"
          icon={<DownloadOutlined />}
          size="small"
        >
          Download
        </Button>
      </div> */}
    </Card>
  );
};

export default Jobmanagement;
