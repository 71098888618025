import React, { useEffect } from 'react';
import { useNavigate, useParams} from 'react-router-dom';
import { useQuery } from '@apollo/client';
import {
  COMPANY_ID,
} from '../../redux/constants/Auth'
import Loading from 'components/shared-components/Loading';
const CompanyLayout = (props) => {
  const params = useParams();
  const companyId = params?.companyId;
  let navigate = useNavigate();
  useEffect(() => {
    if(companyId && companyId !== "notfound")
      localStorage.setItem(COMPANY_ID, companyId);
    navigate('/auth/login');
  }, []);

  return <Loading cover="content" />;
};

export default CompanyLayout;
