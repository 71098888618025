import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {BrowserRouter}  from "react-router-dom";
import React from 'react';
import './index.scss';
import App from './App';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

import { Provider } from 'react-redux';
import store from './redux/store';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN, COMPANY_ID } from 'redux/constants/Auth';
import JwtAuthService from 'services/JwtAuthService';
import { ApolloProvider, ApolloClient, InMemoryCache, createHttpLink } from '@apollo/client';
import Utils from './utils';

import { message as antdMessage } from 'antd';


antdMessage.config({
    maxCount: 1,
    duration: 2,
});



const httpLink = createHttpLink({
    uri: `${process.env.REACT_APP_API_ENDPOINT_URL}/graphql`,
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem(AUTH_TOKEN);
    const companyid = localStorage.getItem(COMPANY_ID);
    const locale = Utils.getCurrentLocale();
    // return the headers to the context so httpLink can read them
    return {
        headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : null,
        ncpid: companyid,
        'accept-language': locale,
        'language': locale,
        },
    };
});


const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, state, locations, path, code }) => {
        if (state !== undefined && state !== null) {
          for (const key in state) {
            antdMessage.error({
              content: state[key],
            });
          }
        } else {
          if (message) {
            antdMessage.error({ content: message });
          }
  
          console.error(
            `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}, Code: ${code}`
          );
        }
      });
    }
  
    if (networkError) {
      console.error(`[Network error]: ${networkError}`);
      console.error(`[Network error]: ${networkError?.result?.message}`);
  
      if (
        networkError?.code !== 401 &&
        networkError?.code !== 403 &&
        networkError?.result?.message
      ) {
        let errorMessage = networkError.result.message;
  
        antdMessage.error(`${errorMessage} (${networkError.statusCode})`);
        localStorage.removeItem(AUTH_TOKEN);
        JwtAuthService.signOut().then((result) => {        
            window.location.assign('/auth/login');
        });
        
      } else {
        // 네트워크 오류라고 해서 왜 무조건 종료시켜 버리는지 참나... 이러니 디버깅이 너무 어렵다!!
        // 401, 403

        // localStorage.removeItem(AUTH_TOKEN);
        // JwtAuthService.signOut().then((result) => {
        //   if (result && result.status === 200) {
        //     window.location.assign('/');
        //   } else {
        //     window.location.assign('/');
        //   }
        // });
      }
    }
  });

const client = new ApolloClient({
    uri: `${process.env.REACT_APP_API_ENDPOINT_URL}/graphql`,
    link: authLink.concat(errorLink).concat(httpLink),
    cache: new InMemoryCache(),
});

const container = document.getElementById('root');
const root = createRoot(container);
root.render(  
    <React.StrictMode>
        <Provider store={store}>
            <ApolloProvider client={client}>
                <App />
            </ApolloProvider>
        </Provider>
    </React.StrictMode>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
