import React from 'react';
import { Drawer, Button, Form, Row, Col, Input, Select,Grid } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';

const { Option } = Select;

const AppsView = (props) => {
  const { visible, close } = props;
  const { formatMessage } = useIntl();
  const { useBreakpoint } = Grid;

  return (
    <Drawer
      title="Create a new Project"
      width={utils.isTablet(useBreakpoint()) ? '80%' : 720}
      placement="right"
      onClose={close}
      closable={false}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button onClick={close} style={{ marginRight: 8 }}>
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            onClick={() => {
              console.log('Submit');
            }}
            type="primary"
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      }
    >
      <Form layout="vertical">
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={<FormattedMessage id="projects.name" />}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="projects.name.placeholder" />,
                },
              ]}
            >
              <Input
                placeholder={formatMessage({ id: 'projects.name.placeholder' })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <Form.Item
              name="length"
              label={<FormattedMessage id="projects.length" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="projects.length.placeholder" />
                  ),
                },
              ]}
            >
              <Input
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'projects.length.placeholder',
                })}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="owner"
              label={<FormattedMessage id="projects.owner" />}
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="projects.owner.placeholder" />,
                },
              ]}
            >
              <Select
                placeholder={formatMessage({
                  id: 'projects.owner.placeholder',
                })}
              >
                <Option value="xiao">
                  <FormattedMessage id="projects.owner.1" />
                </Option>
                <Option value="mao">
                  <FormattedMessage id="projects.owner.2" />
                </Option>
                <Option value="m3ao">
                  <FormattedMessage id="projects.owner.3" />
                </Option>
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AppsView;
