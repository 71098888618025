import gql from 'graphql-tag';

const UPDATE_USERS_MUTATION = gql`
  mutation updateUsers(
    $id: ID!
    $admin: Boolean
    $name: String
    $state: Int
    $oldPassword: String
    $newPassword: String
    $language: String
    $profile: String
    $timezone: String
    $auto_translate: Boolean
    $auto_detect: Boolean
  ) {
    updateUsers(
      input: {
        id: $id
        admin: $admin
        name: $name
        state: $state
        oldPassword: $oldPassword
        newPassword: $newPassword
        language: $language
        profile: $profile
        timezone: $timezone
        auto_translate: $auto_translate
        auto_detect: $auto_detect
      }
    ) {
      user {
        id
        ncp_project_id
        local {
          email
          password
          name
          phone
        }
        profile
        retries
        ip
        state
        logined_at
        admin
        manager
        fcm_token
        push
        timezone
        deleted
        deleted_at
      }
    }
  }
`;

const DELETE_USERS_MUTATION = gql`
  mutation DELETE_USERS_MUTATION($id: [String]!, $ncp_project_id: String!) {
    deleteUser(input: { id: $id, ncp_project_id: $ncp_project_id }) {
      error_user
    }
  }
`;

const CREATE_USER_MUTATION = gql`
  mutation CREATE_USER_MUTATION(
    $email: String!
    $name: String!
    $state: Int!
    $password: String!
    $ncpId: String!
    $language: String
    $timezone: String
    $auto_translate: Boolean
    $auto_detect: Boolean
  ) {
    createUser(
      input: {
        email: $email
        name: $name
        state: $state
        password: $password
        ncpId: $ncpId
        language: $language
        timezone: $timezone
        auto_translate: $auto_translate
        auto_detect: $auto_detect
      }
    ) {
      user {
        id
      }
    }
  }
`;

export { UPDATE_USERS_MUTATION, DELETE_USERS_MUTATION, CREATE_USER_MUTATION };
