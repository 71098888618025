import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { Form, Avatar, Button, Input, Row, Col, message, Select } from 'antd';
import { UserOutlined, SaveOutlined } from '@ant-design/icons';

import { ROW_GUTTER } from 'constants/ThemeConstant';
import Flex from 'components/shared-components/Flex';
import { setCurrentProfile } from 'redux/actions/Project';
import Utils from '../../../utils';

import { UPDATE_PROFILE_MUTATION } from '../../../graphql/mutations/profile';

const EditProfile = (props) => {
  const { formatMessage } = useIntl();
  const { current_project, current_profile, setCurrentProfile } = props;

  const [profile_name, setProfile_name] = useState('');
  const [profile_url, setProfile_url] = useState('');
  const [profile_timezone, setProfile_timezone] = useState('');

  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION, {
    onCompleted(result) {
      if (result?.updateUser?.user?.id) {
        setCurrentProfile({
          ...current_profile,
          profile: profile_url,
          local: { ...current_profile?.local, name: profile_name },
          timezone: profile_timezone,
        });

        Utils.setDefaultTimezone(profile_timezone);

        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateProfile',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'updateProfile',
        });
      }
    },
  });

  useEffect(() => {
    setProfile_name(current_profile?.local?.name);
    setProfile_url(current_profile?.profile);
    setProfile_timezone(current_profile?.timezone);
  }, [current_profile]);

  const onFinish = () => {
    if (!current_profile?.id) {
      message.error({
        content: formatMessage({
          id: 'common.message.error1',
        }),
      });
      return;
    }

    if (
      profile_name &&
      current_project?.name_regex &&
      profile_name.match(current_project?.name_regex) === null
    ) {
      message.error({
        content: formatMessage({
          id: 'profile.edit.error1',
        }),
      });
      return;
    }

    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateProfile',
      duration: 0,
    });

    updateProfile({
      variables: {
        id: Utils.getID(current_profile?.id),
        name: profile_name,
        profile: profile_url,
        timezone: profile_timezone,
      },
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
    <>
      <Flex
        alignItems="center"
        mobileFlex={false}
        className="text-center text-md-left"
      >
        <Avatar
          size={90}
          src={current_profile?.profile}
          icon={<UserOutlined />}
        />
      </Flex>
      <div className="mt-4">
        <Form
          name="basicInformation"
          layout="vertical"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          <Row>
            <Col xs={24} sm={24} md={24} lg={24}>
              <Row gutter={ROW_GUTTER}>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label={<FormattedMessage id="profile.edit.label.email" />}
                  >
                    <div className="text-break">
                      {current_profile?.local?.email}
                    </div>
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label={
                      <FormattedMessage id="profile.edit.label.profile_name" />
                    }
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="profile.edit.rules.profile_name" />
                        ),
                      },
                    ]}
                  >
                    <Input
                      size="small"
                      value={profile_name}
                      onChange={(e) => setProfile_name(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item
                    label={<FormattedMessage id="profile.edit.label.profile" />}
                  >
                    <Input
                      size="small"
                      value={profile_url}
                      onChange={(e) => setProfile_url(e.target.value)}
                    />
                  </Form.Item>
                </Col>
                <Col xs={24} sm={24} md={24}>
                  <Form.Item label={<FormattedMessage id="common.timezone" />}>
                    <Select
                      size="small"
                      style={{ maxWidth: 305 }}
                      showSearch
                      dropdownMatchSelectWidth={false}
                      placeholder={
                        <FormattedMessage id="common.timezone.placeholder" />
                      }
                      value={profile_timezone}
                      onChange={(value) => {
                        setProfile_timezone(value);
                      }}
                    >
                      {Utils.getTimezoneList().map((item) => (
                        <Select.Option value={item.country} key={item.country}>
                          {item.desc}
                        </Select.Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Button
                size="small"
                type="primary"
                icon={<SaveOutlined className="mr-2" />}
                htmlType="submit"
              >
                <FormattedMessage id="common.save" />
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_project, current_profile } = project;
  return { current_project, current_profile };
};

export default connect(mapStateToProps, {
  setCurrentProfile,
})(EditProfile);
