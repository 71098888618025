
import gql from 'graphql-tag';

const CREATE_INTEGRATION_CONFIG_MUTATION = gql`
    mutation createIntegrationConfig($projectId: String!, $integrationId: String!, $key: String!, $value: String!) {
        createIntegrationConfig(input: {projectId: $projectId, integrationId: $integrationId, key: $key,  value: $value}) {
        integrationConfig {
            integration_id
            key
            value
        }
        }
    }
`;

export { CREATE_INTEGRATION_CONFIG_MUTATION };
