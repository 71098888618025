import React, { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { Card, Row, Col } from 'antd';
import { useNavigate } from 'react-router-dom';
import AccountForm from '../../components/AccountForm';
import queryString from 'query-string';
import { FormattedMessage } from 'react-intl';
import { useParams } from 'react-router';

// import { ADMIN_QUERY } from '../../../../graphql/query/admins';

const backgroundStyle = {
  backgroundImage: 'url(/img/others/img-17.jpg)',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
};

const AccountFromMail = (props) => {
  const [admin, setAdmin] = useState();

  const navigate = useNavigate();

  
  const params = useParams();

  const companyId = params.companyId;
  const queryObj = queryString.parse(window.location.search);  
  const key = queryObj?.key;
  const projectName = queryObj?.projectName;


  return (
    <div className="h-100" style={backgroundStyle}>
      <div className="container d-flex flex-column justify-content-center h-100">
        <Row justify="center">
          <Col xs={20} sm={20} md={20} lg={7}>
            <Card>
              <div className="my-2">
                <div className="text-center">
                <a href="/"><img
                    className="img-fluid"
                    src="/img/logo.png"
                    alt=""
                    style={{ width: 250 }}
                  /></a>
                  <p className="text-muted">
                    <FormattedMessage id="verify.create.card" />
                  </p>
                </div>
                <Row justify="center">
                  <Col xs={24} sm={24} md={20} lg={20}>
                    <AccountForm
                      {...props}
                      switchKey="createFromMail"
                      tempkey={key}
                      companyId={companyId}
                      projectName={projectName}
                      allowRedirect={true}
                    />
                  </Col>
                </Row>
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AccountFromMail;
