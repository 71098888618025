import React, { useEffect } from 'react';
import {
  Input,
  Button,
  Switch,
  message,
  Drawer,
  Form,
  Row,
  Col,
  Grid,
  Popconfirm,
  Select,
} from 'antd';
import { useMutation } from '@apollo/client';
import {
  CloseCircleOutlined,
  DeleteOutlined,
  SaveOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import CopyText from 'components/shared-components/CopyText';
import { FormattedMessage, useIntl } from 'react-intl';

import { UPDATE_USERS_MUTATION } from '../../../graphql/mutations/users';
import utils from 'utils';

const EditUser = (props) => {
  const {
    visible,
    onClose,
    userData,
    refetch,
    ondelete,
    isDisable,
    contextState,
  } = props;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { useBreakpoint } = Grid;
  useEffect(() => {
    if (userData && userData.node) {
      const dataSource = userData.node;

      form.setFieldsValue({
        state: dataSource.state === 1 ? true : false,
        user_name: dataSource.local.name,
        isAdmin: dataSource.admin,
        timezone: dataSource.timezone,
      });
    }
  }, [userData]);

  const [updateUsers] = useMutation(UPDATE_USERS_MUTATION, {
    onCompleted(result) {
      if (result?.updateUsers?.user?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateUsers',
        });

        form.setFieldsValue({
          user_password: null,
        });

        refetch();
        onClose();
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'updateUsers',
        });
      }
    },
  });

  const onSave = () => {
    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateUsers',
      duration: 0,
    });

    const values = form.getFieldsValue();

    if (!values) {
      message.error({
        content: formatMessage({
          id: 'common.message.save.fail',
        }),
        key: 'updateUsers',
      });
      return;
    }

    updateUsers({
      variables: {
        id: utils.getID(userData.node.id),
        name: values.user_name,
        newPassword: values.user_password ? values.user_password : undefined,
        state: values.state ? 1 : 0,
        admin: values.isAdmin,
        timezone: values.timezone,
      },
    });
  };

  const handleDelete = () => {
    form.resetFields();
    onClose();
    ondelete(userData);
  };

  return (
    <Drawer
      title={<FormattedMessage id="common.update" />}
      placement="right"
      bodyStyle={{ paddingBottom: 80 }}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 400}
      open={visible}
      closable={false}
      onClose={onClose}
      // forceRender
      // destroyOnClose
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <div
            style={{ display: 'inline-block' }}
            className={`${utils.isTablet(useBreakpoint()) ? 'mb-1' : ''}`}
          >
            <Button
              size="small"
              onClick={onClose}
              style={{ marginRight: 8 }}
              icon={<CloseCircleOutlined className="mr-2" />}
            >
              <FormattedMessage id="common.cancel" />
            </Button>

            {isDisable || userData?.node?.admin ? (
              <Button
                size="small"
                danger
                style={{ marginRight: 8 }}
                icon={<DeleteOutlined className="mr-2" />}
                disabled
              >
                <FormattedMessage id="common.delete" />
              </Button>
            ) : (
              <Popconfirm
                title={<FormattedMessage id="common.message.delete.confirm" />}
                onConfirm={handleDelete}
                okText={<FormattedMessage id="common.ok" />}
                cancelText={<FormattedMessage id="common.no" />}
              >
                <Button
                  size="small"
                  danger
                  style={{ marginRight: 8 }}
                  icon={<DeleteOutlined className="mr-2" />}
                >
                  <FormattedMessage id="common.delete" />
                </Button>
              </Popconfirm>
            )}
          </div>
          <div style={{ display: 'inline-block' }}>
            <Button
              size="small"
              type="primary"
              onClick={() => form.submit()}
              icon={<SaveOutlined className="mr-2" />}
              disabled={isDisable}
              style={{ marginRight: 8 }}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="state"
              label={<FormattedMessage id="common.enable" />}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                disabled={isDisable}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="settings.user.label.user_id" />}
            >
              {userData?.node?.local?.email}
              <CopyText text={userData?.node?.local?.email} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="user_name"
              label={<FormattedMessage id="settings.user.label.user_name" />}
              rules={[
                {
                  required: true,
                  pattern: contextState?.name_regex,
                  message: (
                    <FormattedMessage id="settings.user.rules.user_name" />
                  ),
                },
              ]}
            >
              <Input
                size="small"
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'settings.user.placeholder.user_name',
                })}
                disabled={isDisable}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="user_password"
              label={
                <FormattedMessage id="settings.user.label.user_password" />
              }
              rules={[
                {
                  pattern: utils.regPW,
                  message: <FormattedMessage id="common.error.password" />,
                },
              ]}
            >
              <Input.Password
                size="small"
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'settings.user.placeholder.user_password',
                })}
                disabled={isDisable}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="timezone"
              label={<FormattedMessage id="common.timezone" />}
            >
              <Select
                size="small"
                placeholder={
                  <FormattedMessage id="common.timezone.placeholder" />
                }
                disabled={isDisable}
                showSearch
              >
                {utils.getTimezoneList().map((item) => (
                  <Select.Option key={item.country} value={item.country}>
                    {item.desc}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="isAdmin"
              label={<FormattedMessage id="settings.user.label.isadmin" />}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                disabled={isDisable}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default EditUser;
