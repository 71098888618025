import { message } from 'antd/lib';
import * as ncloudchat from "cloudchat";

let instance = null;

class ChatService {
  isconn = false;

  constructor() {
    if (instance) {
      return instance;
    }
    console.log('ChatService initialize');
    instance = this;
  }

  static getInstance() {
    return new ChatService();
  }
  setToken(token) {
    this.nc.setToken(token);
  }
  initialize(projectId) {
    if (this.nc) return true;
    this.nc = new ncloudchat.Chat();
    this.nc.initialize(projectId);
    this.nc.setServerUrl(`${process.env.REACT_APP_API_ENDPOINT_URL}`);
    this.nc.setSocketUrl(`${process.env.REACT_APP_SOCKET_ENDPOINT_URL}`);
  }
  changeLanguage(lang) {
    this.nc.setLang(lang);
  }
  bind(name, func) {
    this.nc.bind(name, func);
  }
  unbind(name) {
    this.nc.unbind(name);
  }

  async connect(userId, name, profile, token, callback) {
    if (this.isconn === true) {
      return false;
    }

    this.isconn = true;
    console.log('ChatService Connecting...');

    if (this.nc?.isConnected()) {
      callback(null, userId);
    }

    try {

      const user = await this.nc.connect(
        {
          id: userId,
          name,
          profile,
        },
        token
      );

      callback(null, user);
    } catch (error) {
      callback(error);
    }
  }

  async joinChannel(channel_id) {
    try
    {
      await this.nc.subscribe(channel_id);
      return true;
    } catch( e ) {
      message.error({
        content: e.message,
      });
      return false;
    }
  }

  getChannels(offset, limit, search, query, callback) {
    callback('', []);
    this.nc.getChannels(offset, limit, (err, channelData) => {
      callback(err, channelData);
    });
  }
  async getMessages(filter, sort, option, callback) {
    const messages = await this.nc.getMessages(filter, sort, option);
    callback(null, messages);
  }
  async getSubscriptions(channel_id, offset, limit, callback) {
    const messages = await this.nc.getSubscriptions(
      channel_id,
      offset,
      limit,
      callback
    );
    callback(null, messages);
  }

  disconnect() {
    return new Promise((resolve, reject) => {
      this.isconn = false;
      this.nc.disconnect();
    });
  }

  sendMessage(channel_id, message) {
    return new Promise((resolve, reject) => {
      this.nc.sendMessage(channel_id, message);
    });
  }

  sendIntegration(channel_id, integration_id, to, message) {
    return new Promise((resolve, reject) => {
      this.nc.sendIntegration(channel_id, integration_id, to, message);
    });
  }

  sendImage = async (channel_id, file) => {
    const result = await this.nc.sendImage(channel_id, file);

    return result;
  };

  sendMessageOfAdmin(channelIds, message, admin) {
    return new Promise((resolve, reject) => {
      this.nc.sendMessageOfAdmin(channelIds, message, admin);
    });
  }

  translateMessage(source, target, message, channelId = '') {
    return new Promise((resolve, reject) => {
      return this.nc.translateMessage(source, target, message, channelId);
    });
  }

  createChannel = async (variables) => {
    const channel = await this.nc.createChannel(variables);

    return channel;
  };

  updateChannel = async (channelId, variables) => {
    const channel = await this.nc.updateChannel(channelId, variables);

    return channel;
  };

  deleteChannel = async (channelID) => {
    const channel = await this.nc.deleteChannel(channelID);

    return channel;
  };

  banUser = async (channelId, userId, timeout, reason) => {
    const options = {
      timeout: String(timeout),
      reason: reason,
    };

    const ban = await this.nc.banUser(channelId, userId, options);

    return ban;
  };

  unbanUser = async (channelId, userId) => {
    const ban = await this.nc.unbanUser(channelId, userId);

    return ban;
  };
}

export default ChatService;
