import gql from 'graphql-tag';

const MEMBER = `
  id
  project_id
  member_id
  name
  profile
  memo
  country
  remoteip
  adid
  customField
  device
  network
  version
  model
  deleted
  online
  device_type
  push
  memberblock_id {
    id
    project_id
    member_id
    deleted
    type
    status
    messageMulti {
      lang
      value
      default
    }
    started_at
    ended_at
    created_at
    updated_at
    deleted_at
  }
  logined_at
  created_at
  updated_at
  deleted_at
`;

const CREATEMEMBER_MUTATION = gql`
  mutation createMember(
    $projectId: String!
    $userId: String!
    $name: String!
    $profile: String
  ) {
    createMember(
      input: {
        projectId: $projectId
        userId: $userId
        name: $name
        profile: $profile
      }
    ) {
      member {
        ${MEMBER}
      }
    }
  }
`;

const MEMBER_MUTATION = gql`
  mutation updateMember(
    $id: String!
    $projectId: String!
    $name: String
    $profile: String
    $remoteip: String
    $memo: String
    $adid: String
    $device: String
    $deviceType: [String]
    $network: String
    $version: String
    $model: String
    $push: Boolean
    $customField: String
  ) {
    updateMember(
      input: {
        id: $id
        projectId: $projectId
        name: $name
        profile: $profile
        remoteip: $remoteip
        memo: $memo
        adid: $adid
        device: $device
        deviceType: $deviceType
        network: $network
        version: $version
        model: $model
        push: $push
        customField: $customField
      }
    ) {
      member {
        ${MEMBER}
      }
    }
  }
`;

const CREATE_MEMBERBLOCK_MUTATION = gql`
  mutation createMemberBlock(
    $projectId: String!
    $memberId: String!
    $type: String
    $status: Int
    $kick: Boolean
    $messageMulti: [LanguageInput]
    $startedAt: String
    $endedAt: String
  ) {
    createMemberBlock(
      input: {
        projectId: $projectId
        memberId: $memberId
        type: $type
        status: $status
        kick: $kick
        messageMulti: $messageMulti
        startedAt: $startedAt
        endedAt: $endedAt
      }
    ) {
      memberBlock {
        id
        project_id
        member_id
        type
        status
        messageMulti {
          lang
          value
          default
        }
        started_at
        ended_at
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const UPDATE_MEMBERBLOCK_MUTATION = gql`
  mutation updateMemberBlock(
    $id: ID!
    $projectId: String!
    $memberId: String!
    $type: String
    $status: Int
    $kick: Boolean
    $messageMulti: [LanguageInput]
    $startedAt: String
    $endedAt: String
  ) {
    updateMemberBlock(
      input: {
        id: $id
        projectId: $projectId
        memberId: $memberId
        type: $type
        status: $status
        kick: $kick
        messageMulti: $messageMulti
        startedAt: $startedAt
        endedAt: $endedAt
      }
    ) {
      memberBlock {
        id
        project_id
        member_id
        type
        status
        messageMulti {
          lang
          value
          default
        }
        started_at
        ended_at
        created_at
        updated_at
        deleted_at
      }
    }
  }
`;

const DELETE_MEMBERBLOCK_MUTATION = gql`
  mutation DELETE_MEMBERBLOCK_MUTATION($id: ID!, $projectId: String!) {
    deleteMemberBlock(input: { id: $id, projectId: $projectId }) {
      memberBlock {
        id
        messageMulti {
          lang
          value
          default
        }
      }
    }
  }
`;

const DELETE_MEMBER_MUTATION = gql`
  mutation DELETE_MEMBER_MUTATION($id: ID!, $projectId: String!) {
    deleteMember(input: { id: $id, projectId: $projectId }) {
      member {
        ${MEMBER}
      }
    }
  }
`;

const DELETE_MEMBERS_MUTATION = gql`
  mutation deleteMembers($projectId: ID!, $ids: [String]!) {
    deleteMembers(input: { projectId: $projectId, ids: $ids }) {
      result {
        project_id
        completes
        fails
        message
      }
    }
  }
`;

const RECOVER_MEMBER_MUTATION = gql`
  mutation RECOVER_MEMBER_MUTATION($id: String!, $projectId: String!) {
    recoverMember(input: { id: $id, projectId: $projectId }) {
      member {
        ${MEMBER}
      }
    }
  }
`;

export {
  CREATEMEMBER_MUTATION,
  MEMBER_MUTATION,
  CREATE_MEMBERBLOCK_MUTATION,
  UPDATE_MEMBERBLOCK_MUTATION,
  DELETE_MEMBERBLOCK_MUTATION,
  DELETE_MEMBER_MUTATION,
  DELETE_MEMBERS_MUTATION,
  RECOVER_MEMBER_MUTATION,
};
