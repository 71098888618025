import antdKoKR from 'antd/lib/locale-provider/ko_KR';
import koMsg from '../locales/ko_KR.json';
import 'moment/locale/ko';
const KoLang = {
  antd: antdKoKR,
  locale: 'ko-KR',
  messages: {
    ...koMsg,
  },
};
export default KoLang;
