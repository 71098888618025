import axios from 'axios';
import { useNavigate  } from 'react-router-dom';
import { AUTH_TOKEN, COMPANY_ID } from 'redux/constants/Auth';
import { notification } from 'antd';

const service = axios.create({
  baseURL: process.env.REACT_APP_API_ENDPOINT_URL,
  timeout: 10000,
});

// Config
// const ENTRY_ROUTE = '/auth/login';
const TOKEN_PAYLOAD_KEY = 'authorization';
const PUBLIC_REQUEST_KEY = 'public-request';

// API Request interceptor
service.interceptors.request.use(
  (config) => {
    const jwtToken = localStorage.getItem(AUTH_TOKEN);

    if (jwtToken) {
      config.headers[TOKEN_PAYLOAD_KEY] = jwtToken;
    }

    if (!jwtToken && !config.headers[PUBLIC_REQUEST_KEY]) {
      // verify 때문에 주석 처리
      // history.push(ENTRY_ROUTE);
      // window.location.reload();
    }

    return config;
  },
  (error) => {
    // Do something with request error here
    notification.error({
      message: 'Error',
    });
    Promise.reject(error);
  }
);

// API respone interceptor
service.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    let notificationParam = {
      message: '',
    };
    let nativate = useNavigate();
    if (error.response == null) {
      // localStorage.removeItem(AUTH_TOKEN);
      // localStorage.removeItem(COMPANY_ID);
      nativate('/error/500');
      window.location.reload();
    }

    // Remove token and redirect
    if (error.response.status === 400 || error.response.status === 403) {
      notificationParam.message = 'Authentication Fail';
      notificationParam.description = 'Please login again';
      // localStorage.removeItem(AUTH_TOKEN);
      // history.push(ENTRY_ROUTE);
      // window.location.reload();
    }

    if (error.response.status === 404) {
      notificationParam.message = 'Not Found';
    }

    if (error.response.status === 500) {
      notificationParam.message = 'Internal Server Error';
    }

    if (error.response.status === 508) {
      notificationParam.message = 'Time Out';
    }

    notification.error(notificationParam);

    return Promise.reject(error);
  }
);

export default service;
