import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

export const BlankViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        <Route
          path={`/monitor/l7check`}
          component={lazy(() => import(`./l7check`))}
        />
        <Route
          path={`/error-1`}
          component={lazy(() => import(`../errors/404`))}
        />
        <Route
          path={`/error-2`}
          component={lazy(() => import(`../errors/500`))}
        />
        <Route
          path={`*`}
          component={lazy(() => import(`../errors/404`))}
        />
      </Routes>
    </Suspense>
  );
};

export default BlankViews;
