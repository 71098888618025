import React from 'react';
import { Modal, Button, message } from 'antd';
import { useMutation } from '@apollo/client';
import { PAID_PROJECT_MUTATION } from '../../graphql/mutations/project';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';

const ProjectPayModal = (props) => {
  const projectId = utils.getProjectID();
  const { formatMessage } = useIntl();

  const { visible, onCancel } = props;

  const [paidProject] = useMutation(PAID_PROJECT_MUTATION, {
    onCompleted(result) {
      if (result?.paidProject?.project?.paid) {
        message.success({
          content: formatMessage({ id: 'footerpay.modal.message.success' }),
          key: 'paidProject',
        });
        onCancel();
        window.location.reload();
      } else {
        message.error({
          content: formatMessage({ id: 'footerpay.modal.message.fail' }),
          key: 'paidProject',
        });
        onCancel();
      }
    },
  });

  const onNext = () => {
    message.loading({
      content: formatMessage({ id: 'footerpay.modal.message.loading' }),
      key: 'paidProject',
      duration: 0,
    });

    paidProject({
      variables: { id: projectId },
    });
  };

  return (
    <Modal
      title="Caution"
      open={visible}
      closable={false}
      footer={[
        <Button key="cancel" size="small" onClick={() => onCancel(false)}>
          <FormattedMessage id="common.cancel" />
        </Button>,
        <Button key="ok" type="primary" size="small" onClick={() => onNext()}>
          <FormattedMessage id="common.send" />
        </Button>,
      ]}
    >
      <div>
        <div className="text-primary font-weight-bold">
          <FormattedMessage id="footerpay.modal.message.desc1" />
        </div>
        <div className="font-size-sm text-gray-light">
          <FormattedMessage id="footerpay.modal.message.desc2" />
        </div>
        <br />
        <div className="text-center">
          <div>
            <FormattedMessage id="footerpay.modal.message.desc3" />
          </div>
          <div
            dangerouslySetInnerHTML={{
              __html: formatMessage({ id: 'footerpay.modal.message.desc4' }),
            }}
          />
          <div className="text-primary">
            <FormattedMessage id="footerpay.modal.message.desc5" />
          </div>
        </div>
        <br />
      </div>
    </Modal>
  );
};

export default ProjectPayModal;
