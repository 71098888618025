import { gql } from '@apollo/client';

const CHANNEL_QUERY = gql`
  query channel($id: String, $projectId: String!, $uniqueId: String) {
    channel(projectId: $projectId, id: $id, uniqueId: $uniqueId) {
      id
      name
      unique_id
      type
      translation
      members
      push
      image_url
      customField
      integration_id
      created_at
      updated_at
    }
  }
`;

const CHANNELS_QUERY = gql`
  query channels(
    $projectId: String!
    $filter: String!
    $sort: String
    $option: String
  ) {
    channels(
      projectId: $projectId
      filter: $filter
      sort: $sort
      option: $option
    ) {
      totalCount
      edges {
        node {
          id
          name
          unique_id
          type
          translation
          members
          push
          image_url
          customField
          integration_id
          created_at
          updated_at
        }
      }
    }
  }
`;

const SUBSCRIPTIONS_QUERY = gql`
  query subscriptions(
    $projectId: String!
    $filter: String!
    $sort: String
    $option: String
  ) {
    subscriptions(
      projectId: $projectId
      option: $option
      filter: $filter
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          id
          channel_id
          user_id
          online
          push
          language
          created_at
          user {
            member_id
            name
            profile
            country
          }
          channel {
            channel_id
            name
            unique_id
          }
          mark {
            user_id
            message_id
            sort_id
          }
          memberblock_id {
            id
            project_id
            member_id
            channel_id
            deleted
            type
            status
            messageMulti {
              lang
              value
              default
            }
            started_at
            ended_at
            created_at
            updated_at
            deleted_at
          }
        }
      }
    }
  }
`;

export { CHANNEL_QUERY, CHANNELS_QUERY, SUBSCRIPTIONS_QUERY };
