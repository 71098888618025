import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl, getLocale } from 'react-intl';
import { useMutation } from '@apollo/client';
import {
  Modal,
  Button,
  Input,
  message,
  Form,
  Popconfirm,
  Col,
  Row,
  Radio,
  Grid,
  Switch,
} from 'antd';
import {
  SaveOutlined,
  CloseCircleOutlined,
  PlusCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import utils from 'utils';
import { Editor } from '@tinymce/tinymce-react';

import {
  CREATE_STORY_MUTATION,
  UPDATE_STORY_MUTATION,
  DELETE_STORY_MUTATION,
} from '../../../graphql/mutations/story';


const Add = (props) => {
  const {
    visible,
    setVisible,
    item,
    lists_refetch,
  } = props;

  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [text, setText] = useState("");
  const [status, setStatus] = useState(-1);
  const [subject, setSubject] = useState("");
  const [createStory] = useMutation(CREATE_STORY_MUTATION, {
    onCompleted(result) {
      setLoading(false);
      if (result?.createStory?.story?.id) {
        if (lists_refetch) lists_refetch();
        message.success({
          content: formatMessage({ id: 'common.message.save.success' }),
          key: 'story',
        });
        onChildrenModalClose();
      } else {
        message.error({
          content: formatMessage({ id: 'common.message.save.fail' }),
          key: 'story',
        });
      }
      
    },
    onError(error) {
      setLoading(false);
      message.error({
        content:
          error.message ||
          formatMessage({
            id: 'common.message.save.fail',
          }),
        key: 'createStory',
      });
    },
  });

  const [updateStory] = useMutation(UPDATE_STORY_MUTATION, {
    onCompleted(result) {
      setLoading(false);
      if (result?.updateStory?.story?.id) {
        if (lists_refetch) lists_refetch();
        message.success({
          content: formatMessage({ id: 'common.message.save.success' }),
          key: 'story',
        });
        onChildrenModalClose();
      } else {
        message.error({
          content: formatMessage({ id: 'common.message.save.fail' }),
          key: 'story',
        });
      }
      
    },
    onError(error) {
      setLoading(false);
      message.error({
        content:
          error.message ||
          formatMessage({
            id: 'common.message.save.fail',
          }),
        key: 'createStory',
      });
    },
  });

  const [deleteStory] = useMutation(DELETE_STORY_MUTATION, {
    onCompleted(result) {
      setLoading(false);
      if (result?.deleteStory?.story?.id) {
        if (lists_refetch) lists_refetch();
        message.success({
          content: formatMessage({ id: 'common.message.delete.success' }),
          key: 'story',
        });
        onChildrenModalClose();
      } else {
        message.error({
          content: formatMessage({ id: 'common.message.delete.fail' }),
          key: 'story',
        });
      }
      
    },
    onError(error) {
      setLoading(false);
      message.error({
        content:
          error.message ||
          formatMessage({
            id: 'common.message.delete.fail',
          }),
        key: 'createStory',
      });
    },
  });

  useEffect(() => {
    if (item && item?.id) {    
      form.setFieldsValue({
        status: item?.status == 1 ? true : false,
      });

      setSubject(utils.getLanguageDec(item.title));
      setText(utils.getLanguageDec(item.text));
      setStatus(item.status);
    } else {    
      setSubject("");
      setText("");
      setStatus(-1);
    }
  }, [item]);

  const onCreate = (values) => {
    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'create',
      duration: 0,
    });
    if(!subject) {
      return message.error({
        content: formatMessage({
          id: 'story.modal.empty.subject',
        })
      });
    }
    if(!text) {
      return message.error({
        content: formatMessage({
          id: 'story.modal.empty.content',
        })
      });
    }
    const variables = {
      projectId: utils.getProjectID(),
      category_id: "notice",
      title: [{"default":true, "lang":"ko","value": subject}],
      text: [{"default":true, "lang":"ko","value": text}], 
      status: values?.status ? 1 : -1,
    };
    if(item?.id)
    {
      variables.id = utils.getID(item.id);
      updateStory({ variables });
    }
    else 
    {
      createStory({ variables });
    }

  };

  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        onCreate(values);
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const onDelete = () => {
    message.loading({
      content: formatMessage({
        id: 'common.message.deleting',
      }),
      key: 'deleteStory',
      duration: 0,
    });

    deleteStory({
      variables: {
        projectId: utils.getProjectID(),
        id: utils.getID(item.id),
      },
    });

  }
  const onChildrenModalClose = () => {
    form.resetFields();
    // setText("");
    // setSubject("");
    // setStatus(-1);
    setVisible(false);
  };
  
  return (
    <>
     
      <Modal
        open={visible}
        width={utils.isTablet(useBreakpoint()) ? '80%' : "80%"}
        closable={false}
        forceRender
        destroyOnClose
        onCancel={onChildrenModalClose}
        title={
          item?.id ? (
            <FormattedMessage id="story.edit" />          
          ) : (
            <FormattedMessage id="story.add" />
          )
        }
        footer={
          <div>   
            { item?.id ? (
              <Popconfirm
                  placement="bottomRight"
                  title={<FormattedMessage id="common.message.delete.confirm" />}
                  onConfirm={() => onDelete()}
                  okText={<FormattedMessage id="common.ok" />}
                  cancelText={<FormattedMessage id="common.no" />}
                >
                <Button
                  size="small"
                  danger
                  icon={<CloseCircleOutlined className="mr-2" />}
                >
                  <FormattedMessage id="common.delete" />
                </Button>
              </Popconfirm>
          ) : (<div></div>) }
          <Button
            size="small"
            onClick={onChildrenModalClose}
            icon={<CloseCircleOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined className="mr-2" />}
            onClick={() => form.submit()}
          >
          { item?.id ? (
            <FormattedMessage id="common.edit" />          
          ) : (
            <FormattedMessage id="common.add2" />
          )}

          </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSave}
        >
          <Row gutter={24}>
            <Col span={24}>
              <Form.Item
                name="status"
                value={status}
                label={<FormattedMessage id="story.label.status" />}
                valuePropName="checked"
              >
                <Switch
                  checkedChildren={<CheckOutlined />}
                  unCheckedChildren={<CloseOutlined />}
                />
              </Form.Item>
              <Form.Item         
                label={
                  <FormattedMessage id="story.subject" />
                }
                rules={[
                  {
                    required: true,
                    whitespace: true,
                    message: (
                      <FormattedMessage id="story.rules.subject" />
                    ),
                  },
                ]}
              >
                <Input
                style={{ padding: '0px 7px' }}
                className="text-dark"
                size="small"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
              </Form.Item>
            </Col>
          </Row>
          <Row>
          <Col span={24}>
          <Editor
              apiKey="jt66ai9i9pi3fzn126r6gaikj96pxfod7ezfl90kyeqe3xfe"
              
              init={{
                ...utils.tinymceInit,
                placeholder: '',    
                toolbar:
                  'fontsizeselect fontselect formatselect |  bold italic underline strikethrough | forecolor backcolor  | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | table image link code preview ',               
              }}
              onEditorChange={(contents, editor) => {                
                setText(editor.getContent());                
              }}
              value={text}
            />
            
          </Col>
        </Row>
         
        </Form>
      </Modal>
    </>
  );
};

export default Add;
