import { gql } from '@apollo/client';

const MEMBER = `
  id
  project_id
  member_id
  name
  profile
  memo
  country
  remoteip
  adid
  device
  network
  version
  model
  deleted
  online
  device_type
  push
  customField
  memberblock_id {
    id
    project_id
    member_id
    deleted
    type
    status
    messageMulti {
      lang
      value
      default
    }
    started_at
    ended_at
    created_at
    updated_at
    deleted_at
  }
  logined_at
  created_at
  updated_at
  deleted_at
`;

const MEMBER_QUERY = gql`
query member(
  $projectId: String!
   $id: String!
  ) {
  member(
    projectId: $projectId
     id: $id
  ) {
    ${MEMBER}
  }
}
`;

const MEMBERS_QUERY = gql`
  query members(
    $projectId: String!
    $filter: String!
    $sort: String
    $option: String
  ) {
    members(
      projectId: $projectId
      filter: $filter
      sort: $sort
      option: $option
    ) {
      totalCount
      edges {
        node {
          ${MEMBER}
        }
      }
    }
  }
`;

const MEMBERBLOCK_QUERY = gql`
  query memberBlock($projectId: String, $id: ID, $memberId: String) {
    memberBlock(projectId: $projectId, id: $id, memberId: $memberId) {
      id
      project_id
      member_id
      channel_id
      type
      status
      messageMulti {
        lang
        value
        default
      }
      started_at
      ended_at
      created_at
      updated_at
      deleted_at
    }
  }
`;

const MEMBERBLOCKS_QUERY = gql`
  query MEMBERBLOCKS_QUERY(
    $projectId: String!
    $filter: String!
    $sort: String
    $option: String
  ) {
    memberblocks(
      projectId: $projectId
      filter: $filter
      sort: $sort
      option: $option
    ) {
      totalCount
      edges {
        node {
          id
          project_id
          member_id
          channel_id
          type
          status
          messageMulti {
            lang
            value
            default
          }
          started_at
          ended_at
          created_at
          updated_at
          deleted_at
        }
      }
    }
  }
`;

export { MEMBER_QUERY, MEMBERS_QUERY, MEMBERBLOCK_QUERY, MEMBERBLOCKS_QUERY };
