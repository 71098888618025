import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Form, Select, Input, Button, Grid, DatePicker, Badge } from 'antd';
import { SearchOutlined, PlusCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import LinesEllipsis from 'react-lines-ellipsis';
import Flex from 'components/shared-components/Flex';
import CopyText from 'components/shared-components/CopyText';
import { FormattedMessage, useIntl } from 'react-intl';
import UserBlock from './UserBlock';
import utils from 'utils';
import userutils from '../userutils';
import { useParams } from 'react-router-dom';

import { MEMBERBLOCKS_QUERY } from '../../../../graphql/query/member';
import AddBlock from './AddBlock';

const { Option } = Select;
const { RangePicker } = DatePicker;

const searchData = [
  { name: <FormattedMessage id="member.list.columns.id" />, id: 'member_id' },
];

const User = ({ match }) => {
  const [form] = Form.useForm();
  const { formatNumber } = useIntl();
  const params = useParams();
  const { useBreakpoint } = Grid;
  

  const [memberblocks, setMemberblocks] = useState([]);
  const [updateVisible, setUpdateVisible] = useState(false);
  const [addVisible, setAddVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: undefined,
    endDate: undefined,
  });

  const memberBlocksData = useQuery(MEMBERBLOCKS_QUERY, {
    variables: {
      projectId: params.projectId,
      sort: JSON.stringify({ createdAt: -1 }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
      filter: JSON.stringify({
        project_id: params.projectId,
        [search.search]: search.query,
        createdAt: search.startDate
          ? {
              $gte: new Date(moment(search.startDate).startOf('day')),
              $lte: new Date(moment(search.endDate).endOf('day')),
            }
          : undefined,
      }),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (memberBlocksData?.data?.memberblocks) {
      const mb = memberBlocksData.data.memberblocks;

      setMemberblocks(mb.edges);
      setPagination({ ...pagination, total: mb.totalCount });
    }
  }, [memberBlocksData]);

  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    if (!values.query || !values.search) {
      values.query = undefined;
      values.search = undefined;
    }

    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
      startDate: values.range
        ? values.range[0].startOf('day').format()
        : undefined,
      endDate: values.range ? values.range[1].endOf('day').format() : undefined,
    });
  };

  const showUserProfile = (userInfo) => {
    setUpdateVisible(true);
    setSelectedUser(userInfo);
  };

  const closeUpdateBlock = () => {
    setUpdateVisible(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      title: <FormattedMessage id="member.block.status" />,
      dataIndex: ['node', 'status'],
      className: 'td-nowrap',
      render: (row) => {
        if (row === 1) {
          return (
            <div>
              <Badge status="success" /> &nbsp;
              <FormattedMessage id="member.block.status.1" />
            </div>
          );
        }
        return (
          <div>
            <Badge status="default" />&nbsp;
            <FormattedMessage id="member.block.status.0" />
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="member.list.columns.status" />,
      dataIndex: 'node',
      className: 'td-nowrap',
      render: (row) => userutils?.getBlockStatus(row, null),
    },
    {
      title: <FormattedMessage id="member.list.view.id" />,
      dataIndex: ['node', 'member_id'],
      className: 'td-break',
      render: (row) => (
        <div style={{ minWidth: '100px' }}>
          {row}
          <CopyText text={row} />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="member.block.messagemulti" />,
      dataIndex: ['node', 'messageMulti'],
      className: 'td-break',
      render: (row) => {
        return (
          <LinesEllipsis text={row?.[0]?.value} maxLine="3" basedOn="letters" />
        );
      },
    },
    {
      title: <FormattedMessage id="chat.setting.channelid" />,
      dataIndex: ['node', 'channel_id'],
      className: 'td-break',
      render: (row) => {
        return row;
      },
    },
    {
      title: <FormattedMessage id="member.block.started_at" />,
      dataIndex: ['node', 'started_at'],
      render: (row) => (
        <div style={{ minWidth: '100px' }}>
          {row ? moment(row).format('YYYY-MM-DD HH:mm:ss') : '-'}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="member.block.ended_at" />,
      dataIndex: ['node', 'ended_at'],
      render: (row) => (
        <div style={{ minWidth: '100px' }}>
          {row ? moment(row).format('YYYY-MM-DD HH:mm:ss') : '-'}{' '}
        </div>
      ),
    },
  ];

  return (
    <div>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Form
          form={form}
          className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile w-100' : 'w-100'}`}
          layout="inline"
          onFinish={onSearch}
          initialValues={{ search: 'member_id' }}
        >
          <div>
            <Form.Item name="range">
              <RangePicker size="small" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="search">
              <Select style={{ minWidth: 150 }} size="small">
                {searchData.map((elm) => (
                  <Option key={elm.id} value={elm.id}>
                    {elm.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="query">
              <Input placeholder="Search" size="small" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="button_submit">
              <Button
                type="primary"
                size="small"
                icon={<SearchOutlined className="mr-2" />}
                block
                htmlType="submit"
              >
                <FormattedMessage id="common.search" />
              </Button>
            </Form.Item>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Form.Item name="button_add" style={{ marginRight: 0 }}>
              <Button
                className={`${utils.isTablet(useBreakpoint()) ? 'mt-1 mb-1' : ''}`}
                type="primary"
                icon={<PlusCircleOutlined className="mr-2" />}
                block
                onClick={() => setAddVisible(true)}
                size="small"
              >
                <FormattedMessage id="common.add2" />
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Flex>
      <Table
        scroll={{ x: 1000 }}
        style={{ marginTop: 10 }}
        size="small"
        dataSource={memberblocks}
        columns={columns}
        pagination={pagination}
        rowKey={(record) => record.node.id}
        loading={memberBlocksData.loading}
        onChange={handleTableChange}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              showUserProfile(record?.node);
            },
          };
        }}
        footer={() => {
          return (
            <div>
              <FormattedMessage id="common.total.1" />
              {pagination?.total ? formatNumber(pagination.total) : 0}
              <FormattedMessage id="common.total.2" />
            </div>
          );
        }}
      />

      <UserBlock
        projectId={params.projectId}
        visible={updateVisible}
        blockData={selectedUser}
        refetch_members={memberBlocksData.refetch}
        close={closeUpdateBlock}
      />

      <AddBlock
        projectId={params.projectId}
        visible={addVisible}
        close={() => setAddVisible(false)}
        refetch_members={memberBlocksData.refetch}
      />
    </div>
  );
};

export default User;
