import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import moment from 'moment';
import CountryData from 'country-data';
import { useParams } from 'react-router';

import {
  Grid,
  Table,
  Form,
  Select,
  Input,
  Button,
  DatePicker,
  Badge,
  Tooltip,
  Row,
  Col,
  message,
  Popconfirm,
} from 'antd';
import {
  SearchOutlined,
  UserOutlined,
  FileExcelOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';

import AvatarStatus from 'components/shared-components/AvatarStatus';
import CopyText from 'components/shared-components/CopyText';
import UserView from './UserView';
import UserBlock from './UserBlock';
import userutils from '../userutils';
import utils from 'utils';

import { MEMBERS_QUERY } from '../../../../graphql/query/member';
import { CREATE_LOG_EXPORTER } from '../../../../graphql/mutations/exporter';

const { Option } = Select;
const { RangePicker } = DatePicker;

const searchData = [
  { name: <FormattedMessage id="member.list.columns.id" />, id: 'id' },
  {
    name: <FormattedMessage id="member.list.columns.name" />,
    id: 'name',
  },
  {
    name: <FormattedMessage id="member.list.columns.remoteip" />,
    id: 'remoteip',
  },
];

const User = ({ match, current_profile }) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  const { useBreakpoint } = Grid;
  // state
  const [members, setMembers] = useState([]);
  const [userProfileVisible, setUserProfileVisible] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [startday, setStartday] = useState();
  const [endday, setEndday] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });

  const membersData = useQuery(MEMBERS_QUERY, {
    variables: {
      projectId: params.projectId,
      sort: JSON.stringify({ createdAt: -1 }),
      option: JSON.stringify({ offset: 0, per_page: 20 }),
      filter: JSON.stringify({ project_id: params.projectId }),
    },
    fetchPolicy: 'network-only',
  });

  const [createExporter] = useMutation(CREATE_LOG_EXPORTER, {
    onCompleted(result) {
      setExportLoading(false);

      if (result?.createExporter?.exporter?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.success',
          }),
          key: 'createExporter',
        });
      } else {
        setExportLoading(false);

        message.error({
          content: formatMessage({
            id: 'common.message.fail',
          }),
          key: 'createExporter',
        });
      }
    },
  });

  // Query useEffect
  useEffect(() => {
    if (membersData?.data?.members) {
      const members = membersData?.data?.members;

      setMembers(members?.edges);
      setPagination({ ...pagination, total: members?.totalCount });
    }
  }, [membersData]);

  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    setSelectedRowKeys([]);
    setPagination({ ...pagination, current: pagination.current });

    const sorted = {
      sort: JSON.stringify({
        [sorter.columnKey]: sorter.order === 'ascend' ? 1 : -1,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: 20,
      }),
    };

    const unsorted = {
      sort: JSON.stringify({ createdAt: -1 }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: 20,
      }),
    };

    if (sorter?.columnKey && sorter?.order) {
      membersData.refetch(sorted);
    } else {
      membersData.refetch(unsorted);
    }
  };

  const onSearch = (values) => {
    if (!values.query || !values.search) {
      values.query = undefined;
      values.search = undefined;
    }

    if (values.query && values.search === 'country') {
      values.query = values.query.toUpperCase();
    }

    setSelectedRowKeys([]);
    setPagination({ ...pagination, current: 1 });
    setStartday(
      values.range ? values.range[0].startOf('day').format() : undefined
    );
    setEndday(values.range ? values.range[1].endOf('day').format() : undefined);

    const variables = {
      option: JSON.stringify({ offset: 0, per_page: 20 }),
      filter: JSON.stringify({
        project_id: params.projectId,
        [values.search]: values.query,
        createdAt: values.range
          ? {
              $gte: new Date(values.range[0].startOf('day')),
              $lte: new Date(values.range[1].endOf('day')),
            }
          : undefined,
      }),
    };

    membersData.refetch({
      ...variables,
    });
  };

  const getExportButton = () => {
    if (
      utils.isEmpty(startday) ||
      utils.isEmpty(endday)
    ) {
      return (
        <Tooltip
          title={<FormattedMessage id="common.message.export.tooltip4" />}
        >
          <Button
            size="small"
            type="primary"
            icon={<FileExcelOutlined className="mr-2" />}
            block
            disabled={true}
          >
            <FormattedMessage id="common.export" />
          </Button>
        </Tooltip>
      );
    }

    if (pagination.total < 1) {
      return (
        <Tooltip
          title={<FormattedMessage id="common.message.export.tooltip2" />}
        >
          <Button
            size="small"
            type="primary"
            icon={<FileExcelOutlined className="mr-2" />}
            block
            disabled={true}
          >
            <FormattedMessage id="common.export" />
          </Button>
        </Tooltip>
      );
    }

    return (
      <Popconfirm
        title={<FormattedMessage id="common.message.export.confirm" />}
        onConfirm={() => {
          requestExport(membersData?.variables);
        }}
        okText={<FormattedMessage id="common.ok" />}
        cancelText={<FormattedMessage id="common.no" />}
      >
        <Button
          size="small"
          icon={<FileExcelOutlined className="mr-2" />}
          block
          loading={exportLoading}
        >
          <FormattedMessage id="common.export" />
        </Button>
      </Popconfirm>
    );
  };

  const requestExport = (variables, edges) => {
    setExportLoading(true);

    message.loading({
      content: formatMessage({
        id: 'common.message.loading',
      }),
      key: 'createExporter',
      duration: 0,
    });

    const exporterData = {
      type: 'member_list',
      projectId: params.projectId,
      user_id: current_profile?.local?.email,
      startDate: startday,
      endDate: endday,
    };

    const value1 = {
      project_id: params.projectId,
      // deleted: { $ne: true },
    };

    // deleted : { $ne: true } : 탈퇴되지 않은 사용자
    // deleted : { $ne: false } : 탈퇴된 사용자

    if (variables.search && variables.query) {
      value1[variables.search] = variables.query;
    }

    exporterData.value1 = JSON.stringify(value1);

    createExporter({
      variables: exporterData,
    });
  };

  const showUserProfile = (userInfo) => {
    setUserProfileVisible(true);
    setSelectedUser(userInfo);
  };

  const closeUserProfile = () => {
    setUserProfileVisible(false);
    setSelectedUser(null);
  };

  const columns = [
    {
      title: <FormattedMessage id="member.list.columns.id" />,
      dataIndex: 'node',
      render: (row) => (
        <div className="d-flex" style={{ maxWidth: '500px' }}>
          <AvatarStatus
            src={row?.profile ? row?.profile : "none" }
            name={utils.getID(row.id)}
            icon={<UserOutlined />}
            copyTitle
          />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="member.list.columns.name" />,
      key: 'name',
      dataIndex: ['node', 'name'],
      className: 'td-break',
      sorter: true,
      render: (row) => (
        <div style={{ minWidth: '100px' }}>
          {row}
          <CopyText text={row} />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="member.list.columns.status" />,
      dataIndex: 'node',
      className: 'td-nowrap',
      render: (row) =>
        userutils?.getBlockStatus(row?.memberblock_id, row?.deleted_at),
    },
    {
      title: <FormattedMessage id="member.list.columns.country" />,
      key: 'country',
      dataIndex: ['node', 'country'],
      sorter: true,
      render: (text, record) => {
        return text != null && CountryData.countries[text.toUpperCase()] ? (
          <span>
            {`${CountryData.countries[text.toUpperCase()].name} [${
              CountryData.countries[text.toUpperCase()].alpha2
            }]`}
          </span>
        ) : (
          ''
        );
      },
    },
    {
      title: <FormattedMessage id="member.list.columns.remoteip" />,
      key: 'remoteip',
      dataIndex: ['node', 'remoteip'],
      className: 'td-nowrap',
      sorter: true,
      render: (row) => (
        <div>
          {row}
          <CopyText text={row} />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="member.list.columns.logined_at" />,
      key: 'loginedAt',
      dataIndex: ['node', 'logined_at'],
      sorter: true,
      render: (logined_at) => (
        <div style={{ minWidth: '100px' }}>
          {logined_at ? moment(logined_at).format('YYYY-MM-DD HH:mm:ss') : '-'}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="member.list.columns.created_at" />,
      key: 'createdAt',
      dataIndex: ['node', 'created_at'],
      sorter: true,
      render: (created_at) => (
        <div style={{ minWidth: '100px' }}>
          {created_at ? moment(created_at).format('YYYY-MM-DD HH:mm:ss') : '-'}
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const selectedRowsArray = selectedRows.map((item) => item.node);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRowsArray);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.node?.deleted === true,
    }),
  };
  
  return (
    <div>
      {/* PageHeader */}
      {/* [사용자 추가] 버튼 위치로 인한 헤더 개별 처리 */}
      <div className="d-flex justify-content-between align-items-center">
        <div className="app-page-header mb-0 mr-3 d-block">
          <span className="font-weight-semibold font-size-lg">
            <FormattedMessage id="sidenav.member.list" />
          </span>
          {utils.isTablet(useBreakpoint()) === true ? (
            <Tooltip
              className="ml-1 text-muted"
              placement="topRight"
              title={<FormattedMessage id="sidenav.member.subtitle" />}
            >
              <QuestionCircleOutlined />
            </Tooltip>
          ) : (
            <h5 className="text-muted">
              <FormattedMessage id="sidenav.member.subtitle" />
            </h5>
          )}
        </div>
        {/* <div>
          <UserAdd
            projectId={params.projectId}
            refetch_members={membersData.refetch}
            resetSelectedRowKeys={() => setSelectedRowKeys([])}
          />
        </div> */}
      </div>

      {/* Searchbar */}
      <Form
        className="d-block"
        form={form}
        initialValues={{ search: 'id' }}
        onFinish={onSearch}
      >
        <Row
          gutter={10}
          justify="space-between"
          className={
            utils.isTablet(useBreakpoint()) === true ? 'searchRow-mobile' : 'searchRow'
          }
        >
          {/* Search */}
          <Col>
            <Row gutter={10}>
              <Col>
                <Form.Item name="range">
                  <RangePicker size="small" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="search">
                  <Select style={{ minWidth: 150 }} size="small">
                    {searchData.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="query">
                  <Input size="small" placeholder="Search" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined className="mr-2" />}
                    block
                    htmlType="submit"
                  >
                    <FormattedMessage id="common.search" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* button */}
          <Col>
            <Row gutter={10}>
              <Col>
                <Form.Item>{getExportButton()}</Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <UserBlock
                    projectId={params.projectId}
                    rowData={selectedRows}
                    blockAll={true}
                    disabled={utils.isEmpty(selectedRowKeys)}
                    refetch_members={membersData.refetch}
                    resetSelectedRowKeys={() => setSelectedRowKeys([])}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      {utils.isEmpty(selectedRowKeys) === false && (
        <b className="ml-1">
          {selectedRowKeys.length}
          <FormattedMessage id="member.list.check.length" />
        </b>
      )}
      <Table
        className="mt-2"
        scroll={{ x: 1000 }}
        size="small"
        dataSource={members}
        columns={columns}
        pagination={pagination}
        rowKey={(record) => record.node.id}
        loading={membersData.loading}
        onChange={handleTableChange}
        rowSelection={{
          type: 'checkbox',
          ...rowSelection,
        }}
        onRow={(record, rowIndex) => {
          return {
            onClick: () => {
              showUserProfile(record?.node);
            },
          };
        }}
        footer={() => {
          return (
            <div>
              <FormattedMessage id="common.total.1" />
              {pagination?.total ? formatNumber(pagination.total) : 0}
              <FormattedMessage id="common.total.2" />
            </div>
          );
        }}
      />

      <UserView
        data={selectedUser}
        visible={userProfileVisible}
        refetch_members={membersData.refetch}
        close={closeUserProfile}
      />
    </div>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(User);
