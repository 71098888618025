
import React, { Suspense } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import NavLanguage from 'components/layout-components/NavLanguage';
import Loading from 'components/shared-components/Loading';


import ProjectFromMail from '../../views/verify-views/authentication/ProjectFromMail';
import AccountFromMail from '../../views/verify-views/authentication/AccountFromMail';
import InitPassword from '../../views/verify-views/authentication/InitPassword';
import ChangeAccount from '../../views/verify-views/authentication/ChangeAccount';


export const VeriyfyLayout = () => {
  return (
    <div className="auth-container">
       <Suspense fallback={<Loading cover="page" />}>
      <div style={{ position: 'absolute', left: '100%', marginLeft: -60 }}>
        <NavLanguage dark />
      </div>
      <Routes>
        <Route exact path='/project/*' element={<ProjectFromMail />} />
        <Route exact path='/account/*'  element={<AccountFromMail />} />
        <Route exact path='/initPassword/*' element={<InitPassword />} />
        <Route exact path='/confirmAccount/*'  element={<ChangeAccount />} />        
        <Route exact path='*' element={<Navigate to="/notfound" />} />
      </Routes>
    </Suspense>
    </div>
  );
};

export default VeriyfyLayout;
