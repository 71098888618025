import gql from 'graphql-tag';

const STORY = `
    id
    status
    title {
        lang
        value
        default
    }
    text {
        lang
        value
        default
    }
    url
    createdAt
    updatedAt
    category_id
    status
    required
    hit
`;



const CREATE_STORY_MUTATION = gql`
  mutation createStory(
    $title: [LanguageInput]
    $text: [LanguageInput]
    $projectId: String!
    $category_id: String!
    $status: Int
  ) {
    createStory(
      input: {
        title: $title
        text: $text
        projectId: $projectId
        category_id: $category_id
        status: $status
      }
    ) {
      story {
        ${STORY}
      }
    }
  }
`;

const DELETE_STORY_MUTATION = gql`
   mutation deleteStory(
     $id: ID!
    $projectId: String!
  ) {
    deleteStory(
      input: {
        id: $id
        projectId: $projectId
      }
    ) {
      story {
        id
      }
  }
 }
`;


const UPDATE_STORY_MUTATION = gql`
  mutation updateStory(
    $title: [LanguageInput]
    $text: [LanguageInput]
    $projectId: String!
    $category_id: String!
    $status: Int
    $id: ID!
  ) {
    updateStory(
      input: {
        title: $title
        text: $text
        projectId: $projectId
        category_id: $category_id
        status: $status
        id: $id
      }
    ) {
      story {
        ${STORY}
      }
    }
  }
`;

export { CREATE_STORY_MUTATION, DELETE_STORY_MUTATION, UPDATE_STORY_MUTATION };
