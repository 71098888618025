import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import { useQuery, useMutation } from '@apollo/client';
import { Form, Select, Input, Button, Row, Col, message, Grid} from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import { AUTH_TOKEN } from 'redux/constants/Auth';
import InnerAppLayout from 'layouts/inner-app-layout';
import ChatService from 'services/ChatService';
import ChatMenu from './ChatMenu';
import Conversation from './Conversation';
import ChatAdd from './ChatAdd';
import ChatMessage from './ChatMessage';
import utils from 'utils';
import { useParams } from 'react-router';

import { CHANNELS_QUERY } from '../../../graphql/query/chatting';
import { MEMBER_MUTATION } from '../../../graphql/mutations/member';

const { Option } = Select;
const MAX_CHANNEL_SIZE = 1000;
const searchData = [
  { name: <FormattedMessage id="chat.update.channelid" />, id: 'id' },
  { name: <FormattedMessage id="chat.setting.channelname" />, id: 'name' },
];

const Chat = (props) => {
  const params = useParams();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { current_profile } = props;
  const projectId = params.projectId;
  const { useBreakpoint } = Grid;
  const [channel, setChannel] = useState('');

  const [loading, setLoading] = useState(true);
  const [isConnect, setIsConnect] = useState(false);
  const [channels, setChannels] = useState();
  const [checkedList, setCheckedList] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [channelHasMore, setChannelHasMore] = useState(true);
  const [pagination, setPagination] = useState({
    pageSize: 15,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
  });

  const channelsData = useQuery(CHANNELS_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({
        project_id: projectId,
        state: true,
        [search.search]: search.query,
      }),
      sort: JSON.stringify({ createdAt: -1 }),
      option: JSON.stringify({ offset: 0, per_page: pagination.pageSize }),
    },
    fetchPolicy: 'network-only',
  });

  const [updateMember] = useMutation(MEMBER_MUTATION, {
    onCompleted() {},
    onError(error) {
      console.error(error);
    },
  });

  useEffect(() => {
    ChatService.getInstance().initialize(projectId);    
    ChatService.getInstance().bind('onConnected', function (data) {   
      setLoading(false);
      setIsConnect(true);
      console.log('[onConnected]');   
      ChatService.getInstance().setToken(localStorage.getItem(AUTH_TOKEN));
    });
    ChatService.getInstance().bind(
      'onErrorReceived',
      function (channel, message) {
        console.log('[onErrorReceived]');
      }
    );
    ChatService.getInstance().bind(
      'onDisconnected',
      function (message) {
        console.log('[onDisconnected]-'+ message);
      }
    );
  }, []);

  useEffect(() => {
    if (channelsData?.data?.channels) {
      const channels = channelsData.data.channels;

      let result = [];
      channels.edges.some((item) => {
        result.push(item.node);
      });

      if (channel) {
        setChannel(
          result.filter((item) => {
            return item.id === channel.id;
          })[0]
        );
      }

      setChannels(result);
      setPagination({ ...pagination, total: channels.totalCount });
    }
  }, [channelsData]);

  useEffect(() => {
    return () => {
      if (props?.history?.location?.pathname?.includes('/chat') === false) {
        ChatService.getInstance().disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!current_profile?.id) return;
    const userId = utils.getID(current_profile?.id);
    const name = current_profile?.local?.name;
    const profile = current_profile?.profile;
    const token = localStorage.getItem(AUTH_TOKEN);

    setLoading(true);
    ChatService.getInstance().connect(
      userId,
      name,
      profile,
      token,
      function (err, res) {

        if (err) {
          message.error({
            content: `${formatMessage({ id: 'chat.block' })}${err?.message})`,
            duration: 2,
          });

          setIsConnect(false);
          return;
        }

      
      }
    );

    return () => {
    };
  }, [current_profile]);

  // Functions
  const onSearch = (values) => {
    if (!values.query || !values.search) {
      values.query = undefined;
      values.search = undefined;
    }

    if (values.query && values.search === 'country') {
      values.query = values.query.toUpperCase();
    }

    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
    });
  };

  return (
    <>
      <Form
        className="d-block"
        form={form}
        initialValues={{ search: 'id' }}
        onFinish={onSearch}
      >
        <Row
          gutter={10}
          justify="space-between"
          className={
            utils.isTablet(useBreakpoint()) === true ? 'searchRow-mobile' : 'searchRow'
          }
        >
          <Col>
            <Row gutter={10}>
              <Col>
                <Form.Item>
                  <ChatAdd
                    projectId={params.projectId}
                    channels_refetch={channelsData?.refetch}
                  />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="search">
                  <Select style={{ minWidth: 150 }} size="small">
                    {searchData.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="query">
                  <Input placeholder="Search" size="small" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="button">
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined className="mr-2" />}
                    block
                    htmlType="submit"
                  >
                    <FormattedMessage id="common.search" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col>
            <Row gutter={10}>
              <Col>
                <Form.Item>
                  <ChatMessage
                    projectId={params.projectId}
                    profile={current_profile}
                    userId={current_profile?.local?.email}
                    disabled={checkedList?.length < 1}
                    checkedList={checkedList}
                    channels={channels}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <div className="chat">
        <InnerAppLayout
          sideContent={
            <ChatMenu
              {...props}
              channels={channels}
              checkedList={checkedList}
              setChannel={(channel) => {
                setMenuOpen(false);
                setChannel(channel);
              }}
              setCheckedList={(list) => {
                setCheckedList(list);
              }}
              hasMore={channelHasMore}
              onHasMore={(hasMore) => {
                setChannelHasMore(hasMore);
              }}
              maxChannelSize={MAX_CHANNEL_SIZE}
              loadChannel={() => {
                setPagination({
                  ...pagination,
                  pageSize: pagination.pageSize + 15,
                });
                setChannelHasMore(channels.length >= MAX_CHANNEL_SIZE);
              }}
              isConnect={isConnect}
              loading={loading}
            />
          }
          mainContent={
            <Conversation
              {...props}
              loading={loading}
              channel={channel}
              getNewChannels={channelsData?.refetch}
              setMenuOpen={(value) => setMenuOpen(value)}
            />
          }
          sideContentWidth={450}
          sideContentGutter={false}
          border
          menuOpen={menuOpen}
          setMenuOpen={(value) => setMenuOpen(value)}
        />
      </div>
    </>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(Chat);
