import { gql } from '@apollo/client';

const USERS_QUERY = gql`
  query USERS_QUERY(
    $projectId: String!
    $filter: String!
    $sort: String
    $option: String
  ) {
    users(
      projectId: $projectId
      filter: $filter
      sort: $sort
      option: $option
    ) {
      totalCount
      edges {
        node {
          id
          ncp_project_id
          local {
            email
            name
          }
          retries
          ip
          state
          admin
          manager
          fcm_token
          push
          deleted
          created_at
          updated_at
          logined_at
          deleted_at
          timezone
        }
      }
    }
  }
`;
export { USERS_QUERY };
