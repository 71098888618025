import { gql } from '@apollo/client';

const PROFILE_QUERY = gql`
  query PROFILE_QUERY {
    me {
      id
      ncp_project_id
      local {
        email
        name
        phone
      }
      retries
      ip
      state
      admin
      manager
      fcm_token
      push
      profile
      deleted
      created_at
      updated_at
      deleted_at
      timezone
    }
  }
`;
export { PROFILE_QUERY };
