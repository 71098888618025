import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Grid,Input, Form, Col, Row, Card, message } from 'antd';
import {
  SaveOutlined,
  CloseCircleOutlined,
  MessageOutlined,
} from '@ant-design/icons';
import utils from 'utils';

import { CREATE_CHANNEL_MESSAGE } from '../../../graphql/mutations/messages';

const ChatMessage = (props) => {
  const { projectId, profile, userId, disabled, checkedList, channels } = props;
  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [selectedChannels, setSelectedChannels] = useState([]);
  const [childrenModal, setChildrenModal] = useState(false);

  const [createChannelMessage] = useMutation(CREATE_CHANNEL_MESSAGE);

  useEffect(() => {
    let reuslt = [];

    checkedList.map((id) => {
      channels.map((item) => {
        if (id === utils.getID(item.id)) {
          reuslt.push(item);
        }
      });
    });

    setSelectedChannels(reuslt);
  }, [checkedList, channels]);

  useEffect(() => {
    form.setFieldsValue({
      name: profile?.local?.name,
      profile: profile?.profile,
    });
  }, [profile]);

  // Functions
  const createChannelMessageAction = async (variables) => {
    const { data } = await createChannelMessage({ variables });
    return data;
  };

  const onSave = () => {
    if (utils.isEmpty(userId)) {
      message.error(formatMessage({ id: 'chat.nouserId' }));
      return;
    }

    message.loading({
      content: formatMessage({
        id: 'common.message.loading',
      }),
      key: 'createChannelMessage',
      duration: 0,
    });

    form
      .validateFields()
      .then((values) => {
        const results = checkedList.map(async (item) => {
          const variables = {
            projectId,
            channelId: item,
            content: values?.message,
          };

          return createChannelMessageAction(variables);
        });

        Promise.all(results)
          .then((result) => {
            if (result.length === checkedList.length) {
              onChildrenModalClose();
              message.success({
                content: formatMessage({
                  id: 'common.message.success',
                }),
                key: 'createChannelMessage',
              });
            } else {
              message.error({
                content: formatMessage({
                  id: 'common.message.fail',
                }),
                key: 'createChannelMessage',
              });
            }
          })
          .catch((err) => {
            console.error('createChannelMessage error', err);
            message.error({
              content: formatMessage({
                id: 'common.message.fail',
              }),
              key: 'createChannelMessage',
            });
          });

        return;
      })

      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const showChildrenModal = () => {
    form.setFieldsValue({ message: '' });
    setChildrenModal(true);
  };

  const onChildrenModalClose = () => {
    form.setFieldsValue({ message: '' });
    setChildrenModal(false);
  };

  return (
    <>
      <Button
        className="itsb"
        size="small"
        onClick={showChildrenModal}
        disabled={disabled}
        icon={<MessageOutlined className="mr-2" />}
      >
        <FormattedMessage id="chat.admin.title" />
      </Button>

      <Modal
        open={childrenModal}
        width={utils.isTablet(useBreakpoint()) ? '80%' : 700}
        closable={false}
        forceRender
        destroyOnClose
        onCancel={onChildrenModalClose}
        title={
          <>
            <FormattedMessage id="chat.admin.title" />
            <br />
            <span className="text-muted font-size-base">
              <FormattedMessage id="chat.admin.des" />
            </span>
          </>
        }
        footer={
          <div className="alignRight">
            <Button
              size="small"
              onClick={onChildrenModalClose}
              icon={<CloseCircleOutlined className="mr-2" />}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              disabled={checkedList && checkedList.length < 1}
              onClick={() => form.submit()}
            >
              <FormattedMessage id="chat.admin.submit" />
            </Button>
          </div>
        }
      >
        <Form form={form} layout="horizontal" onFinish={onSave}>
          <Row gutter={16}>
            <Col span={8}>
              <div className="font-weight-bold mb-2">
                <FormattedMessage id="chat.admin.selected" />
                <span className="ml-2">
                  {(checkedList && checkedList.length) || 0}
                  <FormattedMessage id="chat.admin.selected.1" />
                </span>
              </div>
              <Card
                className="text-break w-100 mb-0"
                style={{ height: 300, overflowY: 'auto', boxShadow: 'none' }}
                bodyStyle={{ paddingTop: 10, paddingBottom: 10 }}
              >
                {selectedChannels &&
                  selectedChannels.map((item) => {
                    return (
                      <div className="mb-2" key={item.id}>
                        {item.name}
                      </div>
                    );
                  })}
              </Card>
            </Col>
            <Col span={16}>
              <Row gutter={16}>
                <Col span={24}>
                  <Form.Item
                    className="mb-0"
                    name="message"
                    rules={[
                      {
                        required: true,
                        message: (
                          <FormattedMessage id="chat.admin.rules.message" />
                        ),
                      },
                    ]}
                  >
                    <Input.TextArea
                      style={{ height: 330 }}
                      className="w-100"
                      size="small"
                      placeholder={formatMessage({
                        id: 'chat.admin.placeholder.message',
                      })}
                    />
                  </Form.Item>
                </Col>
              </Row>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

export default ChatMessage;
