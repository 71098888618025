import gql from 'graphql-tag';
const PUSH = `
  id
  target
  message {
    lang
    value
    default
  }
  channel
  title {
    lang
    value
    default
  }
  image {
    lang
    value
    default
  }
  hit
  total
  status
  msg_type
  send_type
  description
  userdata
  response
  data
  scheduleAt
  createdAt
  updatedAt
  completedAt
  timezone
  sens_schedule_id
`;
const PUSHS_QUERY = gql`
  query pushs(
    $projectId: String!
    $filter: String!,
    $sort: String,
    $option: String
  ) {
    pushs(
      projectId: $projectId
      filter: $filter
      sort: $sort
      option: $option
    ) {
        totalCount
        edges {
          node {
            ${PUSH}
          }
        }
    }
  }
`;

const PUSH_QUERY = gql`
  query push($projectId: String!, $id: ID!) {
    push(projectId: $projectId, id: $id) {
      ${PUSH}
    }
  }
`;

export { PUSHS_QUERY, PUSH_QUERY };
