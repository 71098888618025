import React from 'react';
import { Layout, Grid } from 'antd';
import { Routes, Route, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import HeaderViews from 'views/header-views';
import navigationConfig from 'configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import utils from 'utils';
import { Outlet } from 'react-router-dom';


const { Content } = Layout;

export const HeaderLayout = (props) => {
  
  const { useBreakpoint } = Grid;
  const navType = NAV_TYPE_TOP;
  const navCollapsed = SIDE_NAV_COLLAPSED_WIDTH;
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    window.location.pathname
  );
  const isMobile = false; // isMobile
  const isNavTop = navType === NAV_TYPE_TOP;

  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  return (
    <Layout>
      <HeaderNav isMobile={isMobile} isHide={true} />
      <Layout className="app-container">
        <Layout
          className="app-layout"
          style={{ paddingLeft: getLayoutGutter() }}
        >
          <div className={`app-content`}>
            <PageHeader
              display={currentRouteInfo?.breadcrumb}
              title={currentRouteInfo?.title}
              isTablet={utils.isTablet(useBreakpoint())}
            />
            <Content>
              <Routes>
                <Route path="" component={HeaderViews} />
              </Routes>
            </Content>
          </div>
          {/* <Footer /> */}
        </Layout>
      </Layout>
      {isMobile && <MobileNav />}
    </Layout>
  );
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, locale } = theme;
  return { navCollapsed, navType, locale };
};

export default connect(mapStateToProps)(HeaderLayout);
