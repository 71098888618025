import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Radio, Col, message, Alert, Input, Tooltip,
  InputNumber } from 'antd';
import { SaveOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { FormattedMessage, useIntl } from 'react-intl';
import KeyGroup from './KeyGroup';
import { useParams } from 'react-router-dom';

import { PROJECT_ACCESSS } from '../../../graphql/query/project';
import { UPDATE_PROJECT_ACCESS_MUTATION } from '../../../graphql/mutations/project';

const Project = ({ match }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  // const [domains, setDomains] = useState([]);
  const [ips, setIps] = useState([]);
  const [exts, setExts] = useState([]);
  const [url_allows, setUrlAllows] = useState([]);
  const [isMutation, setIsMutation] = useState(false);
  const params = useParams();
  const company_id = localStorage.getItem('company_id');

  const projectData = useQuery(PROJECT_ACCESSS, {
    variables: { ncpId: company_id, id:params.projectId },
    fetchPolicy: 'network-only',
  });

  const [updateProject] = useMutation(UPDATE_PROJECT_ACCESS_MUTATION, {
    onCompleted(result) {
      setIsMutation(false);

      if (result?.updateProject?.project?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateProject',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'updateProject',
        });
      }
    },
    onError(err) {
      setIsMutation(false);

      message.error({
        content: formatMessage({
          id: 'common.message.save.fail',
        }),
        key: 'updateProject',
      });
    },
  });

  useEffect(() => {
    if (projectData.data && projectData.data.project) {
      const dataSource = projectData.data.project;
      if(!dataSource.access.permissions)
        dataSource.access.permissions = 'DENY';
      form.setFieldsValue(dataSource);  
      form.setFieldsValue(dataSource.access);
      setIps(dataSource.access?.ips); 
      setExts(dataSource.access?.exts);
      setUrlAllows(dataSource.access?.url_allows);
     
    }
  }, [projectData]);

  const onFinish = () => {
    const values = form?.getFieldsValue();

    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateProject',
      duration: 0,
    });

    setIsMutation(true);

    updateProject({
      variables: {
        id:params.projectId,
        anonymous_enable: values?.anonymous_enable === true,
        access: {
          permissions: values?.permissions,
          // domains,
          ips,
          exts,
          max_upload_size: values?.max_upload_size,
          download_expire_days: values?.download_expire_days,
          url_deny_type: values?.url_deny_type,
          url_allows,
        },
      },
    });
  };

  return (
    <div className="mt-4">
      <Form layout="vertical" name="basicInformation" form={form}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={ROW_GUTTER}>
              <Col xs={24} sm={24} md={24}>
                {/* Token 인증 */}
                <Form.Item
                  name="anonymous_enable"
                  label={
                    <>
                      <FormattedMessage id="settings.security.anonymous_enable" />
                      <Tooltip
                        className="ml-1"
                        title={
                          <FormattedMessage id="settings.security.anonymous_enable.tooltip" />
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                  <Radio.Group size="small">
                    <Radio value={true}>
                      <FormattedMessage id="settings.security.anonymous_enable.able" />
                    </Radio>
                    <Radio value={false}>
                      <FormattedMessage id="settings.security.anonymous_enable.disable" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {/* Access token 권한 */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="permissions"
                  label={
                    <>
                      <FormattedMessage id="settings.security.domain.permissions" />
                      <Tooltip
                        className="ml-1"
                        title={
                          <FormattedMessage id="settings.security.domain.permissions.tooltip" />
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                  <Radio.Group size="small">
                    <Radio value="RW">
                      <FormattedMessage id="settings.security.domain.permissions.RW" />
                    </Radio>
                    <Radio value="R">
                      <FormattedMessage id="settings.security.domain.permissions.read" />
                    </Radio>
                    <Radio value="DENY">
                      <FormattedMessage id="settings.security.domain.permissions.block" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>

              {/* Access IP */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="ips"
                  label={
                    <>
                      <FormattedMessage id="settings.security.domain.ips" />
                      <Tooltip
                        className="ml-1"
                        title={
                          <FormattedMessage id="settings.security.domain.ips.tooltip" />
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                  <KeyGroup
                    type="ip"
                    data={ips}
                    isMutation={isMutation}
                    handleChange={(array) => {
                      setIps(array);
                    }}
                  />
                </Form.Item>
              </Col>
               {/* Enable Ext */}
               <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="ips"
                  label={
                    <>                                          
                      <FormattedMessage id="settings.security.ext.upload" /> 
                      <Tooltip
                        className="ml-1"
                        title={
                          <FormattedMessage id="settings.security.ext.upload.tooltip" />                        
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                   <KeyGroup
                    type="ext"
                    data={exts}
                    isMutation={isMutation}
                    handleChange={(array) => {
                      setExts(array);
                    }}
                  />
                  
                </Form.Item>

               
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item>
                <Alert                  
                    description={<FormattedMessage id="settings.security.ext.upload.example" />}
                    type="info"
                  />
                </Form.Item>              
              </Col>
               {/* Max Upload Size */}
               <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="max_upload_size"
                  
                  label={
                      <FormattedMessage id="settings.security.max_uploadsize" />              }
                >
                <InputNumber size="small" min={1} max={1000}/>
                </Form.Item>
              </Col>
               {/* File Expire Day*/}
               <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="download_expire_days"
                  label={
                      <FormattedMessage id="settings.security.expireday" />              }
                >
                  <InputNumber size="small"  min={0} max={365}/>
                </Form.Item>
              </Col>
              
            </Row>
                    
             {/* URL Deny 권한 */}
             <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="url_deny_type"
                  label={
                    <>
                      <FormattedMessage id="settings.security.url.permissions" />
                      <Tooltip
                        className="ml-1"
                        title={
                          <FormattedMessage id="settings.security.url.permissions.tooltip" />
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                  <Radio.Group size="small">
                   <Radio value="all">
                      <FormattedMessage id="settings.security.url.permissions.all" />
                    </Radio>
                    <Radio value="block">
                      <FormattedMessage id="settings.security.url.permissions.block" />
                    </Radio>
                    <Radio value="whitelist">
                      <FormattedMessage id="settings.security.url.permissions.whitelist" />
                    </Radio>
                   
                  </Radio.Group>
                </Form.Item>
              </Col>

              {/* urls */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="url_allows"
                  label={
                    <>
                      <FormattedMessage id="settings.security.url.list" />
                      <Tooltip
                        className="ml-1"
                        title={
                          <FormattedMessage id="settings.security.url.list.tooltip" />
                        }
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                >
                  <KeyGroup
                    type="url_allows"
                    data={url_allows}
                    isMutation={isMutation}
                    handleChange={(array) => {
                      setUrlAllows(array);
                    }}
                  />
                </Form.Item>
              </Col>

            <Button
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              size="small"
              loading={isMutation}
              onClick={onFinish}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Project;
