import { gql } from '@apollo/client';

const MESSAGES_QUERY = gql`
  query messages(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    messages(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          id
          message_id
          channel_id
          sort_id
          translate
          message_type
          attachment_filenames {
            name
            size
            type
            url
          }
          sender {
            senderid: id
            name
            profile
          }
          admin {
            adminid: id
            name
          }
          mentions
          mentions_everyone
          content
          sended_at
          created_at
        }
      }
    }
  }
`;

export { MESSAGES_QUERY };
