import React from 'react';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Menu } from 'antd';
import { ProfileOutlined, TeamOutlined, ApiOutlined, InteractionOutlined, LockOutlined } from '@ant-design/icons';

import InnerAppLayout from 'layouts/inner-app-layout';
import Project from './Project';
import Security from './Security';
import Integration from './Integration';
import User from './User';


const SettingOption = ({ match, location, profile }) => {
  return (
    <Menu      
      defaultSelectedKeys={`/edit-project`}
      defaultOpenKeys={`/edit-project`}
      mode="inline"
      selectedKeys={[window?.location?.pathname?.split('/').pop()]}
    >
      <Menu.Item key='edit-project'>
        <ProfileOutlined />
        <span>
          <FormattedMessage id="settings.menu1" />
        </span>
        <Link to={'edit-project'} />
      </Menu.Item>
      <Menu.Item key='edit-security'>
        <LockOutlined />
        <span>
          <FormattedMessage id="settings.menu4" />
        </span>
        <Link to={'edit-security'} />
      </Menu.Item>
      <Menu.Item key='integration'>
      <ApiOutlined />
        <span>
          <FormattedMessage id="settings.integration" />
        </span>
        <Link to={'integration'} />
      </Menu.Item>
      {profile && profile.admin ? (
        <Menu.Item key='edit-user'>
          <TeamOutlined />
          <span>
            <FormattedMessage id="settings.menu2" />
          </span>
          <Link to={'edit-user'} />
        </Menu.Item>
      ) : (
        ''
      )}
    </Menu>
  );
};

const SettingContent = ({ match, profile }) => {
  return (
    <Routes>      
      <Route exact path='/' element={<Navigate to="edit-project" />}/>
      <Route exact path='/edit-project/*' element={<Project />} />
      <Route exact path='/edit-security/*' element={<Security />} />
      <Route exact path='/integration/*' element={<Integration />} />
      {profile && profile.admin ? (
        <Route exact path='/edit-user/*' element={<User />} />
      ) : (
        ''
      )}
      <Route exact path='*' element={<Navigate to="/notfound" />} />
    </Routes>
  );
};

export const Setting = (props) => {
  const { current_profile } = props;

  return (
    <InnerAppLayout
      sideContentWidth={320}
      sideContent={<SettingOption {...props} profile={current_profile} />}
      mainContent={<SettingContent {...props} profile={current_profile} />}
    />
  );
};


export default Setting;
