import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import { useParams } from 'react-router';
import {
  Modal,
  Button,
  Input,
  message,
  Form,
  Col,
  Row,
  DatePicker,
  Radio,
  Grid,
  Checkbox,
  Popconfirm,
} from 'antd';
import {
  SaveOutlined,
  UserDeleteOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import utils from 'utils';

import {
  CREATE_MEMBERBLOCK_MUTATION,
  DELETE_MEMBERBLOCK_MUTATION,
} from '../../../../graphql/mutations/member';

const options = [
  {
    label: <FormattedMessage id="member.list.block.form.label.period.1" />,
    value: '1',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.2" />,
    value: '7',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.3" />,
    value: '30',
  },
];

const UserBlock = (props) => {
  const {
    current_profile,
    projectId,
    rowData,
    extraClassName,
    disabled,
    blockAll,
    blockData,
    deleted,

    refetch_member,
    refetch_members,
    resetSelectedRowKeys,
  } = props;

  const params = useParams();
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { useBreakpoint } = Grid;
  const [periodOption, setPeriodOption] = useState('1');
  const [childrenModal, setChildrenModal] = useState(false);
  const [blockStatus, setBlockStatus] = useState(1);

  // Mutation
  const [createMemberBlock] = useMutation(CREATE_MEMBERBLOCK_MUTATION);
  const [deleteMemberBlock] = useMutation(DELETE_MEMBERBLOCK_MUTATION, {
    onCompleted(result) {
      if (result?.deleteMemberBlock?.memberBlock?.id) {
        refetch_member();
        onChildrenModalClose();
        message.success({
          content: formatMessage({
            id: 'common.message.delete.success',
          }),
          key: 'deleteBlock',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.delete.fail',
          }),
          key: 'deleteBlock',
        });
      }
    },
  });

  // UseEffect
  useEffect(() => {
    if (!blockData) {
      form.resetFields();
      return;
    }

    setField();
  }, [blockData]);

  useEffect(() => {
    if (utils.isEmpty(periodOption) === false) {
      form.setFieldsValue({
        period: [moment(), moment().add(periodOption, 'days')],
      });
    }
  }, [periodOption]);

  useEffect(() => {
    // 일시 정지
    if (blockStatus === 1) {
      setPeriodOption('1');
    }

    // 영구 정지
    if (blockStatus === 2) {
      setPeriodOption(null);

      form.setFieldsValue({
        period: [moment(), moment('2099').endOf('year')],
      });
    }
  }, [blockStatus]);

  // function
  const setField = () => {
    setPeriodOption(blockData ? null : '1');
    setBlockStatus(blockData?.ended_at.substr(0, 4) === '2099' ? 2 : 1);

    form.setFieldsValue({
      kick: false,
      message: blockData?.messageMulti[0]?.value,
      period: blockData
        ? [moment(blockData?.started_at), moment(blockData?.ended_at)]
        : [moment(), moment().add('1', 'days')],
    });
  };

  const createBlockAction = async (variables) => {
    
    const { data } = await createMemberBlock({ variables });
    return data;
  };

  const deleteBlock = () => {
    message.loading({
      content: formatMessage({
        id: 'common.message.deleting',
      }),
      key: 'deleteBlock',
      duration: 0,
    });
    const variables = {
      projectId,
      id: utils.getID(blockData.id),
    };

    deleteMemberBlock({ variables });
  };

  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (utils.getID(current_profile?.id) === rowData?.[0]?.member_id) {
          message.error({
            content: formatMessage({
              id: 'member.list.block.form.error1',
            }),
            key: 'createBlock',
          });
          return;
        }

        message.loading({
          content: formatMessage({
            id: 'common.message.updating',
          }),
          key: 'createBlock',
          duration: 0,
        });

        const results = rowData.map(async (item) => {
          const memberId = utils.getID(item.id);
          let variables = {
            projectId,
            memberId,
            status: 1,
            kick: values?.kick,
            startedAt: values?.period?.[0] || undefined,
            endedAt: values?.period?.[1] || undefined,
            messageMulti: [
              { default: true, value: values?.message, lang: 'ko' },
            ],
          };

          return createBlockAction(variables);
        });

        Promise.all(results)
          .then((result) => {
            if (result.length === rowData.length) {
              if (refetch_member) refetch_member();
              if (refetch_members) refetch_members();
              if (resetSelectedRowKeys) resetSelectedRowKeys();

              onChildrenModalClose();

              message.success({
                content: formatMessage({
                  id: 'common.message.save.success',
                }),
                key: 'createBlock',
              });
            } else {
              message.error({
                content: formatMessage({
                  id: 'common.message.save.fail',
                }),
                key: 'createBlock',
              });
            }
          })
          .catch((err) => {
            message.error({
              content: err.message,
              key: 'createBlock',
            });
            console.error('createBlockAction Error', err);
          });

        return;
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const showChildrenModal = () => {
    setField();
    setChildrenModal(true);
  };

  const onChildrenModalClose = () => {
    setField();
    setChildrenModal(false);
  };

  return (
    <>
      <Button
        size="small"
        className={`itsb ${extraClassName}`}
        icon={<UserDeleteOutlined className="mr-2" />}
        disabled={disabled}
        onClick={showChildrenModal}
      >
        <FormattedMessage id="member.list.button.block" />
      </Button>

      <Modal
        title={<FormattedMessage id="member.list.block.title" />}
        open={childrenModal}
        width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
        closable={false}
        onCancel={onChildrenModalClose}
        forceRender
        destroyOnClose
        footer={
          <div className="text-right">
            <Button
              size="small"
              onClick={onChildrenModalClose}
              icon={<CloseCircleOutlined className="mr-2" />}
            >
              <FormattedMessage id="common.cancel" />
            </Button>
            {blockAll !== true && blockData ? (
              <Popconfirm
                title={<FormattedMessage id="common.message.delete.confirm" />}
                okText={<FormattedMessage id="common.ok" />}
                cancelText={<FormattedMessage id="common.no" />}
                onConfirm={deleteBlock}
              >
                <Button
                  className="mb-1"
                  size="small"
                  danger
                  icon={<DeleteOutlined className="mr-2" />}
                >
                  <FormattedMessage id="common.delete" />
                </Button>
              </Popconfirm>
            ) : (
              ''
            )}
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              disabled={deleted}
              onClick={() => form.submit()}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        }
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={onSave}
          initialValues={{
            period: [moment(), moment().add('1', 'days')],
          }}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage id="member.list.block.form.label.status" />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="member.list.block.form.rules.status" />
                    ),
                  },
                ]}
              >
                <Radio.Group
                  value={blockStatus}
                  onChange={(e) => setBlockStatus(e.target.value)}
                >
                  <Radio value={1}>
                    <FormattedMessage id="member.list.block.form.label.status.1" />
                  </Radio>
                  <Radio value={2}>
                    <FormattedMessage id="member.list.block.form.label.status.2" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="kick"
                label={
                  <FormattedMessage id="member.list.block.form.label.kick" />
                }
                valuePropName="checked"
              >
                <Checkbox>
                  <FormattedMessage id="member.list.block.form.checkbox.kick.2" />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="message"
                label={
                  <FormattedMessage id="member.list.block.form.label.message" />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="member.list.block.form.rules.message" />
                    ),
                  },
                ]}
              >
                <Input
                  size="small"
                  style={{ width: '100%' }}
                  addonBefore="KR"
                  placeholder={formatMessage({
                    id: 'member.list.block.form.placeholder.message',
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="period"
                label={
                  <div className="justify-content-between d-flex w-100">
                    <FormattedMessage id="member.list.block.form.label.period" />
                    <Radio.Group
                      className="itsb"
                      size="small"
                      optionType="button"
                      disabled={blockStatus === 2 ? true : false}
                      options={options}
                      value={periodOption}
                      onChange={(e) => setPeriodOption(e.target.value)}
                    />
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="member.list.block.form.rules.period" />
                    ),
                  },
                ]}
              >
                <DatePicker.RangePicker
                  className="w-100"
                  size="small"
                  showTime
                  disabled={blockStatus === 2 ? true : false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(UserBlock);
