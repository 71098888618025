import React, { useEffect, useState } from 'react';
import {
  Form,
  Button,
  Input,
  Row,
  Radio,
  Col,
  message,
  InputNumber,
  Switch,
  Space,
  Divider,
  Modal,
  Select,
  Popconfirm,
  Tooltip,
} from 'antd';

import {
  CheckOutlined,
  CloseOutlined,
  SaveOutlined,
  ContainerOutlined,
  ExclamationCircleOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';


import { useQuery, useMutation } from '@apollo/client';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import Flex from 'components/shared-components/Flex';
import CopyText from 'components/shared-components/CopyText';
import { FormattedMessage, useIntl } from 'react-intl';
import { useParams } from 'react-router-dom';
import { PROJECT_QUERY } from '../../../graphql/query/project';
import { UPDATE_PROJECT_MUTATION } from '../../../graphql/mutations/project';

import { APIKEY_QUERY } from '../../../graphql/query/apikeys';
import {
  CREATE_APIKEY_MUTATION,
  DELETE_APIKEY_MUTATION,
} from '../../../graphql/mutations/apikeys';
import Utils from 'utils';
import { guideConfig } from 'config';
import moment from 'moment';

const { Option } = Select;

const { TextArea } = Input;



const Project = ({ match }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  
  const [size, setSize] = useState('middle');
  const [loading, setLoading] = useState(false);
  const [papagoCheck, setPapagoCheck] = useState(false);
  const [papagoModal, setPapagoModal] = useState(false);
  const [aiFilterCheck, setAiFilterCheck] = useState(false);
  const [apiKey, setApiKey] = useState({});
  const params = useParams();
  const company_id = localStorage.getItem('company_id');
  const projectData = useQuery(PROJECT_QUERY, {
    variables: { ncpId: company_id, id:params.projectId },
    fetchPolicy: 'network-only',
  });
  const languageCheckOptions = [
    { label: formatMessage({ id: 'language.ar' }), value: 'ar' },
    { label: formatMessage({ id: 'language.bg' }), value: 'bg' },
    { label: formatMessage({ id: 'language.ca' }), value: 'ca' },
    { label: formatMessage({ id: 'language.zh-Hans' }), value: 'zh-Hans' },
    { label: formatMessage({ id: 'language.zh-Hant' }), value: 'zh-Hant' },
    { label: formatMessage({ id: 'language.hr' }), value: 'hr' },
    { label: formatMessage({ id: 'language.cs' }), value: 'cs' },
    { label: formatMessage({ id: 'language.da' }), value: 'da' },
    { label: formatMessage({ id: 'language.nl' }), value: 'nl' },
    { label: formatMessage({ id: 'language.en' }), value: 'en' },
    { label: formatMessage({ id: 'language.et' }), value: 'et' },
    { label: formatMessage({ id: 'language.tl' }), value: 'tl' },
    { label: formatMessage({ id: 'language.fi' }), value: 'fi' },
    { label: formatMessage({ id: 'language.fr' }), value: 'fr' },
    { label: formatMessage({ id: 'language.de' }), value: 'de' },
    { label: formatMessage({ id: 'language.el' }), value: 'el' },
    { label: formatMessage({ id: 'language.he' }), value: 'he' },
    { label: formatMessage({ id: 'language.hi' }), value: 'hi' },
    { label: formatMessage({ id: 'language.hu' }), value: 'hu' },
    { label: formatMessage({ id: 'language.is' }), value: 'is' },
    { label: formatMessage({ id: 'language.id' }), value: 'id' },
    { label: formatMessage({ id: 'language.it' }), value: 'it' },
    { label: formatMessage({ id: 'language.ja' }), value: 'ja' },
    { label: formatMessage({ id: 'language.ko' }), value: 'ko' },
    { label: formatMessage({ id: 'language.lv' }), value: 'lv' },
    { label: formatMessage({ id: 'language.lt' }), value: 'lt' },
    { label: formatMessage({ id: 'language.no' }), value: 'no' },
    { label: formatMessage({ id: 'language.pl' }), value: 'pl' },
    { label: formatMessage({ id: 'language.pt' }), value: 'pt' },
    { label: formatMessage({ id: 'language.ro' }), value: 'ro' },
    { label: formatMessage({ id: 'language.ru' }), value: 'ru' },
    { label: formatMessage({ id: 'language.sr' }), value: 'sr' },
    { label: formatMessage({ id: 'language.sk' }), value: 'sk' },
    { label: formatMessage({ id: 'language.sl' }), value: 'sl' },
    { label: formatMessage({ id: 'language.es' }), value: 'es' },
    { label: formatMessage({ id: 'language.sv' }), value: 'sv' },
    { label: formatMessage({ id: 'language.th' }), value: 'th' },
    { label: formatMessage({ id: 'language.tr' }), value: 'tr' },
    { label: formatMessage({ id: 'language.uk' }), value: 'uk' },
    { label: formatMessage({ id: 'language.ur' }), value: 'ur' },
    { label: formatMessage({ id: 'language.vi' }), value: 'vi' },
    { label: formatMessage({ id: 'language.ms' }), value: 'ms' },
  ];

  const options = [];
  for (let i = 0; i < languageCheckOptions.length; i++) {
    options.push({
      value: languageCheckOptions[i].value,
      label:languageCheckOptions[i].label,
    });
  }
  const apikeyData = useQuery(APIKEY_QUERY, {
    variables: {
      projectId:params.projectId,
      filter: JSON.stringify({ project_id:params.projectId }),
    },
    fetchPolicy: 'network-only',
  });

  const [updateProject] = useMutation(UPDATE_PROJECT_MUTATION, {
    onCompleted(result) {
      setLoading(false);
      if (result?.updateProject?.project?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateProject',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'updateProject',
        });
      }
    },
    onError(err) {
      setLoading(false);
      message.error({
        content: formatMessage({
          id: 'common.message.save.fail',
        }),
        key: 'updateProject',
      });
    },
  });

  const [deleteApiKey] = useMutation(DELETE_APIKEY_MUTATION, {
    onCompleted(result) {
      if (result?.deleteApiKey?.apiKey?.id) {
        createApiKey({
          variables: {
            projectId:params.projectId,
            expiredAt: moment.utc('2099-12-31 23:59:59'),
          },
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.fail',
          }),
          key: 'deleteApiKey',
        });
      }
    },
  });

  const [createApiKey] = useMutation(CREATE_APIKEY_MUTATION, {
    onCompleted(result) {
      if (result?.createApiKey?.apiKey?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.success',
          }),
          key: 'deleteApiKey',
        });

        apikeyData.refetch();
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.fail',
          }),
          key: 'deleteApiKey',
        });
      }
    },
  });

  useEffect(() => {
    if (projectData.data && projectData.data.project) {
      const dataSource = projectData.data.project;

      form.setFieldsValue({
        max_message_length: dataSource.max_message_length,
        profanity_deny_type: dataSource.profanity_deny_type,
        name_regex: dataSource?.name_regex,
        id_regex: dataSource?.id_regex,
        name_denied: dataSource?.name_denied,
        profanity_filter: dataSource.profanity_filter,
        objectstorage_enable: dataSource.ncp?.objectstorage_enable,
        objectstorage_bucket: dataSource.ncp?.objectstorage_bucket,
        objectstorage_access_key: dataSource.ncp?.objectstorage_access_key,
        objectstorage_secret_key: dataSource.ncp?.objectstorage_secret_key,
        sens_push_enable: dataSource.ncp?.sens_push_enable,
        sens_push_service_id: dataSource.ncp?.sens_push_service_id,
        sens_push_secret_key: dataSource.ncp?.sens_push_secret_key,
        sens_push_limit: dataSource.ncp?.sens_push_limit,
        // sens_sms_enable: dataSource.ncp?.sens_sms_enable,
        // sens_sms_service_id: dataSource.ncp?.sens_sms_service_id,
        // sens_sms_secret_key: dataSource.ncp?.sens_sms_secret_key,
        // sens_sms_limit: dataSource.ncp?.sens_sms_limit,
        papago_client_id: dataSource.ncp?.papago_client_id,
        papago_client_secret: dataSource.ncp?.papago_client_secret,
        papago_limit: dataSource.ncp?.papago_limit,
      });

      setPapagoCheck(dataSource.ncp?.papago_enable || false);
      setAiFilterCheck(dataSource.ncp?.ai_filter_enable || false);
    }
  }, [projectData]);

  useEffect(() => {
    if (apikeyData.data && apikeyData.data.apikeys) {
      let data = apikeyData.data.apikeys.edges;

      if (data.length > 0) {
        setApiKey(data[0]?.node);
      }
    }
  }, [apikeyData]);

  const setProfanityFiled = () => {
    form.setFieldsValue({
      profanity_filter: Utils.basicProfanity,
    });
  };

  const onFinish = () => {
    const values = form?.getFieldsValue();

    if (Utils.isEmpty(values?.max_message_length)) {
      message.error({
        content: formatMessage({
          id: 'settings.project.rules.max_message_length',
        }),
      });
      return;
    }

    
    if (Utils.isEmpty(values?.name_regex)) {
      message.error({
        content: formatMessage({
          id: 'settings.project.rules.name_regex',
        }),
      });
      return;
    }

    if (
      values?.max_message_length &&
      0 < values.max_message_length < 65536 === false
    ) {
      message.error({
        content: formatMessage({ id: 'settings.project.error1' }),
      });
      return;
    }

    setLoading(true);

    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateProject',
      duration: 0,
    });

    const excloudedData = {
      objectstorage_enable: values.objectstorage_enable,
      objectstorage_bucket: values.objectstorage_bucket,
      objectstorage_access_key: values.objectstorage_access_key,
      objectstorage_secret_key: values.objectstorage_secret_key,
      sens_push_enable: values.sens_push_enable,
      sens_push_service_id: values.sens_push_service_id,
      sens_push_secret_key: values.sens_push_secret_key,
      sens_push_limit: values.sens_push_limit,
      sens_sms_enable: values.sens_sms_enable,
      sens_sms_service_id: values.sens_sms_service_id,
      sens_sms_secret_key: values.sens_sms_secret_key,
      sens_sms_limit: values.sens_sms_limit,
      papago_enable: papagoCheck,
      ai_filter_enable: aiFilterCheck,
      papago_client_id: values.papago_client_id,
      papago_client_secret: values.papago_client_secret,
      papago_limit: values.papago_limit,
    };
    updateProject({
      variables: {
        id:params.projectId,
        max_message_length: values.max_message_length,
        profanity_deny_type: values.profanity_deny_type,
        profanity_filter: values.profanity_filter,
        id_regex: values.is_regex,
        name_denied: values.name_denied,
        name_regex: values.name_regex,
        ncp: { ...Utils.excloud(excloudedData) },
      },
    });
  };

  return (
    <div className="mt-4">
      <Form layout="vertical" name="basicInformation" form={form}>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24}>
            <Row gutter={ROW_GUTTER}>
              {/* NAVER Cloud ID*/}
              <Col xs={24} sm={24} md={24}>
                <Form.Item label="NAVER Cloud ID">
                  {localStorage.getItem('company_id')}
                  <CopyText text={localStorage.getItem('company_id')} />
                </Form.Item>
              </Col>
              {/* 프로젝트명*/}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="settings.project.label.project_name" />
                  }
                >
                  {projectData?.data?.project?.name}
                  <CopyText text={projectData?.data?.project?.name} />
                </Form.Item>
              </Col>
              {/* 프로젝트 ID */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="settings.project.label.project_id" />
                  }
                >
                  {Utils.getID(projectData?.data?.project?.id)}
                  <CopyText
                    text={Utils.getID(projectData?.data?.project?.id)}
                  />
                </Form.Item>
              </Col>
              {/* API Key */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item label={'API Key'}>
                  <span style={{ wordBreak: 'break-all' }}>{apiKey.key}</span>
                  <CopyText text={apiKey.key} />
                  <Popconfirm
                    title={formatMessage({
                      id: 'settings.project.apikey.confirm',
                    })}
                    okText={formatMessage({ id: 'common.ok' })}
                    cancelText={formatMessage({ id: 'common.no' })}
                    onConfirm={() => {
                      message.loading({
                        content: formatMessage({
                          id: 'common.message.loading',
                        }),
                        key: 'deleteApiKey',
                        duration: 0,
                      });

                      deleteApiKey({
                        variables: {
                          id: Utils.getID(apiKey.id),
                          projectId:params.projectId,
                        },
                      });
                    }}
                  >
                    <Button style={{ marginLeft: 15 }} size="small">
                      <FormattedMessage id="settings.project.apikey.reissuance" />
                    </Button>
                  </Popconfirm>
                </Form.Item>
              </Col>
              {/* 메시지 길이 제한 */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <>
                      <FormattedMessage id="settings.project.label.max_message_length" />
                      <Tooltip
                        title={
                          <FormattedMessage id="settings.project.error1" />
                        }
                        className="ml-1"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                    </>
                  }
                  name="max_message_length"
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="settings.project.rules.max_message_length" />
                      ),
                    },
                  ]}
                >
                  <InputNumber size="small" min={1} max={65535} />
                </Form.Item>
              </Col>
               {/* 아이디 정규식 */}
               {/* <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="id_regex"
                  label={
                    <>
                    <FormattedMessage id="settings.project.label.id_regex" />
                    <Tooltip
                          title={
                            <FormattedMessage id="settings.settings.nickname.tips" />
                          }
                          className="ml-1"
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                    </>
                  }
                  rules={[
                    {
                      required: true,
                      message: (
                        <FormattedMessage id="settings.project.rules.id_regex" />
                      ),
                    },
                  ]}
                >
                  <Input size="small" />
                </Form.Item>
              </Col> */}
              {/* 닉네임 정규식 */}
              <Col xs={16} sm={12} md={12}>
              <Form.Item
                name="name_regex"
                label={
                  <>
                  <FormattedMessage id="settings.project.label.name_regex" />
                  <Tooltip
                        title={
                          <FormattedMessage id="settings.settings.nickname.tips" />
                        }
                        className="ml-1"
                      >
                        <QuestionCircleOutlined />
                      </Tooltip>
                  </>
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="settings.project.rules.name_regex" />
                    ),
                  },
                ]}
              >
                <Input size="small" />
              </Form.Item>
              </Col>
              
              {/* 금칙어 제한 타입 */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="settings.project.label.profanity_deny_type" />
                  }
                  name="profanity_deny_type"
                >
                  <Radio.Group size="small">
                    <Radio value="none">
                      <FormattedMessage id="settings.project.radio.profanity_deny_type.none" />
                    </Radio>
                    <Radio value="star">
                      <FormattedMessage id="settings.project.radio.profanity_deny_type.star" />
                    </Radio>
                    <Radio value="block">
                      <FormattedMessage id="settings.project.radio.profanity_deny_type.block" />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
              </Col>
              {/* 금칙어 설정 */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <div style={{ width: '100%' }}>
                      <Flex justifyContent="between" alignItems="center">
                        <FormattedMessage id="settings.project.label.profanity_filter" />
                        <Button
                          className="ml-2"
                          icon={<ContainerOutlined className="mr-2" />}
                          size="small"
                          onClick={() => setProfanityFiled()}
                        >
                          <FormattedMessage id="settings.project.label.profanity_filter.button" />
                        </Button>
                      </Flex>
                    </div>
                  }
                  name="profanity_filter"
                >
                  <TextArea rows={6} size="smail" />
                </Form.Item>
              </Col>
              {/* AI 필터링  */}
              {/* <Col xs={24} sm={24} md={24}>
                <Form.Item label={<FormattedMessage id="settings.project.label.profanity_ai" />}>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={aiFilterCheck}
                    onClick={(e) => {
                      if (e === true) {
                        setAiFilterCheck(true);
                        return;
                      }
                      setAiFilterCheck(false);
                    }}
                  />
                </Form.Item>
              </Col> */}
              {/* <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="language"
                  label={<FormattedMessage id="settings.general.language" />}>
                 
                <Select
                    mode="tags"                    
                    placeholder={<FormattedMessage id="settings.general.language.desc" />}
                    size={size}
                    style={{
                      width: '100%',
                    }}
                    style={{ width: '100%' }}
                    options={options}
                   
                    // onChange={(checkedValue) => {
                    //   if (checkedValue.length > 0) {
                    //     setLanguage(checkedValue.toString());
                    //   } else {
                    //     message.warning(formatMessage({ id: 'settings.general.language.error' }));
                    //   }
                    // }}
                  />
                </Form.Item>
              </Col> */}
            </Row>
            {/* Papago */}
            <Divider>
              <FormattedMessage id="settings.project.divider.papago" />
            </Divider>
            <Row>
              <Col xs={24} sm={24} md={24}>
                <Form.Item label={<FormattedMessage id="common.enable" />}>
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                    checked={papagoCheck}
                    onClick={(e) => {
                      if (e === true) {
                        setPapagoModal(true);
                        return;
                      }
                      setPapagoCheck(false);
                    }}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="settings.project.label.ncp.papago_client_id" />
                  }
                  name="papago_client_id"
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="settings.project.label.ncp.papago_client_secret" />
                  }
                  name="papago_client_secret"
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  label={
                    <FormattedMessage id="settings.project.label.ncp.papago_limit" />
                  }
                  name="papago_limit"
                >
                  <InputNumber size="small" min={1} />
                </Form.Item>
              </Col>
            </Row>

            {/* NCP */}
            <Divider>
              <FormattedMessage id="settings.project.divider.ncp" />
            </Divider>
            <Row>
              {/* Objectstorage */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  valuePropName="checked"
                  name="objectstorage_enable"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.objectstorage_enable" />
                  }
                >
                  <Switch
                     checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="objectstorage_bucket"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.objectstorage_bucket" />
                  }
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="objectstorage_access_key"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.objectstorage_access_key" />
                  }
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="objectstorage_secret_key"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.objectstorage_secret_key" />
                  }
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              {/* SENS PUSH */}
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  valuePropName="checked"
                  name="sens_push_enable"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.sens_push_enable" />
                  }
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="sens_push_service_id"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.sens_push_service_id" />
                  }
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
             
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="sens_push_limit"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.sens_push_limit" />
                  }
                >
                  <InputNumber size="small" min={1} />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  valuePropName="checked"
                  name="sens_sms_enable"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.sens_sms_enable" />
                  }
                >
                  <Switch
                    checkedChildren={<CheckOutlined />}
                    unCheckedChildren={<CloseOutlined />}
                  />
                </Form.Item>
              </Col>
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="sens_sms_service_id"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.sens_sms_service_id" />
                  }
                >
                  <Input size="small" />
                </Form.Item>
              </Col>
              
              <Col xs={24} sm={24} md={24}>
                <Form.Item
                  name="sens_sms_limit"
                  label={
                    <FormattedMessage id="settings.project.label.ncp.sens_sms_limit" />
                  }
                >
                  <InputNumber size="small"  min={1} />
                </Form.Item>
              </Col>
            </Row>
            
            <Button
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              size="small"
              loading={loading}
              onClick={onFinish}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </Col>
        </Row>
      </Form>
      <Modal
        open={papagoModal}
        title={
          <div>
            <ExclamationCircleOutlined
              className="mr-2"
              style={{ color: '#faad14' }}
            />
            <FormattedMessage id="settings.project.modal.title" />
          </div>
        }
        closable={false}
        footer={[
          <Button
            key="cancel"
            size="small"
            onClick={() => setPapagoModal(false)}
          >
            <FormattedMessage id="common.cancel" />
          </Button>,
          <Button
            key="ok"
            size="small"
            type="primary"
            onClick={() => {
              setPapagoModal(false);
              setPapagoCheck(true);
            }}
          >
            <FormattedMessage id="common.ok2" />
          </Button>,
        ]}
      >
        <FormattedMessage id="settings.project.modal.content1" />
        <br />
        <FormattedMessage id="settings.project.modal.content2" />
        <a
          href={`${guideConfig.papago.guide}?language=${
            localStorage.locale || 'en-US'
          }`}
          target="_blank"
        >
          <FormattedMessage id="settings.project.modal.content3" />
        </a>
        <FormattedMessage id="settings.project.modal.content4" />
      </Modal>
    </div>
  );
};

export default Project;
