import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { Table, Form, Select, Input, Button, DatePicker, Grid } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import moment from 'moment';
import Flex from 'components/shared-components/Flex';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';
import { useParams } from 'react-router-dom';

import { JOBMANAGEMENTS_QUERY } from '../../../graphql/query/jobmanagement';

const { Option } = Select;
const { RangePicker } = DatePicker;

const searchData = [
  {
    name: <FormattedMessage id="jobmanagement.table.column.user_id" />,
    id: 'user_id',
  },
];

const Jobmanagement = ({ match }) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  const { useBreakpoint } = Grid;
  const [exporters, setExporters] = useState([]);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: undefined,
    endDate: undefined,
  });

  const jobmanagementData = useQuery(JOBMANAGEMENTS_QUERY, {
    variables: {
      projectId:params.projectId,
      per_page: pagination.pageSize,
      offset: (pagination.current - 1) * 20,
      search: search.search,
      query: search.query,
      startDate: search.startDate,
      endDate: search.endDate,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (jobmanagementData?.data?.exporters) {
      const datas = jobmanagementData.data.exporters;

      setExporters(datas.edges);
      setPagination({ ...pagination, total: datas.totalCount });
    }
  }, [jobmanagementData]);

  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    if (!values.query || !values.search) {
      values.query = undefined;
      values.search = undefined;
    }

    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
      startDate: values.range
        ? values.range[0].startOf('day').format()
        : undefined,
      endDate: values.range ? values.range[1].endOf('day').format() : undefined,
    });
  };

  const fileCheck = (record) => {
    if (!record?.expired_at || !record?.filelink) {
      return '-';
    }

    let expiredDate = moment(record.expired_at, 'x').format('YYYY-MM-DD');
    let today = moment().format('YYYY-MM-DD');

    if (moment(expiredDate).diff(today, 'days') >= 0) {
      return (
        <span>
          <a href={record.filelink}>
            <FormattedMessage id="jobmanagement.table.filelink.download" />
          </a>
          <span
            style={{
              color: '#888',
              marginLeft: 5,
            }}
          >
            ({moment(expiredDate).diff(moment(today), 'days') + 1}
            <FormattedMessage id="jobmanagement.table.filelink.day" />)
          </span>
        </span>
      );
    }

    return '-';
  };

  const columns = [
    {
      title: <FormattedMessage id="jobmanagement.table.column.status" />,
      dataIndex: ['node', 'status'],
      className: 'td-keepall',
      render: (row) => {
        const ids = {
          0: 'jobmanagement.table.status.ready',
          1: 'jobmanagement.table.status.complete',
          3: 'jobmanagement.table.status.processing',
          11: 'jobmanagement.table.status.error',
        };
        if (ids[row]) {
          return formatMessage({ id: ids[row] });
        }
        return row;
      },
    },
    {
      title: (
        <FormattedMessage id="jobmanagement.table.column.proc_startdate" />
      ),
      key: 'proc_startdate',
      dataIndex: ['node', 'proc_startdate'],
      render: (row) => (
        <div style={{ minWidth: '150px' }}>
          {row ? moment(row, 'x').format('YYYY-MM-DD HH:mm:ss') : '-'}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="jobmanagement.table.column.proc_enddate" />,
      key: 'proc_enddate',
      dataIndex: ['node', 'proc_enddate'],
      render: (row) => (
        <div style={{ minWidth: '150px' }}>
          {row ? moment(row, 'x').format('YYYY-MM-DD HH:mm:ss') : '-'}
        </div>
      ),
    },
    {
      title: <FormattedMessage id="jobmanagement.table.column.type" />,
      key: 'type',
      dataIndex: ['node', 'type'],
      className: 'td-keepall',
      render: (row) => {
        const ids = {
          message_list: 'jobmanagement.table.type.message_list',
          member_list: 'jobmanagement.table.type.member_list',
          channel_list: 'jobmanagement.table.type.channel_list',
        };
        if (ids[row]) {
          return formatMessage({ id: ids[row] });
        }
        return row;
      },
    },
    {
      title: <FormattedMessage id="jobmanagement.table.column.filelink" />,
      key: 'filelink',
      dataIndex: 'node',
      className: 'td-nowrap',
      render: (row) => fileCheck(row),
    },
    {
      title: <FormattedMessage id="jobmanagement.table.column.user_id" />,
      key: 'user_id',
      dataIndex: ['node', 'user_id'],
      className: 'td-break',
    },
  ];

  return (
    <div>
      <Form
        form={form}
        className={`${utils.isTablet(useBreakpoint) ? 'search-mobile' : ''}`}
        layout="inline"
        onFinish={onSearch}
        initialValues={{ search: 'user_id' }}
      >
        <Flex alignItems="center" mobileFlex={false}>
          <div>
            <Form.Item name="range">
              <RangePicker size="small" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="search">
              <Select style={{ minWidth: 150 }} size="small">
                {searchData.map((elm) => (
                  <Option key={elm.id} value={elm.id}>
                    {elm.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="query">
              <Input placeholder="Search" size="small" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="button">
              <Button
                type="primary"
                size="small"
                icon={<SearchOutlined className="mr-2" />}
                block
                htmlType="submit"
              >
                <FormattedMessage id="common.search" />
              </Button>
            </Form.Item>
          </div>
        </Flex>
      </Form>
      <Table
        scroll={{ x: 1000 }}
        style={{ marginTop: 10 }}
        size="small"
        dataSource={exporters}
        columns={columns}
        pagination={pagination}
        rowKey={(record) => record.node.id}
        loading={jobmanagementData.loading}
        onChange={handleTableChange}
        footer={() => {
          return (
            <div>
              <FormattedMessage id="common.total.1" />
              {pagination?.total ? formatNumber(pagination.total) : 0}
              <FormattedMessage id="common.total.2" />
            </div>
          );
        }}
      />
    </div>
  );
};

export default Jobmanagement;
