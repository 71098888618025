import React from 'react';
import { Routes, Route } from 'react-router-dom';
import AuthViews from 'views/auth-views';
import IEModal from 'components/layout-components/IEModal';

export const AuthLayout = () => {
  window.sessionStorage.removeItem('cloudchat-alert');

  return (
    
    <div className="auth-container">
      <Routes>
        <Route exact path="*" element={<AuthViews />}/>
      </Routes>
      {/* <IEModal /> */}
    </div>
  );
};

export default AuthLayout;
