import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Menu, Layout } from 'antd';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import Logo from './Logo';
import NavProject from './NavProject';
import NavProfile from './NavProfile';
import NavLanguage from './NavLanguage';
import NavSearch from './NavSearch';
import { toggleCollapsedNav, onMobileNavToggle } from 'redux/actions/Theme';
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from 'constants/ThemeConstant';
import utils from 'utils';

const { Header } = Layout;

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    isHide,
  } = props;
  const [searchActive, setSearchActive] = useState(false);

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = utils.getColorContrast(headerNavColor);
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return '0px';
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };
  if (isHide) {
    return (
      <Header
        className={`app-header ${mode}`}
        style={{ backgroundColor: headerNavColor }}
      >
        <div
          className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}
        >
          <Logo logoType={mode} />
          <div
            className="nav"
            style={{ width: `calc(100% - ${getNavWidth()})` }}
          >
            <div className="nav-left"></div>
            <div className="nav-right">
              <NavLanguage />
              <NavProfile />
              {/* <NavPanel />  */}
            </div>
            <NavSearch active={searchActive} close={onSearchClose} />
          </div>
        </div>
      </Header>
    );
  } else {
    return (
      <Header
        className={`app-header ${mode}`}
        style={{ backgroundColor: headerNavColor }}
      >
        <div
          className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}
        >
          <Logo logoType={mode} />
          <div
            className="nav"
            style={{ width: `calc(100% - ${getNavWidth()})` }}
          >
            <div className="nav-left">
              <Menu mode="horizontal">
                {isNavTop && !isMobile ? null : (
                  <Menu.Item
                    key="navcol"
                    onClick={() => {
                      onToggle();
                    }}
                  >
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined className="nav-icon" />
                    ) : (
                      // <MenuFoldOutlined className="nav-icon" />
                      <div></div>
                    )}
                  </Menu.Item>
                )}
              </Menu>
              <NavProject />
            </div>
            <div
              className="nav-right justify-content-end"
              style={{ width: 'calc(100% - 70px)' }}
            >
              <NavLanguage />
              <NavProfile />
            </div>
            <NavSearch active={searchActive} close={onSearchClose} />
          </div>
        </div>
      </Header>
    );
  }
};

const mapStateToProps = ({ theme }) => {
  const { navCollapsed, navType, headerNavColor, mobileNav } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
