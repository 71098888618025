import React, { useState, useEffect, useContext } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { useParams } from 'react-router';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  MessageOutlined,
  ControlOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import {
  Row,
  Col,
  DatePicker,
  message,
  Card,
  Button,
  Progress,
  Badge,
  Grid,
  Tooltip,
} from 'antd';
import { consoleConfig } from 'config';
import StatisticWidget from 'components/shared-components/StatisticWidget';
import ChartWidget from 'components/shared-components/ChartWidget';
import CopyText from 'components/shared-components/CopyText';
import utils from 'utils';

import {
  Analytics_QUERY,
  CCU_ANALYTICS_QUERY,
  MESSAGE_ANALYTICS_QUERY,
  USAGES_QUERY,
} from '../../../graphql/query/analytics';
import { APIKEY_QUERY } from '../../../graphql/query/apikeys';
import { PROJECT_QUERY } from '../../../graphql/query/project';

const { RangePicker } = DatePicker;

const oneMonthAgo = moment().subtract(29, 'days').startOf('day').format();
const oneYearAgo = moment().subtract(1, 'years').startOf('day').format();
const today = moment().endOf('day').format();

export const DefaultDashboard = (props) => {
  const { formatMessage, formatNumber } = useIntl();
  const navigate = useNavigate();
  const company_id = localStorage.getItem('company_id');
  const { useBreakpoint } = Grid;
  const layoutArr = utils.getBreakPoint(useBreakpoint());

  // Chart data state
  const [apiKey, setApiKey] = useState({});
  const [usages, setUsages] = useState({});
  const [projectUsage, setProjectUsage] = useState({
    usePapago: true,
    useSens: true,
  });
  const [mauChartData, setMauChartData] = useState();
  const [dauChartData, setDauChartData] = useState();
  const [ccuChartData, setCcuChartData] = useState();
  const [messageChartData, setMessageChartData] = useState();
  const [visitorChartData, setVisitorChartData] = useState();

  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(today);
  const params = useParams();

  //useQuery
  const projectData = useQuery(PROJECT_QUERY, {
    variables: {  ncpId: company_id, id: params.projectId },
    fetchPolicy: 'network-only',
  });
  const apikeyData = useQuery(APIKEY_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({ project_id: params.projectId }),
    },
    fetchPolicy: 'network-only',
  });
  const usagesData = useQuery(USAGES_QUERY, {
    variables: {
      projectId: params.projectId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const MauAnalyticsData = useQuery(Analytics_QUERY, {
    variables: {
      projectId: params.projectId,
      type: 'activeuser_monthly',
      startDate: oneYearAgo,
      endDate: today,
    },
    fetchPolicy: 'network-only',
  });
  const DauAnalyticsData = useQuery(Analytics_QUERY, {
    variables: {
      projectId: params.projectId,
      type: 'activeuser_daily',
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const CcuAnalyticsData = useQuery(CCU_ANALYTICS_QUERY, {
    variables: {
      projectId: params.projectId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const MessageAnalyticsData = useQuery(MESSAGE_ANALYTICS_QUERY, {
    variables: {
      projectId: params.projectId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });

  // Set Project
  useEffect(() => {
    if (projectData?.data?.project) {
      const { project } = projectData?.data;

      const usePapago = project?.ncp?.papago_enable === true;
      const useSens =
        (project?.ncp?.sens_push_enable === true &&
          utils.isEmpty(project?.ncp?.sens_push_service_id) === false &&
          utils.isEmpty(project?.ncp?.objectstorage_access_key) === false &&
          utils.isEmpty(project?.ncp?.objectstorage_secret_key) === false) ===
        true;

      setProjectUsage({
        usePapago,
        useSens,
      });
    }
  }, [projectData]);


  // Set Project Usage
  useEffect(() => {
    if (usagesData?.data?.usages) {
      setUsages(usagesData?.data?.usages);
    }
  }, [usagesData]);

  // Set MAU
  useEffect(() => {
    if (MauAnalyticsData?.loading === true) {
      setMauChartData({
        loading: true,
      });
      return;
    }

    const MAUDatas = MauAnalyticsData?.data?.analytics?.edges;
    let dataArray = [];
    let allSum = 0;
    let categories = [];
    let todayCount = 0;
    let updatedAt;

    if (MAUDatas) {
      MAUDatas.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);

        allSum += item.node.count;

        if (item.node.date === moment().format('YYYY-MM')) {
          todayCount = item.node.count;
        }
      });

      if (MAUDatas[MAUDatas.length - 1]?.node?.updated_at) {
        updatedAt = moment(
          MAUDatas[MAUDatas.length - 1].node.updated_at
        ).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    let mau = {
      name: formatMessage({ id: 'dashboards.chart.title.mau' }),
      data: dataArray.sort(),
    };

    setMauChartData({
      loading: false,
      series: [mau],
      categories,
      avg: Math.round(allSum / dataArray.length),
      todayCount,
      updatedAt,
    });
  }, [MauAnalyticsData]);

  // Set Active Messages
  useEffect(() => {
    if (MessageAnalyticsData?.loading === true) {
      setMessageChartData({ loading: true });
      return;
    }

    const MessageDatas = MessageAnalyticsData?.data?.messageAnalytics?.edges;

    let dataArray = [];
    let categories = [];

    if (MessageDatas) {
      MessageDatas.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let message = {
      name: formatMessage({ id: 'dashboards.chart.title.messages' }),
      data: dataArray.sort(),
    };

    setMessageChartData({
      series: [message],
      categories,
      loading: false,
      updatedAt: <FormattedMessage id="dashboards.chart.realtime" />,
    });
  }, [MessageAnalyticsData]);

  // Set DAU&CCU
  useEffect(() => {
    if (
      DauAnalyticsData?.loading === true ||
      CcuAnalyticsData?.loading === true
    ) {
      if (!dauChartData)
        setDauChartData({
          loading: true,
        });
      if (!ccuChartData)
        setCcuChartData({
          loading: true,
        });

      setVisitorChartData({
        loading: true,
      });
      return;
    }

    const DAUdatas = DauAnalyticsData?.data?.analytics?.edges;
    const CCUdatas = CcuAnalyticsData?.data?.ccuAnalytics?.edges;

    let dataArray_dau = [];
    let dataArray_ccu = [];
    let allSum_dau = 0;
    let allSum_ccu = 0;
    let todayCount_dau = 0;
    let todayCount_ccu = 0;
    let updatedAt_dau;
    let categories = [];

    if (DAUdatas) {
      DAUdatas.map((item) => {
        dataArray_dau.push([item.node.date, item.node.count]);

        allSum_dau += item.node.count;

        if (categories.includes(item.node.date) === false) {
          categories.push(item.node.date);
        }

        if (item.node.date === moment().format('YYYY-MM-DD')) {
          todayCount_dau = item.node.count;
        }
      });

      if (DAUdatas[DAUdatas.length - 1]?.node?.updated_at) {
        updatedAt_dau = moment(
          DAUdatas[DAUdatas.length - 1].node.updated_at
        ).format('YYYY-MM-DD HH:mm:ss');
      }
    }

    if (CCUdatas) {
      CCUdatas.map((item) => {
        dataArray_ccu.push([item.node.date, item.node.count]);

        allSum_ccu += item.node.count;

        if (categories.includes(item.node.date) === false) {
          categories.push(item.node.date);
        }

        if (item.node.date === moment().format('YYYY-MM-DD')) {
          todayCount_ccu = item.node.count;
        }
      });
    }

    categories.sort();

    let dau = {
      name: formatMessage({ id: 'dashboards.chart.title.dau' }),
      data: dataArray_dau.sort(),
    };
    let ccu = {
      name: formatMessage({ id: 'dashboards.chart.title.ccu' }),
      data: dataArray_ccu.sort(),
    };

    if (
      DauAnalyticsData.variables.endDate === today &&
      DauAnalyticsData.variables.startDate === oneMonthAgo
    ) {
      setDauChartData({
        series: [dau],
        avg: Math.round(allSum_dau / dataArray_dau.length),
        todayCount: todayCount_dau,
        loading: false,
        updatedAt: updatedAt_dau,
      });
      setCcuChartData({
        series: [ccu],
        avg: Math.round(allSum_ccu / dataArray_ccu.length),
        todayCount: todayCount_ccu,
        loading: false,
        updatedAt: <FormattedMessage id="dashboards.chart.realtime" />,
      });
    }

    setVisitorChartData({
      series: [dau, ccu],
      categories,
      loading: false,
      updatedAt: <FormattedMessage id="dashboards.chart.realtime" />,
    });
  }, [DauAnalyticsData, CcuAnalyticsData]);

  // functions
  const getGridStyle = () => {
    if (layoutArr.length === 3) {
      return { width: '50%' };
    }
    if (layoutArr.length === 2 || layoutArr.length === 1) {
      return { width: '100%' };
    }

    return { width: '25%' };
  };

  const getProjectUsagePercent = (count, max) => {
    return (count / max) * 100;
  };

  const onChange = (date, dateString) => {
    if (!date) {
      return;
    }

      setStartDate(date[0].startOf('day').format());
      setEndDate(date[1].endOf('day').format());
  };

  const statisticData = [
    {
      type: 'mau',
      title: <FormattedMessage id="dashboards.statistic.mau.title" />,
      subtitle: formatMessage({ id: 'dashboards.statistic.mau.subtitle' }),
      decs: <FormattedMessage id="dashboards.statistic.mau.decs" />,
    },
    {
      type: 'dau',
      title: <FormattedMessage id="dashboards.statistic.dau.title" />,
      subtitle: formatMessage({ id: 'dashboards.statistic.dau.subtitle' }),
      decs: <FormattedMessage id="dashboards.statistic.dau.decs" />,
    },
    {
      type: 'ccu',
      title: <FormattedMessage id="dashboards.statistic.ccu.title" />,
      subtitle: formatMessage({ id: 'dashboards.statistic.ccu.subtitle' }),
      decs: <FormattedMessage id="dashboards.statistic.ccu.decs" />,
    },
  ];

  return (
    <>
      <Row gutter={16}>
        <Col span={24}>
          {/* Project Info */}
          <Row gutter={16}>
            <Col span={24}>
              <Card
                title={
                  <>
                    <MessageOutlined className="mr-2" />
                    {projectData?.data?.project?.name ?? company_id}
                  </>
                }
                extra={
                  <Button size="small" onClick={() => navigate(`/${company_id}/${utils.getID(projectData?.data?.project?.id)}/settings`)}>
                    <FormattedMessage id="sidenav.settings" />
                  </Button>
                }
              >
                <Row gutter={16}>
                  <Col xs={24} sm={4} md={4} lg={4} xl={4} xxl={2}>
                    <FormattedMessage id="settings.project.label.project_id" />
                  </Col>
                  <Col xs={24} sm={20} md={20} lg={20} xl={20} xxl={22}>
                    <span className="text-break">
                      {params.projectId || '-'}
                    </span>
                    <CopyText text={params.projectId || '-'} />
                  </Col>
                </Row>
               
              </Card>
            </Col>
          </Row>

          {/* 프로젝트 사용량 */}
          <Row gutter={16}>
            <Col span={24}>
              <Card
                headStyle={{ paddingBottom: '1rem' }}
                title={
                  <span>
                    <ControlOutlined className="mr-2" />
                    <FormattedMessage id="dashboards.usage" />
                  </span>
                }
                extra={
                  <>
                    <span className="text-muted mr-2">
                      {utils.isTablet(useBreakpoint()) === true ? (
                        <Tooltip
                          title={
                            <FormattedMessage id="dashboards.usage.text" />
                          }
                        >
                          <QuestionCircleOutlined />
                        </Tooltip>
                      ) : (
                        <FormattedMessage id="dashboards.usage.text" />
                      )}
                    </span>
                    <Button
                      size="small"
                      onClick={() => navigate(`/${company_id}/${utils.getID(projectData?.data?.project?.id)}/settings`)}
                    >
                      <FormattedMessage id="sidenav.settings" />
                    </Button>
                  </>
                }
              >
                {/* Chat */}
                <Card.Grid style={getGridStyle()} hoverable={false}>
                  <div className="font-size-lg mb-4">Chat</div>
                  <div>
                    <span>
                      <FormattedMessage id="dashboards.usage.chat" />
                    </span>
                    <span className="float-right">
                      <Badge
                        style={{ backgroundColor: '#52c41a' }}
                        count={formatNumber(usages?.mau_use || 0)}
                        overflowCount={usages?.mau_max || 0}
                      />
                    </span>
                  </div>
                  <Progress
                    className="mb-3"
                    percent={getProjectUsagePercent(
                      usages?.mau_use || 0,
                      usages?.mau_max || 0
                    )}
                    showInfo={false}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />

                  <div>
                    <span className="font-size-xxl">
                      {formatNumber(usages?.mau_use || 0)}
                    </span>
                    <span className="float-right font-size-xxl">MAU</span>
                  </div>
                </Card.Grid>

                {/* SENS */}
                <Card.Grid style={getGridStyle()} hoverable={false}>
                  <div className="font-size-lg mb-4">SENS</div>
                  <div>
                    <span>
                      <FormattedMessage id="dashboards.usage.sens" />
                    </span>
                    <span className="float-right">
                      <Badge
                        style={{ backgroundColor: '#52c41a' }}
                        count={
                          projectUsage.useSens
                            ? formatNumber(usages?.sens_use || 0)
                            : 0
                        }
                        overflowCount={usages?.sens_max || 0}
                      />
                    </span>
                  </div>
                  <Progress
                    className="mb-3"
                    percent={
                      projectUsage.useSens
                        ? getProjectUsagePercent(
                            usages?.sens_use || 0,
                            usages?.sens_max || 0
                          )
                        : 0
                    }
                    showInfo={false}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                  {projectUsage.useSens === true && usages?.sens_max > 0 ? (
                    <div>
                      <span className="font-size-xxl">
                        {formatNumber(usages?.sens_use || 0)}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <a href={consoleConfig.sens.ko} target="_blank">
                        <Button style={{ marginTop: 5 }} className="w-100">
                          <FormattedMessage id="dashboards.usage.apply" />
                        </Button>
                      </a>
                    </div>
                  )}
                </Card.Grid>

                {/* PAPAGO */}
                <Card.Grid style={getGridStyle()} hoverable={false}>
                  <div className="font-size-lg mb-4">PAPAGO</div>
                  <div>
                    <span>
                      <FormattedMessage id="dashboards.usage.papago" />
                    </span>
                    <span className="float-right">
                      <Badge
                        style={{ backgroundColor: '#52c41a' }}
                        count={
                          projectUsage.usePapago
                            ? formatNumber(usages?.papago_use || 0)
                            : 0
                        }
                        overflowCount={usages?.papago_max || 0}
                      />
                    </span>
                  </div>
                  <Progress
                    className="mb-3"
                    percent={
                      projectUsage.usePapago
                        ? getProjectUsagePercent(
                            usages?.papago_use || 0,
                            usages?.papago_max || 0
                          )
                        : 0
                    }
                    showInfo={false}
                    strokeColor={{
                      '0%': '#108ee9',
                      '100%': '#87d068',
                    }}
                  />
                  {projectUsage.usePapago === true && usages?.papago_max > 0 ? (
                    <div>
                      <span className="font-size-xxl">
                        {formatNumber(usages?.papago_use || 0)}
                      </span>
                    </div>
                  ) : (
                    <div>
                      <a href={consoleConfig.papago.ko} target="_blank">
                        <Button style={{ marginTop: 5 }} className="w-100">
                          <FormattedMessage id="dashboards.usage.apply" />
                        </Button>
                      </a>
                    </div>
                  )}
                </Card.Grid>

                {/* Integration */}
                <Card.Grid style={getGridStyle()} hoverable={false}>
                  <div className="font-size-lg mb-4">Integration</div>
                  <div style={{ height: 58 }}></div>

                  <div>
                    <Button style={{ marginTop: 5 }} className="w-100" disabled>
                      <FormattedMessage id="common.preparation" />
                    </Button>
                  </div>
                </Card.Grid>
              </Card>
            </Col>
          </Row>

          <Row gutter={16}>
            {statisticData.map((elm, i) => (
              <Col xs={24} sm={24} md={24} lg={24} xl={8} key={i}>
                <StatisticWidget
                  type={elm.type}
                  title={elm.title}
                  subtitle={elm.subtitle}
                  decs={elm.decs}
                  mauChartData={mauChartData}
                  dauChartData={dauChartData}
                  ccuChartData={ccuChartData}
                />
              </Col>
            ))}
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <div style={{ marginBottom: '5px', float: 'right' }}>
                <RangePicker
                  allowClear={false}
                  onChange={onChange}
                  value={[moment(startDate), moment(endDate)]}
                  size="small"
                />
              </div>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              {visitorChartData ? (
                <ChartWidget
                  title={
                    <FormattedMessage id="dashboards.chart.title.visitors" />
                  }
                  extra={visitorChartData.updatedAt}
                  series={visitorChartData.series}
                  categories={visitorChartData.categories}
                  loading={visitorChartData.loading}
                  csv={false}
                  height={400}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              {messageChartData ? (
                <ChartWidget
                  title={
                    <FormattedMessage id="dashboards.chart.title.messages" />
                  }
                  extra={messageChartData.updatedAt}
                  series={messageChartData.series}
                  categories={messageChartData.categories}
                  loading={messageChartData.loading}
                  height={400}
                />
              ) : (
                ''
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default DefaultDashboard;
