import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Form,
  Input,
  Alert,
  message as antdMessage,
  Select,
} from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  showAuthMessage,
  showLoading,
  hideAuthMessage,
  authenticated,
  createFromMail,
  resetPassword,
  changeAccount,
  signOut,
} from 'redux/actions/Auth';
import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import Utils from 'utils';
import momentTZ from 'moment-timezone';
import { useParams } from 'react-router';

export const AccountForm = (props) => {
  const { formatMessage } = useIntl();
  const {
    showLoading,
    loading,
    message,
    showMessage,
    projectName,
    tempkey,
    companyId,
    switchKey,
    createFromMail,
    resetPassword,
    changeAccount,
  } = props;

  const [form] = Form.useForm();
  
  const params = useParams();

  let navigate = useNavigate();
  const rules = {
    password: [
      {
        required: true,
        message: <FormattedMessage id="verify.account.rules.password" />,
      },
      {
        pattern: Utils.regPW,
        message: <FormattedMessage id="common.error.password" />,
      },
    ],
    confirm: [
      {
        required: true,
        message: (
          <FormattedMessage id="verify.account.rules.password.confirm" />
        ),
      },
      ({ getFieldValue }) => ({
        validator(rule, value) {
          if (!value || getFieldValue('password') === value) {
            return Promise.resolve();
          }
          return Promise.reject(
            <FormattedMessage id="verify.account.rules.password.error" />
          );
        },
      }),
    ],
    timezone: [
      {
        required: true,
        message: <FormattedMessage id="common.timezone.placeholder" />,
      },
    ],
  };


  const onSubmit = (values) => {
    showLoading();
    if(!tempkey) {
      alert('key is empty');
      return false;
    }
    if(!companyId) {
      if(!companyId) {
        alert('companyId is empty');
        return false;
      }
    }
    switch (switchKey) {
      case 'createFromMail':
        createFromMail({
          ...values,
          name: 'Admin',
          companyId,
          tempkey,
          projectName,
          navigate,
        });
        break;
      case 'resetPassword':
        resetPassword({
          password: values.password,
          tempkey,
          ncp_project_id: companyId,
          navigate,
        });
        break;
      case 'changeAccount':
        changeAccount({
          ncp_project_id: companyId,
          password: values.password,
          tempkey,
          navigate,
        });
        break;

      default:
        break;
    }
    return false;
  };


  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}>
        <Alert type="error" showIcon message={message}></Alert>
      </motion.div>
      
      <Form
        form={form}
        layout="vertical"
        name="register-form"
        onFinish={onSubmit}>
         
        <Form.Item
          name="password"
          label={<FormattedMessage id="verify.account.form.label.password" />}
          rules={rules.password}
          hasFeedback>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="confirm"
          label={<FormattedMessage id="verify.account.form.label.confirm" />}
          rules={rules.confirm}
          hasFeedback>
          <Input.Password />
        </Form.Item>
        <Form.Item
          name="timezone"
          label={<FormattedMessage id="common.timezone" />}
          rules={rules.timezone}
          initialValue={momentTZ.tz.guess()}
          hasFeedback>
          <Select
            placeholder={<FormattedMessage id="common.timezone.placeholder" />}
            showSearch>
            {Utils.getTimezoneList().map((item) => (
              <Select.Option key={item.country} value={item.country}>
                {item.desc}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <br />
        <Form.Item>
          <Button type="primary" htmlType="submit" block loading={loading}>
            <FormattedMessage id="verify.account.form.submit" />
          </Button>
        </Form.Item>
      </Form>
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loading, message, showMessage, token, redirect } = auth;
  return { loading, message, showMessage, token, redirect };
};

const mapDispatchToProps = {
  showAuthMessage,
  hideAuthMessage,
  showLoading,
  authenticated,
  createFromMail,
  resetPassword,
  changeAccount,
  signOut,
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);
