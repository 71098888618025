import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
  Table,
  Tooltip,
  message,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Row,
  Col,
  Grid,
  Popconfirm,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  PaperClipOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import CopyText from 'components/shared-components/CopyText';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import MessageView from './MessageView';
import utils from 'utils';

import { MESSAGES_QUERY } from '../../../graphql/query/messages';
import { DELETE_MESSAGE_MUTATION, DELETE_MESSAGES_MUTATION } from '../../../graphql/mutations/messages';
import { CREATE_LOG_EXPORTER } from '../../../graphql/mutations/exporter';

const { Option } = Select;
const { RangePicker } = DatePicker;

const oneMonthAgo = moment().subtract(29, 'days').startOf('day').format();
const today = moment().endOf('day').format();

const searchData = [
  { name: <FormattedMessage id="search.columns.sender.id" />, id: 'sender.id' },
  {
    name: <FormattedMessage id="search.columns.sender.name" />,
    id: 'sender.name',
  },
  {
    name: <FormattedMessage id="search.columns.content" />,
    id: 'content',
  },
  {
    name: <FormattedMessage id="search.columns.channel_id" />,
    id: 'channel_id',
  },
];

const Search = ({ match, current_profile }) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  const { useBreakpoint }  = Grid;
  // States
  const [users, setUsers] = useState();
  const [userProfileVisible, setUserProfileVisible] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: oneMonthAgo,
    endDate: today,
  });
  const [sort, setSort] = useState({
    sort_id: undefined,
    sort: undefined,
  });

  // Queries
  const getMessages = useQuery(MESSAGES_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({
        project_id: params.projectId,
        [search.search]: search.query,
        startDate: search.startDate,
        endDate: search.endDate,
      }),
      sort: JSON.stringify({
        [sort.sort_id || 'created_at']: sort.sort === 'asc' ? 1 : -1,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
    },
    fetchPolicy: 'network-only',
  });

  const [createExporter] = useMutation(CREATE_LOG_EXPORTER, {
    onCompleted(result) {
      if (result?.createExporter?.exporter?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.success',
          }),
          key: 'createExporter',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.fail',
          }),
          key: 'createExporter',
        });
      }
    },
  });

  const [deleteMessage] = useMutation(DELETE_MESSAGE_MUTATION, {
    onCompleted(result) {
      //  삭제된 데이터가 DB에 적용되는데 시간이 걸려 setTimeout 처리함
      setTimeout(() => {
        getMessages.refetch();

        if (result?.deleteMessage?.message) {
          message.success({
            content: formatMessage({
              id: 'common.message.delete.success',
            }),
            key: 'deleteMessage',
          });
        } else {
          message.error({
            content: formatMessage({
              id: 'common.message.delete.fail',
            }),
            key: 'deleteMessage',
          });
        }
      }, 1000);
    },
  });

  const [deleteMessages] = useMutation(DELETE_MESSAGES_MUTATION, {
    onCompleted(result) {
      //  삭제된 데이터가 DB에 적용되는데 시간이 걸려 setTimeout 처리함
      setTimeout(() => {
        getMessages.refetch();

        if (result?.deleteMessages) {
          message.success({
            content: formatMessage({
              id: 'common.message.delete.success',
            }),
            key: 'deleteMessage',
          });
        } else {
          message.error({
            content: formatMessage({
              id: 'common.message.delete.fail',
            }),
            key: 'deleteMessage',
          });
        }
      }, 1000);
    },
  });

  useEffect(() => {
    if (getMessages?.data?.messages) {
      let messages = getMessages.data.messages;      
      setUsers(messages.edges);
      setPagination({ ...pagination, total: messages.totalCount });
    }
  }, [getMessages]);

  const handleDeleteMessage = (messageId) => {
    message.loading({
      content: formatMessage({
        id: 'common.message.deleting',
      }),
      key: 'deleteMessage',
      duration: 0,
    });

    const variables = {
      projectId: params.projectId,
      id: messageId,
    };

    deleteMessage({ variables });
  };
  
  // Functions
  const handleDeleteMessages = () => {
    
    message.loading({
      content: formatMessage({
        id: 'common.message.deleting',
      }),
      key: 'deleteMessage',
      duration: 0,
    });

    const variables = {
      projectId: params.projectId,
      ids: selectedRowKeys,
    };

    deleteMessages({ variables });
  }

  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSort({
        sort_id: sorter.columnKey,
        sort: sorter.order === 'ascend' ? 'asc' : 'desc',
      });
    } else {
      setSort({
        sort_id: undefined,
        sort: undefined,
      });
    }

    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    if (
      values?.search === 'content' &&
      values?.query &&
      values?.query?.length < 3
    ) {
      message.error({
        content: formatMessage({
          id: 'search.range.message.error2',
        }),
      });
      return;
    }
    
    let startDate = undefined;
    let endDate = undefined;
    if(values.range !== undefined && values.range)
    {
      startDate = values?.range[0];
      endDate = values?.range[1];
    }
    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
      startDate,
      endDate,
    });
  };

  const showUserProfile = (userInfo) => {
    setUserProfileVisible(true);
    setSelectedUser(userInfo);
  };

  const closeUserProfile = () => {
    setUserProfileVisible(false);
    setSelectedUser(null);
  };

  const requestExport = (variables, startDate, endDate) => {
    message.loading({
      content: formatMessage({
        id: 'common.message.loading',
      }),
      key: 'createExporter',
      duration: 0,
    });

    const data = {
      type: 'message_list',
      projectId: params.projectId,
      user_id: current_profile?.local?.email,
      startDate,
      endDate,
    };

    const value1 = {
      project_id: params.projectId,
    };

    if (variables.search && variables.query) {
      value1[variables.search] = variables.query;
    }

    data.value1 = JSON.stringify(value1);

    createExporter({
      variables: data,
    });
  };

  const getExport = () => {
    const totalCount = getMessages?.data?.messages?.totalCount;
    let titleText = 'common.message.export.tooltip1';

    let startDate = '';
    let endDate = '';

    try {
      startDate = JSON.parse(getMessages?.variables?.filter).startDate;
      endDate = JSON.parse(getMessages?.variables?.filter).endDate;
    } catch (error) {}

    if (totalCount < 1) {
      titleText = 'common.message.export.tooltip2';
    }
    if (totalCount > 10000000) {
      titleText = 'common.message.export.tooltip3';
    }

    if (
      getMessages?.loading === false &&
      totalCount > 0 &&
      totalCount < 10000001
    ) {
      return (
        <Popconfirm
          title={<FormattedMessage id="common.message.export.confirm" />}
          onConfirm={() =>
            requestExport(getMessages?.variables, startDate, endDate)
          }
          okText={<FormattedMessage id="common.ok" />}
          cancelText={<FormattedMessage id="common.no" />}
        >
          <Button
            size="small"
            type="primary"
            icon={<FileExcelOutlined className="mr-2" />}
            block
          >
            <FormattedMessage id="common.export" />
          </Button>
        </Popconfirm>
      );
    } else {
      return (
        <Tooltip title={<FormattedMessage id={titleText} />}>
          <Button
            size="small"
            type="primary"
            icon={<FileExcelOutlined className="mr-2" />}
            block
            disabled={true}
          >
            <FormattedMessage id="common.export" />
          </Button>
        </Tooltip>
      );
    }
  };

  const tableColumns = [
    {
      title: <FormattedMessage id="search.columns.sender" />,
      dataIndex: 'node',
      key: 'sender.id',
      sorter: true,
      render: (row) => (
        <div className="d-flex" style={{ maxWidth: '500px' }}>
          <AvatarStatus
            src={row?.sender?.profile}
            name={row?.sender?.name}
            subTitle={row?.sender?.senderid}
            icon={<UserOutlined />}
            copyTitle
            copySubtitle
          />
        </div>
      ),
    },
   
    
    {
      title: <FormattedMessage id="search.columns.channel_id" />,
      key: 'channel_id',
      dataIndex: ['node', 'channel_id'],
      className: 'td-break',
      sorter: true,
      render: (row) => (
        <div style={{ minWidth: '100px' }}>
          {row}
          <CopyText text={row} />
        </div>
      ),
    },

    {
      title: <FormattedMessage id="search.columns.content" />,
      dataIndex: ['node'],
      key: 'content',
      className: 'td-break',
      sorter: true,
      render: (row) => {
        return row?.attachment_filenames?.name ? (
          <div>
          <LinesEllipsis
            style={{ maxWidth: '700px' }}
            text={utils.getMessage(row.attachment_filenames.name)}
            maxLine="3"
            basedOn="letters"
          />
          </div>
        ) : (
          <div>
          <LinesEllipsis
            style={{ maxWidth: '700px' }}
            text={utils.getMessage(row.content)}
            maxLine="3"
            basedOn="letters"
          />
          </div>
        );
      },
    },
    {
      title: <FormattedMessage id="search.columns.created_at" />,
      key: undefined,
      dataIndex: ['node', 'created_at'],
      sorter: true,
      render: (row) => (
        <span>{moment(row).format('YYYY-MM-DD HH:mm:ss')} </span>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 90,
      render: (_, elm) => (
        <div className="text-right">
          <Tooltip title={<FormattedMessage id="search.tooltip.view" />}>
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showUserProfile(elm.node);
              }}
              size="small"
            />
          </Tooltip>
          <Tooltip title={<FormattedMessage id="search.tooltip.delete" />}>
            <Popconfirm
              placement="bottomRight"
              title={<FormattedMessage id="common.message.delete.confirm" />}
              onConfirm={() => handleDeleteMessage(elm?.node?.message_id)}
              okText={<FormattedMessage id="common.ok" />}
              cancelText={<FormattedMessage id="common.no" />}
            >
              <Button danger icon={<DeleteOutlined />} size="small" />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const selectedRowsArray = selectedRows.map((item) => item.node);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRowsArray);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.node?.deleted === true,
    }),
  };

  return (
    <div>
      <Form
        form={form}
        className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile' : ''}`}
        layout="inline"
        onFinish={onSearch}
        initialValues={{
          search: 'sender.id',
          period: [moment(search.startDate), moment(search.endDate)],
        }}
      >
        <Row
          gutter={10}
          justify="space-between"
          className={
            utils.isTablet(useBreakpoint()) === true ? 'searchRow-mobile' : 'searchRow'
          }
        >
          {/* Search */}
          <Col>
            <Row gutter={10}>
              <Col>
                <Form.Item name="range">
                  <RangePicker size="small" name="period" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="search">
                  <Select style={{ minWidth: 150 }} size="small">
                    {searchData.map((elm) => (
                      <Option key={elm.id} value={elm.id}>
                        {elm.name}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col>
                <Form.Item name="query">
                  <Input size="small" placeholder="Search" />
                </Form.Item>
              </Col>
              <Col>
                <Form.Item>
                  <Button
                    type="primary"
                    size="small"
                    icon={<SearchOutlined className="mr-2" />}
                    block
                    htmlType="submit"
                  >
                    <FormattedMessage id="common.search" />
                  </Button>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          {/* button */}
          <Col>
            <Row gutter={10}>
              <Col>
                <Form.Item>{getExport()}</Form.Item>
              </Col>
              <Col>
                <Form.Item>
                 
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>
      {utils.isEmpty(selectedRowKeys) === false && (
        <Row>
          <Col>
          {formatMessage({
              id: 'common.list.selected',
            })} {selectedRowKeys.length} {formatMessage({
              id: 'common.list.selected.items',
            })}
          <Popconfirm
            title={formatMessage({
              id: 'common.message.delete.confirm',
            })}
            placement="left"
            onConfirm={() => handleDeleteMessages()}
          >
            <Button type="primary" danger size="small" icon={<DeleteOutlined />} style={{ marginLeft: 8 }}>
              Remove
            </Button>
          </Popconfirm>
          </Col>
          </Row>
        )}
      <div className="table-responsive">
        <Table
          scroll={{ x: 1000 }}
          style={{ marginTop: 10 }}
          loading={getMessages?.loading}
          pagination={pagination}
          onChange={handleTableChange}
          columns={tableColumns}
          dataSource={users}
          rowKey={(record) => record.node.message_id}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          size="small"
          footer={() => {
            return (
              <div>
                <FormattedMessage id="common.total.1" />
                {pagination?.total ? formatNumber(pagination.total) : 0}
                <FormattedMessage id="common.total.2" />
              </div>
            );
          }}
        />
        <MessageView
          data={selectedUser}
          visible={userProfileVisible}
          close={() => {
            closeUserProfile();
          }}
        />
      </div>
    </div>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(Search);
