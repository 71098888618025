import { all, takeEvery, put, fork, call } from 'redux-saga/effects';
import {
  AUTH_TOKEN,
  COMPANY_ID,
  LOCALE,
  SAVE_EMAIL,
  SIGNIN,
  SIGNOUT,
  CREATE_FROM_MAIL,
  CHANGE_ACCOUNT,
  RESET_PASSWORD,
  SIGNUP,
  SIGNIN_WITH_GOOGLE,
  SIGNIN_WITH_FACEBOOK,
} from '../constants/Auth';
import {
  showAuthMessage,
  authenticated,
  signOutSuccess,
  signUpSuccess,
  signInWithGoogleAuthenticated,
  signInWithFacebookAuthenticated,
} from '../actions/Auth';

import JwtAuthService from 'services/JwtAuthService';
import Utils from '../../utils';

export function* signInWithFBEmail() {
  yield takeEvery(SIGNIN, function* ({ payload }) {
    const { email, password, company_id, save_email } = payload;
    try {
      const user = yield call(
        JwtAuthService.signIn,
        email,
        password,
        company_id
      );
      if (!user.status) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.token);
        localStorage.setItem(COMPANY_ID, user.ncpid);
        localStorage.setItem(LOCALE, Utils.getCurrentLocale());
        if (save_email) {
          if (save_email === '{}') {
            localStorage.removeItem(SAVE_EMAIL);
          } else {
            localStorage.setItem(SAVE_EMAIL, save_email);
          }
        }
        yield put(authenticated(user.token, user.ncpid, user.project_id));
      }
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* ({ redirect }) {
    try {
      localStorage.removeItem(AUTH_TOKEN);
      yield call(JwtAuthService.signOut);

      yield put(signOutSuccess(redirect));
    } catch (err) {
      yield put(showAuthMessage(err));
    }
  });
}

export function* createFromMail() {
  yield takeEvery(CREATE_FROM_MAIL, function* ({ payload }) {
    const { projectName, password, confirm, name, timezone, tempkey } =
      payload;
    try {
      const user = yield call(
        JwtAuthService.signUp,
        projectName,
        password,
        confirm,
        name,
        timezone,
        tempkey
      );
      if (!user.status) {
        yield put(showAuthMessage(user.message));
      } else {
        yield put(signUpSuccess(user.user.ncp_project_id));
        payload.navigate(
          `/${user.user.ncp_project_id}`
        );
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* resetPassword() {
  yield takeEvery(RESET_PASSWORD, function* ({ payload }) {
    const { ncp_project_id, tempkey, password } = payload;
    try {
      const user = yield call(
        JwtAuthService.resetPassword,
        ncp_project_id,
        tempkey,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        yield put(signUpSuccess(user.user.ncp_project_id));
        payload.navigate(
          `/${user.user.ncp_project_id}`
        );
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* changeAccount() {
  yield takeEvery(CHANGE_ACCOUNT, function* ({ payload }) {
    const { ncp_project_id, tempkey, password } = payload;
    try {
      const user = yield call(
        JwtAuthService.changeAccount,
        ncp_project_id,
        tempkey,
        password
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        yield put(signUpSuccess(user.user.ncp_project_id));
        payload.navigate(
          `/${user.user.ncp_project_id}`
        );
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signUpWithFBEmail() {
  yield takeEvery(SIGNUP, function* ({ payload }) {
    const { email, password, ncpid } = payload;
    try {
      const user = yield call(
        JwtAuthService.signUpEmail,
        email,
        password,
        ncpid
      );
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signUpSuccess(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFBGoogle() {
  yield takeEvery(SIGNIN_WITH_GOOGLE, function* () {
    try {
      const user = yield call(JwtAuthService.signInGoogleRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithGoogleAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export function* signInWithFacebook() {
  yield takeEvery(SIGNIN_WITH_FACEBOOK, function* () {
    try {
      const user = yield call(JwtAuthService.signInFacebookRequest);
      if (user.message) {
        yield put(showAuthMessage(user.message));
      } else {
        localStorage.setItem(AUTH_TOKEN, user.user.uid);
        yield put(signInWithFacebookAuthenticated(user.user.uid));
      }
    } catch (error) {
      yield put(showAuthMessage(error));
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signInWithFBEmail),
    fork(signOut),
    fork(createFromMail),
    fork(resetPassword),
    fork(changeAccount),
    fork(signUpWithFBEmail),
    fork(signInWithFBGoogle),
    fork(signInWithFacebook),
  ]);
}
