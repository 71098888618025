import React, { Suspense } from 'react';
import { Routes, Navigate, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';

import User from '../../app-views/analytics/user';

import Ccu from '../../app-views/analytics/ccu';

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        <Route exact path='/user/*' element={<User />} />
        <Route exact path='/ccu/*'  element={<Ccu />} />  
        <Route exact path='*' element={<Navigate to="/notfound" />} />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
