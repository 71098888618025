import React from 'react';
import { Card, Tooltip, Skeleton } from 'antd';
import PropTypes from 'prop-types';
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons';
import Flex from 'components/shared-components/Flex';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useIntl } from 'react-intl';

const StatisticWidget = (props) => {
  const {
    type,
    title,
    value,
    status,
    subtitle,
    decs,
    prefix,
    dauChartData,
    mauChartData,
    ccuChartData,
  } = props;

  const intl = useIntl();

  const getSeries = () => {
    switch (type) {
      case 'mau':
        return mauChartData;
        break;
      case 'dau':
        return dauChartData;
        break;
      case 'ccu':
        return ccuChartData;
        break;
      default:
        return undefined;
        break;
    }
  };

  const chartOptions = {
    credits: false,
    title: {
      text: undefined,
    },
    chart: {
      height: 80,
      type: 'area',
    },
    legend: {
      enabled: false,
    },
    yAxis: {
      visible: false,
      min: 0,
    },
    xAxis: {
      visible: false,
    },
    plotOptions: {
      series: {
        marker: {
          enabled: false,
        },
      },
    },
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${
          this.key
        }</span><br/><span style="color:${this.point.color}">●</span> ${
          this.series.name
        }: <b>${intl.formatNumber(this.point.y)}</b><br/>`;
      },
    },
    series: getSeries()?.series,
  };
  return (
    <Card>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <div>
          <h4 className="mb-0">{title} </h4>
        </div>
        <div>
          {getSeries()?.updatedAt}
          <Tooltip title={decs} className="ml-1" placement={'topRight'}>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </Flex>
      <Skeleton loading={getSeries()?.loading || false} active>
        <div className={`${prefix ? 'd-flex' : ''} ${title ? 'mt-3' : ''}`}>
          {prefix ? <div className="mr-2">{prefix}</div> : null}
          <div>
            <div className="d-flex align-items-center">
              <h1 className="mb-0 font-weight-bold">
                {getSeries() && getSeries().avg
                  ? intl.formatNumber(getSeries().avg)
                  : 0}
              </h1>

              {status ? (
                <span
                  className={`font-size-md font-weight-bold ml-3 ${
                    status !== 0 && status > 0 ? 'text-success' : 'text-danger'
                  }`}
                >
                  {status}
                  {status !== 0 && status > 0 ? (
                    <ArrowUpOutlined />
                  ) : (
                    <ArrowDownOutlined />
                  )}
                </span>
              ) : null}
            </div>
            <HighchartsReact highcharts={Highcharts} options={chartOptions} />
            {subtitle && (
              <div className="text-gray-light mt-1">
                {subtitle}
                {getSeries() && getSeries().todayCount
                  ? intl.formatNumber(getSeries().todayCount)
                  : 0}
              </div>
            )}
          </div>
        </div>
      </Skeleton>
    </Card>
  );
};

StatisticWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  value: PropTypes.string,
  subtitle: PropTypes.string,
  status: PropTypes.number,
  prefix: PropTypes.element,
};

export default StatisticWidget;
