import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useLazyQuery } from '@apollo/client';
import { connect } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import utils from '../../../utils';
import {
  Divider,
  Input,
  Form,
  Button,
  Menu,
  Drawer,
  message,
  Modal,
  Spin,
  Avatar,
  Grid,
  Dropdown,
  Tooltip,
  Image,
} from 'antd';
import {
  SendOutlined,
  MenuOutlined,
  EditOutlined,
  UserOutlined,
  DeleteOutlined,
  SearchOutlined,
  SettingOutlined,
  FileImageOutlined,
  StopOutlined,
} from '@ant-design/icons';

import AvatarStatus from 'components/shared-components/AvatarStatus';
import Loading from 'components/shared-components/Loading';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import ChatService from 'services/ChatService';
import ChatEdit from './ChatEdit';
import UserEdit from './UserEdit';
import ChatBan from './ChatBan';
import Utils from 'utils';

import { SUBSCRIPTIONS_QUERY } from 'graphql/query/chatting';

const MESSAGE_SIZE = 30;

const { confirm } = Modal;

const Conversation = (props) => {
  const { current_project, current_profile } = props;
  const projectId = Utils.getProjectID();
  const channelId = Utils.getID(props.channel && props.channel.id);
  const { useBreakpoint } = Grid;
  const isTablet = Utils.isTablet(useBreakpoint());

  const { formatMessage } = useIntl();
    
  const [visible, setVisible] = useState(false);
  const [updateUserVisible, setUpdateUserVisible] = useState(false);
  const [updateChannelVisible, setUpdateChannelVisible] = useState(false);
  const [banVisible, setBanVisible] = useState(false);
  const [banUserId, setBanUserId] = useState('');
  const [banData, setBanData] = useState({});
  const [showLoadMore, setShowLoadMore] = useState(true);
  const [subscriptions, setSubscriptions] = useState([]);
  const [filterSub, setFilterSub] = useState([]);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [subLoading, setSubLoading] = useState(false);
  const [newMsg, setNewMsg] = useState('');

  const fileInput = useRef(null);

  const wrapper = React.createRef();
  const messageRef = React.createRef();

  const [SubscriptionsQuery, SubscriptionsData] = useLazyQuery(
    SUBSCRIPTIONS_QUERY,
    {
      fetchPolicy: 'network-only',
      skip: !channelId,
    }
  );
  
  useEffect(() => {
    if (!channelId) return;

   
    ChatService.getInstance().bind(
      'onMemberJoined',
      function (channel, payload) {
        console.log(channel);
        console.log(payload);
      }
    );
    ChatService.getInstance().bind(
      'onMemberRemoved',
      function (channel, payload) {
        console.log(channel);
        console.log(payload);
      }
    );
    ChatService.getInstance().bind(
      'onMessageReceived',
      function (channel, payload) {
        if (channel === channelId) {
          setMessages((preState) => [...preState, payload]);
          scrollToEnd();
        } else {
          console.error('channelId is not matched', channel, channelId);
        }
      }
    );

    return () => {
      setMessages([]);
    };
  }, [channelId]);

  useEffect( () => {
    if (channelId) {
      setLoading(true);
      setMessages([]);
      const joinChannel = async () => {
        if(await ChatService.getInstance().joinChannel(channelId))
        {
          getConversation(0, MESSAGE_SIZE, true);
          scrollToEnd();
        }

        setLoading(false);
      };
      joinChannel();
      
      return () => {      
      };      
    }
  }, [channelId]);

  useEffect(() => {
    if (SubscriptionsData?.data?.subscriptions) {
      const subscriptions = SubscriptionsData?.data?.subscriptions?.edges;

      setSubscriptions(subscriptions);
      setFilterSub(subscriptions);
    } else {
      setSubscriptions([]);
      setFilterSub([]);
    }
  }, [SubscriptionsData]);

  const menu = () => (
    <Menu>
      <Menu.Item
        key="conversation_0"
        onClick={() => {
          SubscriptionsQuery({
            variables: {
              projectId,
              filter: JSON.stringify({
                project_id: projectId,
                channel_id: channelId,
              }),
              sort: JSON.stringify({ createdAt: -1 }),
              option: JSON.stringify({ offset: 0, per_page: 10000 }),
            },
          });
          setVisible(true);
        }}
      >
        <UserOutlined className="mr-2" />
        <span>
          <FormattedMessage id="chat.menu1" />
        </span>
      </Menu.Item>
      <Menu.Item
        key="conversation_1"
        onClick={() => {
          setUpdateChannelVisible(true);
        }}
      >
        <EditOutlined className="mr-2" />
        <span>
          <FormattedMessage id="chat.menu2" />
        </span>
      </Menu.Item>

      <Menu.Divider />

      {/* 채널 정지 Disable이 사라져 우선 주석처리 */}
      {/* {props?.channel?.disabled === true ? (
        <Menu.Item key="conversation_4" onClick={() => onBlockChannel(false)}>
          <UserDeleteOutlined />
          <span>
            <FormattedMessage id="chat.setting.able" />
          </span>
        </Menu.Item>
      ) : (
        <Menu.Item key="conversation_3" onClick={() => onBlockChannel(true)}>
          <UserDeleteOutlined />
          <span>
            <FormattedMessage id="chat.setting.disable" />
          </span>
        </Menu.Item>
      )} */}

      <Menu.Item key="conversation_5" onClick={() => onDeleteChannel()}>
        <DeleteOutlined className="mr-2" />
        <span>
          <FormattedMessage id="chat.menu3" />
        </span>
      </Menu.Item>
    </Menu>
  );

  const onDeleteChannel = () => {
    confirm({
      title: formatMessage({ id: 'chat.delete.confirm.title' }),
      content: formatMessage({ id: 'chat.delete.confirm.content' }),
      okText: formatMessage({ id: 'common.delete' }),
      cancelText: formatMessage({ id: 'common.cancel' }),
      okType: 'danger',
      onOk() {
        message.loading({
          content: formatMessage({
            id: 'common.message.deleting',
          }),
          key: 'deleteChannel',
          duration: 0,
        });

        ChatService.getInstance()
          .deleteChannel(channelId)
          .then((result) => {
            if (result?.id) {
              message.success({
                content: formatMessage({ id: 'common.message.delete.success' }),
                key: 'deleteChannel',
              });
              props.getNewChannels();
            } else {
              message.error({
                content: formatMessage({ id: 'common.message.delete.fail' }),
                key: 'deleteChannel',
              });
            }
          })
          .catch(() => {
            message.error({
              content: formatMessage({ id: 'common.message.delete.fail' }),
              key: 'deleteChannel',
            });
          });
      },
    });
  };

  const getConversation = (offset, per_page, scrollend, reverse) => {
    let scrollHeight = 0;

    const elem = document.querySelector('#messages');
    if (elem) scrollHeight = elem.scrollHeight;

    setSubLoading(true);
    ChatService.getInstance().getMessages(
      { channel_id: channelId },
      { sort_id: -1 },
      { offset, per_page },
      (error, entries) => {
        setLoading(false);
        setSubLoading(false);
        if (error) {
          console.error('error = ', error);
        } else {
          if (entries) {
            let msg = null;
            if (reverse) {
              entries.reverse();
            }
            msg = entries.edges.map((payload, i) => {
              setMessages((messages) => [payload.node, ...messages]);
            });
            if (scrollend) {
              scrollToEnd();
            } else {
              const elem = document.querySelector('#messages');
              if (elem) {
                elem.scrollTop = elem.scrollHeight - scrollHeight;
              }
            }
            setShowLoadMore(msg.length >= MESSAGE_SIZE ? true : false);
          }
        }
      }
    );
  };

  const getMsgType = (obj) => {
    if(obj?.message_type === "file" && obj?.attachment_filenames)
    {
      
      const file = obj?.attachment_filenames;
        return (          
            <Image              
              onLoad={() => {
                if (messages.length <= 30) {
                  scrollToEnd();
                }
              }}
              style={{ height: 'auto', maxWidth: '240px' }}              
              src={process.env.REACT_APP_API_ENDPOINT_URL + file?.url + '?admin=true'}
              preview={{
                src: `${process.env.REACT_APP_API_ENDPOINT_URL}${file?.url}?admin=true`,
              }}
              />
        );
    }
    if(obj?.message_type === "text" && obj?.translate == true)
    {
      return <div>{obj?.content}</div>;
    }
    return <span>{Utils.getMessage(obj.content)}</span>;
  };

  const scrollTo = (element, to, duration) => {
    if (duration <= 0) {
      return;
    }
    const difference = to - element.scrollTop;
    const perTick = (difference / duration) * 10;

    element.scrollTop += perTick;
    if (element.scrollTop === to) {
      return;
    }
    this.actions.scrollTo(element, to, duration - 10);
  };
  const scrollReset = () => {
    const elem = document.querySelector('#messages');

    if (elem) {
      elem.scrollTop = 0;
    }
  };
  const scrollToEnd = () => {
    setTimeout(() => {
      const elem = document.querySelector('#messages');
      if (elem) {
        const scHeight = elem.scrollHeight;
        elem && (elem.scrollTop = scHeight);
      }
    }, 100);
   
  };

  // const scrollToBottom = () => {
  // 	chatBodyRef.current.scrollToBottom()
  // }

  const onSendFile = (file) => {
    ChatService.getInstance()
      .sendImage(channelId, file)
      .then((result) => {
        if (result?.status !== true) {
          message.error({
            content:result.message,
          });
        }
      })

      .catch((e) => {
        message.error({
          content: e.message,
        });
      });
  };

  const onSend = (values) => {
    if (
      current_project?.max_message_length &&
      newMsg &&
      newMsg?.length > current_project?.max_message_length
    ) {
      message.error({
        content: formatMessage({
          id: 'chat.longerror',
        }),
      });
      return;
    }

    if (newMsg) {
      
      if (wrapper) {
        if (messageRef) {
          messageRef.current.focus();
        }
      }
     
      // ChatService.getInstance().translateMessage("ko","en,ja",values.newMsg);
      if(newMsg[0] == "#") {
        if(props?.channel?.integration_id)
        {
          // 파파고
          if(props?.channel?.integration_id == "clova_papago") {
            const data = {
              "text":newMsg.replace(/^#/, ""),
              "srcLang":"auto",
              "targetLang":"en,ja,vi,zh-CN,th"
            };
            ChatService.getInstance().sendIntegration(channelId, props?.channel?.integration_id, "all", data);
          } 
          // chat GPT
          else if(props?.channel?.integration_id == "chatgpt") 
          {
            ChatService.getInstance().sendIntegration(channelId, props?.channel?.integration_id, "all", {"prompt":newMsg.replace(/^#/, ""),"model":"gpt-3.5-turbo"});        
          } else {
            alert(props?.channel?.integration_id + " is not supported.");
          }
        }
        ChatService.getInstance().sendMessage(channelId, {message: newMsg.replace(/^#/, "") });
      } else {
        ChatService.getInstance().sendMessage(channelId, {message: newMsg });
      }
      setNewMsg('');
    }
  };

  const onSearch = (e) => {
    const query = e.target.value;

    const data = subscriptions.filter((item) => {
      return query === ''
        ? item.node.user_id
        : item.node.user_id.toLowerCase().includes(query);
    });
    setFilterSub(data);
  };

  const isMe = (sender_id) => {
    if (sender_id === utils.getID(current_profile?.id)) return true;
    return false;
  };

  const getSendImageButton = () => {
    if (
      current_project?.ncp?.objectstorage_enable === true &&
      Utils.isEmpty(current_project?.ncp?.objectstorage_access_key) === false &&
      Utils.isEmpty(current_project?.ncp?.objectstorage_bucket) === false &&
      Utils.isEmpty(current_project?.ncp?.objectstorage_secret_key) === false
    ) {
      return (

        <Button
          style={{ marginRight: 5 }}
          shape="circle"
          size="small"
          onClick={() => {
            fileInput.current.click();
          }}
        >
          <FileImageOutlined />
        </Button>
      );
    }

    return (
      <Tooltip
        placement="topRight"
        title={<FormattedMessage id="chat.ncperror" />}
      >
        <Button
          style={{ marginRight: 5 }}
          shape="circle"
          size="small"
          disabled={true}
        >
          <FileImageOutlined />
        </Button>
      </Tooltip>
    );
  };

  const chatContentHeader = () => (
    <div className="chat-content-header">
      {isTablet ? (
        <div className={`font-size-lg`}>
          <MenuOutlined onClick={() => props.setMenuOpen(true)} />
        </div>
      ) : (
        ''
      )}
      <h4 className="mb-0 text-truncate">{props?.channel?.name}</h4>
      <div>
        <EllipsisDropdown menu={menu} />
      </div>
    </div>
  );

  const getProfile = (elm) => {
    return (
      <Dropdown
        overlayStyle={{ width: '100px', minWidth: '100px' }}
        trigger={['click']}
        arrow
        overlay={
          <Menu
            items={[
              {
                key: 'ban',
                icon: <StopOutlined />,
                label: <FormattedMessage id="chat.ban" />,
                onClick: (e) => {
                  setBanVisible(true);
                  setBanUserId(elm?.sender?.id);
                  setBanData(null);
                },
              },
            ]}
          />
        }
      >
        <a onClick={(e) => e.preventDefault()}>
          <AvatarStatus
            src={elm?.sender?.profile ? elm?.sender?.profile : null}
            name={elm?.sender?.name}
            subTitle={elm?.sender?.id}
            icon={<UserOutlined />}
          />
        </a>
      </Dropdown>
    );
  };

  const chatContentBody = () => (
    <div id="messages" className="chat-content-body">
      <InfiniteScroll
        initialLoad={false}
        pageStart={0}
        threshold={40}
        loadMore={() => {
          let sort_id = '';
          if (messages[0]) {
            sort_id = messages[0].sort_id;
          }
          setShowLoadMore(false);
          getConversation(messages.length, MESSAGE_SIZE, '', '');
        }}
        // loader={
        //   <div key={0} style={{ textAlign: 'center' }}>
        //     <FormattedMessage id="chat.loading" />
        //   </div>
        // }
        hasMore={showLoadMore}
        useWindow={false}
        isReverse={true}
      >
        <Spin spinning={subLoading}>
          {messages ? (
            messages.map((elm, i) => (              
              <div key={`msg-${elm.channel_id}-${i}`}>
                {moment(elm.created_at).format('L') !==
                moment(messages[i - 1]?.created_at).format('L') ? (
                  <div className={'msg datetime'}>
                    <Divider>
                      {moment(elm.created_at).format('YYYY-MM-DD')}
                    </Divider>
                  </div>
                ) : null}
                {isTablet && isMe(elm.sender.id) === false ? (
                  <div className="mb-1">{getProfile(elm)}</div>
                ) : null}
                <div
                  className={`msg ${
                    isMe(elm.sender.id) ? 'msg-sent' : 'msg-recipient'
                  } ${isTablet ? 'isTablet' : ''} `}
                >
                  {!isTablet && isMe(elm.sender.id) === false ? (
                    <div className="mr-2" style={{ maxWidth: '45%' }}>
                      {getProfile(elm)}
                    </div>
                  ) : null}
                  {isMe(elm.sender.id) ? (
                    <>
                      <span className="date">
                        {moment(elm.created_at).format('YY-MM-DD')}
                        <br />
                        {moment(elm.created_at).format('HH:mm')}
                      </span>
                      <div className={`bubble`}>
                        <div className="bubble-wrapper text-break">
                          {getMsgType(elm)}
                        </div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div className={`bubble`}>
                        <div className="bubble-wrapper text-break">
                          {getMsgType(elm)}
                        </div>
                      </div>
                      <span className="date">
                        {moment(elm.created_at).format('YY-MM-DD')}
                        <br />
                        {moment(elm.created_at).format('HH:mm')}
                      </span>
                    </>
                  )}
                </div>
              </div>
            ))
          ) : (
            <div></div>
          )}
        </Spin>
      </InfiniteScroll>
    </div>
  );

  const chatContentFooter = () => (
    <div className="chat-content-footer">
      <Form name="msgInput" ref={wrapper} onFinish={onSend} className="w-100">
        <Form.Item className="mb-0">
          <div
            style={{
              border: '1px solid #e2e2e2',
              borderRadius: '2px',
            }}
          >
            <div
              className="d-flex align-items-center mb-0"
              style={{ margin: 10 }}
            >
              <Avatar
                className="mr-2"
                size="small"
                icon={<UserOutlined />}
                src={current_profile?.profile}
              />
              <div className="text-truncate" style={{ maxWidth: '80%' }}>
                {current_profile?.local?.name || 'Admin'}
              </div>
              <SettingOutlined
                className="ml-2"
                style={{ paddingBottom: 1 }}
                onClick={() => {
                  setUpdateUserVisible(true);
                }}
              />
            </div>

            <UserEdit
              visible={updateUserVisible}
              setVisible={(result) => setUpdateUserVisible(result)}
            />
          
            <Input
              style={{ border: 0, boxShadow: 'unset' }}
              ref={messageRef}
              autoComplete="off"
              bordered={false}
              value={newMsg}
              onChange={(e) => setNewMsg(e.target.value)}
              placeholder={formatMessage({ id: 'chat.placeholder' })}
              suffix={
                <div className="d-flex align-items-center">
                  <input
                    style={{ display: 'none' }}
                    type="file"
                    ref={fileInput}
                    name="uploadfile"
                    onChange={(e) => {
                      if(e.target?.files && e.target?.files[0])
                        onSendFile(e.target?.files[0]);
                      else
                        alert('file not found');
                      e.target.value = "";
                    }}
                  />
                  {getSendImageButton()}
                  <Button
                    shape="circle"
                    type="primary"
                    size="small"
                    htmlType="submit"
                    onClick={onSend}
                  >
                    <SendOutlined />
                  </Button>
                </div>
              }
            />
          </div>
        </Form.Item>
      </Form>
    </div>
  );

  const chatSubscriptions = () => (
    <Drawer
      title={<FormattedMessage id="chat.subscriptions.title" />}
      placement="right"
      width={isTablet ? '80%' : 350}
      onClose={() => setVisible(false)}
      open={visible}
      getContainer={false}
    >
      <div className="mb-3">
        <Input
          placeholder={formatMessage({ id: 'chat.search' })}
          prefix={<SearchOutlined />}
          onChange={(e) => onSearch(e)}
        />
      </div>

      {SubscriptionsData?.loading === true ? (
        <Loading cover="content" />
      ) : (
        filterSub &&
        filterSub.map((elm, i) => {
          return (
            <div
              className={`${i === filterSub.length - 1 ? '' : 'mb-4'}`}
              key={`${i}`}
            >
              <Dropdown
                overlayStyle={{ width: '100px', minWidth: '100px' }}
                trigger={['click']}
                arrow
                overlay={
                  <Menu
                    items={[
                      {
                        key: 'ban',
                        icon: <StopOutlined />,
                        label: <FormattedMessage id="chat.ban" />,
                        onClick: (e) => {
                          setBanVisible(true);
                          setBanUserId(elm?.node?.user_id);
                          setBanData(elm?.node?.memberblock_id);
                        },
                      },
                    ]}
                  />
                }
              >
                <a onClick={(e) => e.preventDefault()}>
                  <AvatarStatus
                    src={elm?.node?.user?.profile}
                    badge={
                      elm?.node?.memberblock_id?.id
                        ? 'error'
                        : elm?.node?.online === true
                        ? 'success'
                        : 'default'
                    }
                    name={<>{elm?.node?.user_id}</>}
                    subTitle={elm?.node?.user_id}
                    icon={<UserOutlined />}
                  />
                </a>
              </Dropdown>
            </div>
          );
        })
      )}
    </Drawer>
  );

  // 채널 선택하지 않은 경우
  if (!channelId) {
    return (
      <div className={`main-content no-gutter`}>
        {isTablet ? (
          <div className={`font-size-lg mb-3 pt-3 px-3`}>
            <MenuOutlined onClick={() => props.setMenuOpen(true)} />
          </div>
        ) : null}
        <div className="chat-content-empty">
          <div className="text-center">
            <img src="/img/others/img-11.png" alt="Start a Conversation" />
            <h1 className="font-weight-light">Start a conversation</h1>
          </div>
        </div>
      </div>
    );
  }

  if (loading === true) {
    return <Loading cover="content" />;
  }

  return (
    <div className="chat-content">
      <ChatEdit
        visible={updateChannelVisible}
        setVisible={(result) => setUpdateChannelVisible(result)}
        projectId={projectId}
        channel={props.channel}
        channels_refetch={props.getNewChannels}
      />
      <ChatBan
        visible={banVisible}
        setVisible={(result) => setBanVisible(result)}
        channelId={channelId}
        userId={banUserId}
        banData={banData}
        subscriptions_refetch={SubscriptionsData.refetch}
      />
      {chatContentHeader()}
      {chatContentBody()}
      {chatContentFooter()}
      {chatSubscriptions()}
    </div>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_project, current_profile } = project;
  return { current_project, current_profile };
};

export default connect(mapStateToProps)(Conversation);
