import { gql } from '@apollo/client';
const AUDIT_FIELD = `
id
project_id
email
status
service
action
ip
raw
createdAt
`;

const AUDIT_QUERY = gql`
  query audit(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    audit(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${AUDIT_FIELD}
        }
      }
    }
  }
`;
const AUDITS_QUERY = gql`
  query audits(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    audits(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${AUDIT_FIELD}
        }
      }
    }
  }
`;

export { AUDIT_FIELD, AUDITS_QUERY };
