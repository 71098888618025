import React, { useState, useEffect, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router';

import { AUTH_TOKEN, COMPANY_ID } from 'redux/constants/Auth';
import { setCurrentProject, setCurrentProfile } from 'redux/actions/Project';
import Loading from 'components/shared-components/Loading';
import UserList from './user/list';
import Chat from './chat';
import Guide from './guide';
import Utils from '../../utils';


import loadable from '@loadable/component';
import DefaultDashboard from './dashboards';
import UserBlock from './user/block';
import Search from './search';
import Settings from './settings';
import Profile from '../app-views/profile';
import Jobmanagement from './jobmanagement';
import Story from './story';
import Archive from './archive';
import Message from './message';
import Audit from './audit';
import FriendShip from './friendship';
import Analytics from './analytics';

import { PROJECT_QUERY } from '../../graphql/query/project';
import { PROFILE_QUERY } from '../../graphql/query/profile';

const NotFound = loadable(() => import('../errors/404'));
export const AppViews = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(undefined);

  const {
    token,
    current_project,
    current_profile,
    setCurrentProject,
    setCurrentProfile,
  } = props;
  
  const params = useParams();
  const storagetoken = localStorage.getItem(AUTH_TOKEN);  
  const company_id = localStorage.getItem(COMPANY_ID);

  useEffect(() => {
    if (storagetoken && token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [storagetoken, token]);
  // Set Project Redux
  const projectData = useQuery(PROJECT_QUERY, {
    variables: { ncpId: company_id, id: params.projectId },
    skip: !params.projectId,
  });

  useEffect(() => {
    if (
      projectData?.data?.project &&
      projectData.data.project !== current_project?.project
    ) {
      setCurrentProject(projectData.data.project);
    }
  }, [projectData]);

  // Set Profile Redux
  const profileData = useQuery(PROFILE_QUERY, {
    fetchPolicy: isAuthenticated ? 'network-only' : 'cache-only',
  });

  useEffect(() => {
    if (isAuthenticated && profileData?.data?.me) {
      const dataSource = profileData.data.me;

      if (dataSource.id !== current_profile?.profile?.id) {
        setCurrentProfile(dataSource);

        Utils.setDefaultTimezone(dataSource.timezone);
      }
    }
  }, [profileData]);

  
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Routes>
        

      <Route exact path='/' element={<Navigate to="dashboards" />}/>
        <Route
          exact path="/dashboards/*"
          element={<DefaultDashboard {...props}/>}
        />
        <Route path="/user/list/*"
          element={<UserList {...props}/>} />
        <Route
          exact path="/user/block/*"
          element={<UserBlock {...props}/>}
        />
        <Route
          exact path="/chat/*"
          element={<Chat {...props}/>}
        />
        <Route
          exact path="/search/*"
          element={<Search {...props}/>}
        />
        <Route
          exact path="/message/*"
          element={<Message {...props}/>}
        />
        <Route
          exact path="/audit/*"
          element={<Audit {...props}/>}
        />
         <Route
          exact path="/story/*"
          element={<Story {...props}/>}
        />
         <Route
          exact path="/friendship/*"
          element={<FriendShip {...props}/>}
        />
         <Route
          exact path="/analytics/*"
          element={<Analytics {...props}/>}
        />
        <Route
          exact path="/archive/*"
          element={<Archive {...props}/>}
        />
        <Route
          exact path="/profile/*"
          element={<Profile {...props} />}
        />
        <Route
          exact path="/settings/*"
          element={<Settings {...props}/>}
        />-
        
        <Route
          exact path="/jobmanagement"
          element={<Jobmanagement {...props}/>}
        />
        <Route path="/guide" element={<Guide {...props}/>} />
        
      <Route exact path='*' element={<Navigate to="/notfound" />} />
      </Routes>
    </Suspense>
  );
};

const mapStateToProps = ({ auth, project }) => {
  const { token } = auth;
  const { current_project, current_profile } = project;
  return { token, current_project, current_profile };
};

export default connect(mapStateToProps, {
  setCurrentProject,
  setCurrentProfile,
})(AppViews);
