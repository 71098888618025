import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Menu, Dropdown, Input } from 'antd';
import { DownOutlined, PlusCircleOutlined } from '@ant-design/icons';
import utils from 'utils';

import { PROJECTS_QUERY, PROJECT_QUERY } from '../../graphql/query/project';

export const NavProfile = () => {
  const company_id = localStorage.getItem('company_id');
  const { formatMessage } = useIntl();

  const [selectPrjDropdown, setSelectPrjDropdown] = useState();
  const [project, setProject] = useState();
  const [projects, setProjects] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchedProjects, setSearchedProjects] = useState([]);

  const projectData = useQuery(PROJECT_QUERY, {
    variables: { ncpId: company_id, id: utils.getProjectID() },
    fetchPolicy: 'network-only',
  });

  const projectsData = useQuery(PROJECTS_QUERY, {
    variables: { ncpId: company_id, filter: JSON.stringify({deleted:false}) },
    skip: utils.isEmpty(localStorage.getItem('auth_token')),
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (projectData?.data?.project) {
      setProject(projectData?.data?.project);
    }
  }, [projectData]);

  useEffect(() => {
    setSearchLoading(false);

    if (utils?.isEmpty(projectsData?.variables?.search) === true) {
      if (
        projectsData?.data?.projects?.edges &&
        projectsData.data.projects.edges.length > 0
      ) {
        setProjects(projectsData.data.projects?.edges);
        setSearchedProjects(projectsData.data.projects?.edges);
      }
    } else {
      setSearchedProjects(projectsData?.data?.projects?.edges);
    }
  }, [projectsData]);

  const onSearch = (searchInput) => {
    const data = projects.filter((item) => {
      return searchInput === ''
        ? item.node.name
        : item.node.name.toLowerCase().includes(searchInput);
    });

    setSearchedProjects(data);
  };

  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="d-flex">
        <Input.Search
          enterButton
          allowClear
          placeholder={formatMessage({
            id: 'header.search',
          })}
          loading={projectsData?.loading === true || searchLoading === true}
          onChange={(e) => onSearch(e.target.value)}
        />
      </div>
      <div
        className="nav-profile-body p-0"
        style={{
          borderLeft: '1px solid #e2e2e2',
          borderBottom: '1px solid #e2e2e2',
        }}
      >
        <Menu style={{ maxHeight: '200px', overflowY: 'auto' }}>
          {searchedProjects.length < 1 ? (
            <div className="p-3">
              <FormattedMessage id="common.error.search" />
            </div>
          ) : (
            searchedProjects.map((item) => {
              item = item?.node;
              return (
                <Menu.Item key={item?.id} disabled={project?.id === item?.id}>
                  <a href={`/${utils.getID(item?.id)}`}>
                    <span className="font-weight-normal">{item?.name}</span>
                  </a>
                </Menu.Item>
              );
            })
          )}
        </Menu>
        <div
          style={{
            borderTop: '1px solid #e2e2e2',
            borderRight: '1px solid #e2e2e2',
            padding: '10px 16px',
          }}
        >
          <a href="https://console.ncloud.com/cloudchat/projects">
            <span>
              <FormattedMessage id="header.create" />
            </span>
            <span className="float-right">
              <PlusCircleOutlined className="mr-auto" />
            </span>
          </a>
        </div>
      </div>
    </div>
  );

  return (
    <span className="text-truncate">
      {projects && projects.length > 1 && (
        <Dropdown
          className="mr-1"
          placement="bottomLeft"
          trigger={['click']}
          visible={selectPrjDropdown}
          overlay={profileMenu}
          onVisibleChange={(e) => setSelectPrjDropdown(e)}
        >
          <a
            className="ant-dropdown-link text-reset font-weight-bold"
            onClick={(e) => e.preventDefault()}
          >
            <FormattedMessage id="header.select" />
            <DownOutlined className="ml-2" />
          </a>
        </Dropdown>
      )}
      <span
        style={{ cursor: 'default' }}
        className="text-reset font-weight-bold mr-1"
      >
        {project?.name}
      </span>
    </span>
  );
};

export default NavProfile;
