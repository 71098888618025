import { gql } from '@apollo/client';
const FRIENDSHIP_FIELD = `
project_id
id
status
user {
    id
    nickname
    profile
}
friend {
    id
    nickname
    profile
}
friend_id
created_at
updated_at
requested_at
`;

const FRIENDSHIP_QUERY = gql`
  query friendship(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    friendships(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${FRIENDSHIP_FIELD}
        }
      }
    }
  }
`;
const FRIENDSHIPS_QUERY = gql`
  query friendships(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    archives(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${FRIENDSHIP_FIELD}
        }
      }
    }
  }
`;

export { FRIENDSHIP_QUERY, FRIENDSHIPS_QUERY };
