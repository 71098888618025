import React from 'react';
import { Avatar, Drawer, Divider, Button, Grid } from 'antd';
import {
  CalendarOutlined,
  MessageOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import CopyText from 'components/shared-components/CopyText';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import utils from 'utils';
import Utils from 'utils';

const MessageView = (props) => {
  const { data, visible, close } = props;
  const { useBreakpoint } = Grid;
  return (
    <Drawer
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      placement="right"
      onClose={close}
      closable={false}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            size="small"
            className="ml-1"
            icon={<CloseCircleOutlined className="mr-2" />}
            onClick={close}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        </div>
      }
    >
      <div className="text-center mt-3">
        <Avatar size={80} src={data?.sender?.profile} icon={<UserOutlined />} />
        <h3 className="mt-2 mb-0">{data?.sender?.name}</h3>
        <span className="text-muted">{data?.sender?.id}</span>
      </div>
      <Divider dashed />
      <div className="">
        <h6 className="text-muted text-uppercase mb-3">
          <FormattedMessage id="search.view.subtitle1" />
        </h6>
        <p>
          <TeamOutlined />
          <span className="ml-1 text-muted">
            <FormattedMessage id="search.view.channel_id" />
          </span>
          <span className="ml-2 text-dark">
            {data?.channel_id} <CopyText text={data?.channel_id} />
          </span>
        </p>
      </div>
      <div className="mt-5">
        <h6 className="text-muted text-uppercase mb-3">
          <FormattedMessage id="search.view.subtitle2" />
        </h6>
        <p>
          <InfoCircleOutlined />
          <span className="ml-1 text-muted">
            <FormattedMessage id="search.view.message_id" />
          </span>
          <span className="ml-2 text-dark text-break">
            {data?.message_id} <CopyText text={data?.message_id} />
          </span>
        </p>
        <p>
          <MessageOutlined />
          <span className="ml-1 text-muted">
            <FormattedMessage id="search.view.content.text" />
          </span>
          <span className="ml-2 text-dark text-break">
            {Utils.getMessage(data?.content)}
            <CopyText text={Utils.getMessage(data?.content)} />
          </span>
        </p>
        <p>
          <CalendarOutlined />
          <span className="ml-1 text-muted">
            <FormattedMessage id="search.view.created_at" />
          </span>
          <span className="ml-2 text-dark text-break">
            {moment(data?.personalInfo?.created_at).format(
              'YYYY-MM-DD HH:mm:ss'
            )}
          </span>
        </p>
      </div>
    </Drawer>
  );
};

export default MessageView;
