import { CURRENT_PROJECT, CURRENT_PROFILE } from '../constants/Project';

export function setCurrentProject(project) {
  if(!project)
    console.error('project is empty');    
  return {
    type: CURRENT_PROJECT,
    project,
  };
}

export function setCurrentProfile(profile) {
  if(!profile)
    console.error('profile is empty');    
  return {
    type: CURRENT_PROFILE,
    profile,
  };
}
