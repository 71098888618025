import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router';
import { Radio, Avatar, Row, Col, Menu, Card, Tooltip, Button } from 'antd';
import {
  AppstoreOutlined,
  UnorderedListOutlined,
  CalendarOutlined,
  PlusOutlined,
  UserOutlined,
  SearchOutlined,
  WechatOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import PageHeaderAlt from 'components/layout-components/PageHeaderAlt';
import Flex from 'components/shared-components/Flex';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import utils from 'utils';
import AppsView from './AppsView';

import { PROJECTS_QUERY } from '../../../graphql/query/project';

const VIEW_LIST = 'LIST';
const VIEW_GRID = 'GRID';

const ItemHeader = ({ name, id }) => (
  <div>
    <h4 className="mb-0">{name}</h4>
    <span className="text-muted">{utils.getID(id)}</span>
  </div>
);

const ItemAction = ({ id, viewId }) => {
  const menuList = {
    member: <UserOutlined />,
    chat: <WechatOutlined />,
    search: <SearchOutlined />,
    settings: <SettingOutlined />,
  };
  return (
    <EllipsisDropdown
      menu={
        <Menu>
          <Menu.Item
            key={id}
            onClick={(event) => {
              viewId(id);
              event.domEvent.stopPropagation();
            }}
          >
            <EyeOutlined />
            <FormattedMessage id="projects.menu.view" />
          </Menu.Item>
          {Object.keys(menuList).map((item, index) => (
            <Menu.Item
              key={index + 1}
              onClick={(event) => {
                viewId(id, item);
                event.domEvent.stopPropagation();
              }}
            >
              {menuList[item]}
              <FormattedMessage id={`sidenav.${item}`} />
            </Menu.Item>
          ))}
        </Menu>
      }
    />
  );
};

const ItemInfo = ({ created_at }) => (
  <Flex alignItems="center">
    <div>
      <Tooltip title={<FormattedMessage id="projects.info.created_at" />}>
        <CalendarOutlined className="text-muted font-size-md" />
      </Tooltip>
      <span className="ml-2 text-muted">
        {moment(created_at).format('YYYY-MM-DD HH:mm:ss')}
      </span>
    </div>
  </Flex>
);

const ItemAvatar = ({ name, index }) => {
  return (
    <AvatarStatus color index={index} text={name.substr(0, 4)} size="large" />
  );
};

const ListItem = ({ data, viewId, index }) => (
  <div
    className="bg-white rounded p-3 mb-3 border"
    onClick={() => viewId(data.id)}
    style={{ cursor: 'pointer' }}
  >
    <Row align="middle">
      <Col xs={24} sm={24} md={2}>
        <ItemAvatar name={data.name} index={index} />
      </Col>
      <Col xs={24} sm={24} md={14}>
        <ItemHeader name={data.name} id={data.id} viewId={viewId} />
      </Col>
      <Col xs={24} sm={24} md={6}>
        <ItemInfo created_at={data.created_at} />
      </Col>
      <Col xs={24} sm={24} md={2}>
        <div className="text-right">
          <ItemAction id={data.id} viewId={viewId} />
        </div>
      </Col>
    </Row>
  </div>
);

const GridItem = ({ data, viewId, index }) => (
  <Card onClick={() => viewId(data.id)} style={{ cursor: 'pointer' }}>
    <Flex alignItems="center" justifyContent="between">
      <ItemAvatar name={data.name} index={index} />
      <ItemHeader name={data.name} id={data.id} viewId={viewId} />
      <ItemAction id={data.id} viewId={viewId} />
    </Flex>
    <div className="mt-2">
      <ItemInfo created_at={data.created_at} />
    </div>
  </Card>
);

const ProjectList = () => {
  let navigate = useNavigate();
  const params = useParams();

  const [view, setView] = useState(VIEW_GRID);
  const [list, setList] = useState([]);
  const [createVisible, setUserProfileVisible] = useState();

  const company_id = localStorage.getItem('company_id');

  const projectsData = useQuery(PROJECTS_QUERY, {
    variables: { ncpId: company_id, filter: JSON.stringify({deleted:false})},
    skip: utils.isEmpty(localStorage.getItem('auth_token')),
  });

  useEffect(() => {
    if (
      projectsData?.data?.projects?.edges &&
      projectsData.data.projects.edges.length > 0
    ) {
      if (process.env.REACT_APP_NAVERCLOUD === 'true') {
        if (projectsData.data.projects.edges.length <= 1) {
          navigate(
            `${utils.getID(
              projectsData.data.projects.edges[0].node.id
            )}/dashboards`
          );
        } else {
          setList(projectsData.data.projects.edges);
        }
      } else {
        setList(projectsData.data.projects.edges);
      }
    }
  }, [projectsData]);

  const onChangeProjectView = (e) => {
    setView(e.target.value);
  };

  const viewItem = (id, menu) => {
    if (menu === 'member') {
      menu = 'user/list';
    }
    navigate(`${utils.getID(id)}/${menu || 'dashboards'}`);
  };

  const showUserProfile = (userInfo) => {
    setUserProfileVisible(true);
  };

  const closeUserProfile = () => {
    setUserProfileVisible(false);
  };

  if (process.env.REACT_APP_NAVERCLOUD === 'true') {
    if (list.length <= 1) {
      return <div></div>;
    }
  }

  return (
    <>
      <PageHeaderAlt className="bg-white border-bottom">
        <div className="container-fluid">
          <Flex justifyContent="between" alignItems="center" className="py-4">
            <h2>
              <FormattedMessage id="projects.title" />
            </h2>
            <div>
              <Radio.Group
                defaultValue={VIEW_GRID}
                onChange={(e) => onChangeProjectView(e)}
                size="small"
              >
                <Radio.Button value={VIEW_GRID}>
                  <AppstoreOutlined />
                </Radio.Button>
                <Radio.Button value={VIEW_LIST}>
                  <UnorderedListOutlined />
                </Radio.Button>
              </Radio.Group>
              <Button
                size="small"
                type="primary"
                className="ml-2"
                onClick={showUserProfile}
              >
                <PlusOutlined />
                <span>
                  <FormattedMessage id="projects.button.new" />
                </span>
              </Button>
            </div>
          </Flex>
        </div>
      </PageHeaderAlt>
      <div
        className={`my-4 ${
          view === VIEW_LIST ? 'container' : 'container-fluid'
        }`}
      >
        {view === VIEW_LIST ? (
          list.map((elm, index) => {
            if (elm?.node?.id) {
              return (
                <ListItem
                  key={elm.node.id}
                  data={elm.node}
                  viewId={(id, menu) => viewItem(id, menu)}
                  index={index}
                />
              );
            }
          })
        ) : (
          <Row gutter={16}>
            {list.map((elm, index) => {
              if (elm?.node?.id) {
                return (
                  <Col xs={24} sm={24} lg={8} xl={8} xxl={6} key={elm.node.id}>
                    <GridItem
                      key={elm.node.id}
                      data={elm.node}
                      viewId={(id, menu) => viewItem(id, menu)}
                      index={index}
                    />
                  </Col>
                );
              }
            })}
          </Row>
        )}
      </div>
      <AppsView visible={createVisible} close={closeUserProfile} />
    </>
  );
};

export default ProjectList;
