import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  Modal,
  Button,
  Input,
  message,
  Form,
  Col,
  Row,
  Grid,
  Radio,
  Select,
  Switch,
} from 'antd';
import {
  SaveOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import ChatService from 'services/ChatService';
import { INTEGRATION_QUERY} from '../../../graphql/query/integration';
import utils from 'utils';

import { CHANNEL_QUERY } from '../../../graphql/query/chatting';

const ChatEdit = (props) => {
  const { projectId, visible, channel, setVisible, channels_refetch } = props;

  const { useBreakpoint } = Grid;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [options, setOptions] = useState([]);
  const integrationData = useQuery(INTEGRATION_QUERY, {
    variables:{	
      "filter":JSON.stringify({status: 1}),
      "sort" : JSON.stringify({createdAt:1}),
      "option": JSON.stringify({offset:0,per_page:10})
    },
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (integrationData.data) {
      setOptions(integrationData.data.integrations.edges);
    }
  }, [integrationData]);
  
  const channelData = useQuery(CHANNEL_QUERY, {
    variables: {
      projectId: projectId,
      id: utils.getID(channel?.id),
    },
    skip: visible === false,
    fetchPolicy: !channel ? 'cache-only' : 'cache-first',
  });

  useEffect(() => {
    if (channelData?.data?.channel) {
      const data = channelData?.data?.channel;
      form.setFieldsValue({
        name: data?.name,
        type: data?.type,
        uniqueId: data?.unique_id,
        imageUrl: data?.image_url,
        customField: data?.customField,
        integrationId: data?.integration_id,
        push: data?.push,
        translation: data?.translation,
      });
    }
  }, [channelData]);

  const onUpdateChannel = (values) => {
    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateChannel',
      duration: 0,
    });

    const variables = {
      id: utils.getID(channel?.id),
      name: values?.name,
      type: values?.type,
      uniqueId: values?.uniqueId,
      translation: values?.translation,
      push: values?.push,
      imageUrl: values.imageUrl,
      customField: values?.customField,
      integrationId: values?.integrationId,
      members: [],
    };
    ChatService.getInstance()
      .updateChannel(utils.getID(channel?.id), variables)
      .then((result) => {
        if (result?.id) {
          onChildrenModalClose();
          channels_refetch();

          message.success({
            content: formatMessage({
              id: 'common.message.save.success',
            }),
            key: 'updateChannel',
          });
        } else {
          message.error({
            content: formatMessage({
              id: 'common.message.save.fail',
            }),
            key: 'updateChannel',
            duration: 2,
          });
        }
      })
      .catch((e) => {

        message.error({
          content: e.message,
          key: 'updateChannel',
          duration: 2,
        });
      });
  };

  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        onUpdateChannel(values);
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const onChildrenModalClose = () => {
    form.resetFields();
    setVisible(false);
  };

  return (
    <Modal
    open={visible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 600}
      closable={false}
      forceRender
      destroyOnClose
      onCancel={onChildrenModalClose}
      title={
        <>
          <FormattedMessage id="chat.menu2" />
        </>
      }
      footer={
        <div className="alignRight">
          <Button
            size="small"
            onClick={onChildrenModalClose}
            icon={<CloseCircleOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined className="mr-2" />}
            onClick={() => form.submit()}
          >
            <FormattedMessage id="common.update" />
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ type: 'PUBLIC' }}
        onFinish={onSave}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="name"
              label={<FormattedMessage id="chat.channel.label.channel_name" />}
              rules={[
                {
                  required: true,
                  whitespace: true,
                  message: (
                    <FormattedMessage id="chat.channel.rules.channel_name" />
                  ),
                },
              ]}
            >
              <Input
                className="w-100"
                size="small"
                placeholder={formatMessage({
                  id: 'chat.channel.placeholder.channel_name',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="type"
              label={<FormattedMessage id="chat.channel.label.type" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="chat.channel.placeholder.type" />
                  ),
                },
              ]}
            >
              <Radio.Group>
                <Radio value="PUBLIC">
                  <FormattedMessage id="chat.channel.label.type.public" />
                </Radio>
                <Radio value="PRIVATE">
                  <FormattedMessage id="chat.channel.label.type.private" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="uniqueId"
              label={<FormattedMessage id="chat.channel.label.uniqueId" />}
            >
              <Input
                className="w-100"
                size="small"
                placeholder={formatMessage({
                  id: 'chat.channel.placeholder.uniqueId',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="imageUrl"
              label={<FormattedMessage id="chat.channel.label.imageUrl" />}
            >
              <Input
                className="w-100"
                size="small"
                placeholder={formatMessage({
                  id: 'chat.channel.placeholder.imageUrl',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="customField"
                label={<FormattedMessage id="chat.channel.customField.label" />}
              >
                <Input
                  className="w-100"
                  size="small"
                 
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="integrationId"
                label={<FormattedMessage id="chat.channel.integration.label" />}
              >
                <Select defaultValue="" style={{ width: 120 }}>
                  <Option value="">None</Option>
                  {options.map((option) => (
                    <Option key={utils.getID(option.node.id)} value={utils.getID(option.node.id)}>
                      {option.node.name}
                    </Option>
                  ))}
                </Select>

              </Form.Item>
            </Col>
          </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="push"
              label={<FormattedMessage id="chat.channel.label.notice" />}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="translation"
              label={<FormattedMessage id="chat.channel.label.translation" />}
              valuePropName="checked"
            >
              <Switch
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

export default ChatEdit;
