import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Drawer,
  Button,
  Input,
  message,
  Form,
  Col,
  Row,
  DatePicker,
  Radio,
  Grid,
  Checkbox,
} from 'antd';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import utils from 'utils';

import { CREATE_MEMBERBLOCK_MUTATION } from '../../../../graphql/mutations/member';

const options = [
  {
    label: <FormattedMessage id="member.list.block.form.label.period.1" />,
    value: '1',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.2" />,
    value: '7',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.3" />,
    value: '30',
  },
];

const UserView = (props) => {
  const { current_profile, visible, close, refetch_members, projectId } = props;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [periodOption, setPeriodOption] = useState('1');
  const [blockStatus, setBlockStatus] = useState(1);
  const { useBreakpoint } = Grid;
  // Mutation
  const [createMemberBlock] = useMutation(CREATE_MEMBERBLOCK_MUTATION, {
    onCompleted(result) {
      if (result?.createMemberBlock?.memberBlock?.id) {
        refetch_members();
        onChildrenModalClose();

        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'createBlock',
        });
      }
    },
    onError(error) {
      message.error({
        content:
          error.message ||
          formatMessage({
            id: 'common.message.save.fail',
          }),
        key: 'createBlock',
      });
    },
  });

  // UseEffect
  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    if (utils.isEmpty(periodOption) === false) {
      form.setFieldsValue({
        period: [moment(), moment().add(periodOption, 'days')],
      });
    }
  }, [periodOption]);

  useEffect(() => {
    // 일시 정지
    if (blockStatus === 1) {
      setPeriodOption('1');
    }

    // 영구 정지
    if (blockStatus === 2) {
      setPeriodOption(null);

      form.setFieldsValue({
        period: [moment(), moment('2099').endOf('year')],
      });
    }
  }, [blockStatus]);

  // function
  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (utils.getID(current_profile?.id) === values?.member_id) {
          message.error({
            content: formatMessage({
              id: 'member.list.block.form.error1',
            }),
            key: 'createBlock',
          });
          return;
        }

        message.loading({
          content: formatMessage({
            id: 'common.message.updating',
          }),
          key: 'createBlock',
          duration: 0,
        });
        let variables = {
          projectId,
          status: 1,
          memberId: values?.member_id,
          kick: values?.kick,
          startedAt: values?.period?.[0] || undefined,
          endedAt: values?.period?.[1] || undefined,
          messageMulti: [{ default: true, value: values?.message, lang: 'ko' }],
        };

        createMemberBlock({ variables });

        return;
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const onChildrenModalClose = () => {
    setPeriodOption('1');
    setBlockStatus(1);

    form.resetFields();
    close();
  };

  return (
    <Drawer
      title={<FormattedMessage id="member.block.title" />}
      open={visible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      bodyStyle={{ paddingBottom: 80 }}
      closable={false}
      onClose={onChildrenModalClose}
      forceRender
      destroyOnClose
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            size="small"
            onClick={onChildrenModalClose}
            style={{ marginRight: 8 }}
            icon={<CloseCircleOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => form.submit()}
            icon={<SaveOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        initialValues={{
          period: [moment(), moment().add('1', 'days')],
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={
                <FormattedMessage id="member.list.block.form.label.status" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="member.list.block.form.rules.status" />
                  ),
                },
              ]}
            >
              <Radio.Group
                value={blockStatus}
                onChange={(e) => setBlockStatus(e.target.value)}
              >
                <Radio value={1}>
                  <FormattedMessage id="member.list.block.form.label.status.1" />
                </Radio>
                <Radio value={2}>
                  <FormattedMessage id="member.list.block.form.label.status.2" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="kick"
              label={
                <FormattedMessage id="member.list.block.form.label.kick" />
              }
              valuePropName="checked"
            >
              <Checkbox>
                <FormattedMessage id="member.list.block.form.checkbox.kick" />
              </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="member_id"
              label={<FormattedMessage id="member.block.form.label.memberId" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="member.block.form.rules.memberId" />
                  ),
                },
              ]}
            >
              <Input
                size="small"
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'member.block.form.placeholder.memberId',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="message"
              label={
                <FormattedMessage id="member.list.block.form.label.message" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="member.list.block.form.rules.message" />
                  ),
                },
              ]}
            >
              <Input
                size="small"
                style={{ width: '100%' }}
                addonBefore="KR"
                placeholder={formatMessage({
                  id: 'member.list.block.form.placeholder.message',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="period"
              label={
                <div className="justify-content-between d-flex w-100">
                  <FormattedMessage id="member.list.block.form.label.period" />
                  <Radio.Group
                    size="small"
                    optionType="button"
                    disabled={blockStatus === 2 ? true : false}
                    options={options}
                    value={periodOption}
                    onChange={(e) => setPeriodOption(e.target.value)}
                  />
                </div>
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="member.list.block.form.rules.period" />
                  ),
                },
              ]}
            >
              <DatePicker.RangePicker
                className="w-100"
                size="small"
                showTime
                disabled={blockStatus === 2 ? true : false}
                getPopupContainer={(trigger) => trigger.parentNode}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(UserView);
