import { gql } from '@apollo/client';
const INTEGRATION_FIELD = `
id
status
name
description
image
channel_id
project_id
keys {
  key
  value
  type
  label
}
`;

const INTEGRATIONCONFIG_FIELD = `
  project_id
  integration_id
  key
  value
`;

const INTEGRATION_QUERY = gql`
  query integrations(
    $filter: String!
    $option: String
    $sort: String
  ) {
    integrations(
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${INTEGRATION_FIELD}
        }
      }
    }
  }
`;

const INTEGRATIONCONFIG_QUERY = gql`
 query integrationConfigs ($filter: String!, $sort: String, $option:String) {
   integrationConfigs(filter:$filter, sort:$sort, option:$option) {
            totalCount
            edges {
                node {
                  ${INTEGRATIONCONFIG_FIELD}
                }
            }
        }
    }
    
`;

export { INTEGRATION_QUERY, INTEGRATIONCONFIG_QUERY };
