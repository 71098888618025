import gql from 'graphql-tag';

const CREATE_APIKEY_MUTATION = gql`
  mutation CREATE_APIKEY_MUTATION(
    $projectId: String!
    $user_id: String
    $status: Int
    $description: String
    $expiredAt: String
  ) {
    createApiKey(
      input: {
        projectId: $projectId
        user_id: $user_id
        status: $status
        description: $description
        expiredAt: $expiredAt
      }
    ) {
      apiKey {
        id
        key
      }
    }
  }
`;

const DELETE_APIKEY_MUTATION = gql`
  mutation DELETE_APIKEY_MUTATION($id: ID!, $projectId: String!) {
    deleteApiKey(input: { id: $id, projectId: $projectId }) {
      apiKey {
        id
      }
    }
  }
`;

export { CREATE_APIKEY_MUTATION, DELETE_APIKEY_MUTATION };
