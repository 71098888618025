import { gql } from '@apollo/client';
const ARCHIVE_FIELD = `
id
  project_id
status
storageType
project_id
members
user { 
    id
    name    
    profile
    device_type
    language
  }
  channel_id
  channel {
    id
    project_id
    name
    user {
      id
      name
    }
    unique_id
    type
    translation
    members
    disabled
    mutes
    push
    limit
    link_url
    image_url
    created_at
    updated_at
  }
  hit
  filepath
  filename
  original
  format
  size
  channel_id
  created_at
  expired_at
  updated_at
`;

const ARCHIVE_QUERY = gql`
  query archives(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    archives(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${ARCHIVE_FIELD}
        }
      }
    }
  }
`;
const ARCHIVES_QUERY = gql`
  query archives(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    archives(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${ARCHIVE_FIELD}
        }
      }
    }
  }
`;

export { ARCHIVE_QUERY, ARCHIVES_QUERY };
