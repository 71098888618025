import React, { useEffect, useState } from 'react';
import { SaveOutlined, QuestionCircleOutlined , EditOutlined, ExclamationCircleOutlined, EllipsisOutlined, SettingOutlined} from '@ant-design/icons';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import {  INTEGRATIONCONFIG_QUERY} from '../../../graphql/query/integration';
import { CREATE_INTEGRATION_CONFIG_MUTATION } from '../../../graphql/mutations/integration';
import moment from 'moment';
import {
  Modal,
  Button,
  Input,
  message,
  Form,
  Col,
  Row,
  Grid,
  Radio,
  Switch,
  InputNumber,
} from 'antd';

import ChatService from 'services/ChatService';
import utils from 'utils';


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 16 },
  };
  
  const tailLayout = {
    wrapperCol: { offset: 8, span: 16 },
  };

const IntegrationConfig = (props) => {
  const {
    modalVisible,
    selectedItem,
    projectId,
    setModalVisible,
  } = props;

  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);
  const [integrationConfigs, setIntegrationConfig] = useState([]);

  const integrationConfigData = useQuery(INTEGRATIONCONFIG_QUERY, {
    variables:{	
      "filter":JSON.stringify({integration_id: utils.getID(selectedItem?.id)}),
      "sort" : JSON.stringify({createdAt:1}),
      "option": JSON.stringify({offset:0,per_page:30})
    },
    fetchPolicy: 'network-only',
  });

  const [createIntegrationConfig] = useMutation(CREATE_INTEGRATION_CONFIG_MUTATION, {
    onCompleted(result) {
      
      if (result?.createIntegrationConfig?.integrationConfig?.key) {
        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'createIntegrationConfig',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'createIntegrationConfig',
        });
      }
    },
    onError(err) {
      
      message.error({
        content: formatMessage({
          id: 'common.message.save.fail',
        }),
        key: 'createIntegrationConfig',
      });
    },
  });

  useEffect(() => {
    if (integrationConfigData.data) 
    {
      let newItem = [];
      integrationConfigData.data.integrationConfigs.edges.map((item) => {
        newItem[item.node.key] = item.node.value;        
      });
      form.setFieldsValue(newItem);
      setIntegrationConfig(newItem);
      setLoading(false);
    }
  }, [integrationConfigData]);

  useEffect(() => {
    if(selectedItem)
    {
        form.resetFields();
        console.log("selectedItem", selectedItem);        
        setLoading(true);
        integrationConfigData.refetch();
    }
  }, [selectedItem]);


  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };
  const onCancel = () => {
    setLoading(false);
    setModalVisible(false);
  }
  const onFinish = (values) => { 
    let bChange = false;
    for (const key in values) {
      if (values.hasOwnProperty(key)) {
        if(integrationConfigs[key] != values[key])
        {
          bChange = true;
          // 변경된 경우에만 업데이트 한다.
          createIntegrationConfig({
            variables: {
              projectId,
              integrationId: utils.getID(selectedItem.id),
              key: key,
              value: values[key],
            },
          });
        }
      }      
    }
    onCancel();
};

  return (
    <Modal
    open={modalVisible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      closable={false}
      forceRender
      destroyOnClose
      footer={null}
      
    >
       <Form
          name="basic"          
          form={form}
          {...layout}
          autoComplete="off"
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
        >
          {selectedItem?.keys ? 
           selectedItem?.keys.map(item => (
              <Form.Item
              key={item.key}
              label={item.label}
              name={item.key}
              rules={[{ required: true, message: `Please input your ${item.key}!` }]}
              
            >
                <Input />
            </Form.Item>
            )
          ) : (
            <div>Loading...</div>
          )}
            <Form.Item {...tailLayout} >
            <Button key="cancel" onClick={onCancel}>
                <FormattedMessage id="common.cancel" />
            </Button>&nbsp;&nbsp;
            
                <Button type="primary" 
                loading={loading} htmlType="submit">
                    <FormattedMessage id="common.save" />
                </Button>
            </Form.Item>
        </Form>
      </Modal>
  );
};

export default IntegrationConfig;
