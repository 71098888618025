import React from 'react';
import { Input, Button, message, Drawer, Form, Row, Col, Select, Grid } from 'antd';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import Utils from 'utils';
import momentTZ from 'moment-timezone';

import { CREATE_USER_MUTATION } from '../../../graphql/mutations/users';

const AddUser = (props) => {
  const { visible, onClose, refetch, contextState } = props;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { useBreakpoint } = Grid;
  const company_id = localStorage.getItem('company_id');

  const [createUser] = useMutation(CREATE_USER_MUTATION, {
    onCompleted(result) {
      if (result?.createUser?.user?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'createUser',
        });

        form.resetFields();
        refetch();
        onClose();
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'createUser',
        });
      }
    },
  });

  const onSave = () => {
    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'createUser',
      duration: 0,
    });

    const values = form.getFieldsValue();

    if (!values) {
      message.error({
        content: formatMessage({
          id: 'common.message.save.fail',
        }),
        key: 'createUser',
      });
      return;
    }

    createUser({
      variables: {
        email: values.user_id,
        name: values.user_name,
        state: 1,
        password: values.user_password,
        ncpId: company_id,
        timezone: values.timezone,
      },
    });
  };

  return (
    <Drawer
      title={<FormattedMessage id="common.add" />}
      placement="right"
      width={Utils.isTablet(useBreakpoint()) ? '80%' : 400}
      open={visible}
      closable={false}
      onClose={onClose}
      bodyStyle={{ paddingBottom: 80 }}
      // forceRender
      // destroyOnClose
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button size="small" onClick={onClose} style={{ marginRight: 8 }}>
            <CloseCircleOutlined className="mr-2" />
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button size="small" type="primary" onClick={() => form.submit()}>
            <SaveOutlined className="mr-2" />
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="user_id"
              label={<FormattedMessage id="settings.user.label.user_id" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="settings.user.rules.user_id" />
                  ),
                },
                {
                  type: 'email',
                  message: <FormattedMessage id="common.error.email" />,
                },
              ]}
            >
              <Input
                size="small"
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'settings.user.placeholder.user_id',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="user_name"
              label={<FormattedMessage id="settings.user.label.user_name" />}
              rules={[
                {
                  required: true,
                  pattern: contextState?.name_regex,
                  message: (
                    <FormattedMessage id="settings.user.rules.user_name" />
                  ),
                },
              ]}
            >
              <Input
                size="small"
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'settings.user.placeholder.user_name',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="user_password"
              label={
                <FormattedMessage id="settings.user.label.user_password" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="settings.user.rules.user_password" />
                  ),
                },
                {
                  pattern: Utils.regPW,
                  message: <FormattedMessage id="common.error.password" />,
                },
              ]}
            >
              <Input.Password
                size="small"
                style={{ width: '100%' }}
                placeholder={formatMessage({
                  id: 'settings.user.placeholder.user_password',
                })}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="timezone"
              label={<FormattedMessage id="common.timezone" />}
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="common.timezone.placeholder" />
                  ),
                },
              ]}
              initialValue={momentTZ.tz.guess()}
            >
              <Select
                size="small"
                placeholder={
                  <FormattedMessage id="common.timezone.placeholder" />
                }
                showSearch
              >
                {Utils.getTimezoneList().map((item) => (
                  <Select.Option key={item.country} value={item.country}>
                    {item.desc}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Drawer>
  );
};

export default AddUser;
