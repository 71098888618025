import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import Add from './Add';
import DetailView from './DetailView';
import styles from './index.less';
import {
  Table,
  Tooltip,
  message,
  Button,
  Input,
  Select,
  Badge,
  Image,
  DatePicker,
  Form,
  Grid,
  Popconfirm,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  AndroidOutlined,
  SearchOutlined,
  PlusCircleOutlined,
  FileExcelOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import utils from 'utils';
import { PUSHS_QUERY } from '../../../../graphql/query/push';
const { Option } = Select;
const { RangePicker } = DatePicker;
const oneMonthAgo = moment().subtract(90, 'days').startOf('day').format();
const today = moment().endOf('day').format();

const searchData = [
  { name: <FormattedMessage id="message.push.message" />, id: 'message' },
];


const initEditData = {
  title: '',
  content: '',
  send_type: 'schedule',
  date: moment(new Date(), 'YYYY-MM-DD'),
  time: moment(new Date(), 'HH:mm'),
  link: '',
  channel: 'default',
  timezone: 'LOCALTIME',
};

const PushView = (props) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  const { current_project } = props;
  const { useBreakpoint }  = Grid;
  // States
  const [messages, setMessages] = useState();
  const [mode, setMode] = useState("add");
  const [language, setLanguage] = useState([]);
  const [addVisible, setAddVisible] = useState(false);
  const [modalEditData, setModalEditData] = useState(initEditData);
  const [detailVisible, setDetailVisible] = useState(false);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });

  const statusMap = ['default', 'success', 'error', 'processing', 'sending'];
  const status = [
    formatMessage({ id: 'message.push.default' }),
    formatMessage({ id: 'message.push.success' }),
    formatMessage({ id: 'message.push.error' }),
    formatMessage({ id: 'message.push.processing' }),
    formatMessage({ id: 'message.push.sending' }),
  ];

  const POLLING_TIME = 10000;

  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: oneMonthAgo,
    endDate: today,
  });
  const [sort, setSort] = useState({
    sort_id: undefined,
    sort: undefined,
  });

  // Queries
  const getData = useQuery(PUSHS_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({
        [search.search]: search.query,
        // startDate: search.startDate,
        // endDate: search.endDate,
      }),
      sort: JSON.stringify({
        [sort.sort_id || 'created_at']: sort.sort === 'asc' ? 1 : -1,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (getData?.data?.pushs) {
      let pushs = getData.data.pushs;
      setMessages(pushs.edges);
      setPagination({ ...pagination, total: pushs.totalCount });
      if(current_project?.language)
        setLanguage(current_project?.language.split(","));
    }
  }, [getData]);

  const onDetail = (data) => {
    setAddVisible(false);
    setDetailVisible(true);
    setModalEditData(data.node);
  }
  const onEdit = (data) => { 
    setMode('edit');
    setModalEditData(data.node);
    setAddVisible(true);
  }
  const getTarget = (target) => {
    if (Array.isArray(target)) {
      if (target.length > 0 && target[0].length > 0) {
        return formatMessage({ id: 'message.push.target.user' });
      }
      return formatMessage({ id: 'message.push.target.all' });
    }

    return formatMessage({ id: 'message.push.target.all' });
  };

  const getDefaultMsg = (textArray) => {
    if (textArray === null || textArray === undefined) {
      return undefined;
    }

    if (textArray.length <= 0) {
      return undefined;
    }

    for (let index = 0; index < textArray.length; index++) {
      if (textArray[index].default === true) {
        return textArray[index].value;
      }
    }

    return utils.isEmpty(textArray[0].value) ? '-' : textArray[0].value;
  };
  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSort({
        sort_id: sorter.columnKey,
        sort: sorter.order === 'ascend' ? 'asc' : 'desc',
      });
    } else {
      setSort({
        sort_id: undefined,
        sort: undefined,
      });
    }

    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    if (
      values?.search === 'content' &&
      values?.query &&
      values?.query?.length < 3
    ) {
      message.error({
        content: formatMessage({
          id: 'search.range.message.error2',
        }),
      });
      return;
    }

    let startDate = undefined;
    let endDate = undefined;
    if(values.range !== undefined && values.range)
    {
      startDate = values?.range[0];
      endDate = values?.range[1];
    }
    

    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
      startDate,
      endDate,
    });
  };


  const tableColumns = [
    {
      title: formatMessage({ id: 'message.push.status' }),
      dataIndex: 'status',
      width: 80,
      render: (text, record) => {
        if (record.node.status === 2 || record.node.status === 1) {
          const time = moment(record.node.scheduleAt);
          const current = moment();
          let sending = false;
          if (record.node.send_type === 'schedule_global') {
            if (time.diff(current) < 10 * 60 * 1000) {
              if (record.node.status === 1) {
                // console.log("ddddd")
                sending = true;
              }
            }
          }

          // console.log(sending)

          return (
            <a onClick={() => onDetail(record)}>
              <Badge status={statusMap[record.node.status]} text={sending ? (
              <FormattedMessage id="message.push.sent" />
              ) : record.node.status === 1 && record.node.send_type === 'schedule_global' ? (
                formatMessage({ id: 'message.push.sending' })
              ) : (
               status[record.node.status]
              )}/>
             
            </a>
          );
        }
        return <Badge status={statusMap[record.node.status]} text={status[record.node.status]}/>;
      },
    },
    {
      title: formatMessage({ id: 'message.push.target' }),
      dataIndex: 'target',
      width: 100,
      render: (text, record) => <div>{getTarget(record.node.target)}</div>,
    },
    
    {
      title: formatMessage({ id: 'message.push.channel' }),
      dataIndex: 'channel',
      // width: 80,
      render: (text, record) => {
        if (record.node.channel === 'default') {
          return (
            <>
            <Image
              width={20}
              height={20}
              preview={false}
              src={utils.getPlatformIcon('android')}
            />
            <Image
              width={20}
              height={20}
              preview={false}
              src={utils.getPlatformIcon("ios")}
            />
            </>
          );
        }
        return (
          <Image
            width={20}
            height={20}
            preview={false}
            src={utils.getPlatformIcon(record.node.channel)}
          />
        );
      },
    },
    {
      title: formatMessage({ id: 'message.push.schduletype' }),
      dataIndex: 'schedule_type',
      // width: 80,
      render: (text, record) => {
        if (record.node.send_type === 'direct') {
          return <FormattedMessage id="message.push.schduletype.direct" />;
        }
        if (record.node.send_type === 'schedule') {
          return <FormattedMessage id="message.push.schduletype.schedule" />;
        }
        if (record.node.send_type === 'schedule_global') {
          return <FormattedMessage id="message.push.schduletype.schedule_global" />;
        }
        return '';
      },
    },
    {
      title: formatMessage({ id: 'message.push.message' }),
      dataIndex: 'message.value',
      search: true,
      render: (text, record) => {
        if (record.node.send_type === 'schedule_global') {
          // console.log(record);
          if (record.node.status === 0 || record.node.status === 2) {
            return (
              <a onClick={() => onEdit(record)} style={{ width: 400 }}>
                <LinesEllipsis
                  style={{ whiteSpace: 'pre-wrap' }}
                  text={getDefaultMsg(record.node.message)}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </a>
            );
          }

          const time = moment(record.node.scheduleAt);
          const current = moment();
          // console.log(time.format("YYYY-MM-DD hh:mm:ss"));
          // console.log(current.format("YYYY-MM-DD hh:mm:ss"));
          // console.log(time.diff(current));

          if (time.diff(current) > 10 * 60 * 1000) {
            return (
              <a onClick={() => onEdit(record)} style={{ width: 400 }}>
                <LinesEllipsis
                  style={{ whiteSpace: 'pre-wrap' }}
                  text={getDefaultMsg(record.node.message)}
                  maxLine="1"
                  ellipsis="..."
                  trimRight
                  basedOn="letters"
                />
              </a>
            );
          }

          return (
            <div style={{ width: 400 }}>
              <LinesEllipsis
                style={{ whiteSpace: 'pre-wrap' }}
                text={getDefaultMsg(record.node.message)}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </div>
          );
        }
        if (record.node.status === 0 || record.node.status === 2) {
          return (
            <a onClick={() => onEdit(record)} style={{ width: 400 }}>
              <LinesEllipsis
                style={{ whiteSpace: 'pre-wrap' }}
                text={getDefaultMsg(record.node.message)}
                maxLine="1"
                ellipsis="..."
                trimRight
                basedOn="letters"
              />
            </a>
          );
        }
        return (
          <div style={{ width: 400 }}>
            <LinesEllipsis
              style={{ whiteSpace: 'pre-wrap' }}
              text={getDefaultMsg(record.node.message)}
              maxLine="1"
              ellipsis="..."
              trimRight
              basedOn="letters"
            />
          </div>
        );
      },
    },
    {
      title: formatMessage({ id: 'message.push.scheduleat' }),
      dataIndex: 'scheduleAt',
      // width: 220,
      render: (text, record) => {
        if (record.node.scheduleAt !== null) {
          return <div>{utils.getDate(record.node.scheduleAt)}</div>;
        }

        return '';
      },
    },
    {
      title: formatMessage({ id: 'message.push.completeat' }),
      dataIndex: 'completedAt',
      // width: 220,
      render: (text, record) => (
        <div>{record.node.completedAt !== null ? utils.getDate(record.node.completedAt) : ''}</div>
      ),
    },
  ];

  return (
    <div>
      <Form
        form={form}
        className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile' : ''}`}
        layout="inline"
        onFinish={onSearch}
        initialValues={{
          search: 'message',
          period: [moment(search.startDate), moment(search.endDate)],
        }}
      >
        <div>
          <Form.Item name="period">
            <RangePicker allowClear={false} showTime size="small" locale />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="search">
            <Select
              className="w-100"
              style={{ minWidth: 150 }}
              placeholder="Category"
              size="small"
            >
              {searchData.map((elm) => (
                <Option key={elm.id} value={elm.id}>
                  {elm.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item name="query">
            <Input placeholder="Search" size="small" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="button_submit">
            <Button
              type="primary"
              icon={<SearchOutlined className="mr-2" />}
              block
              htmlType="submit"
              size="small"
              loading={getData?.loading}
            >
              <FormattedMessage id="common.search" />
            </Button>
          </Form.Item>
        </div>
        <div style={{ marginLeft: 'auto' }}>
            <Form.Item name="button_add" style={{ marginRight: 0 }}>
              <Button
                className={`${utils.isTablet(useBreakpoint()) ? 'mt-1 mb-1' : ''}`}
                type="primary"
                icon={<PlusCircleOutlined className="mr-2" />}
                block
                onClick={() => {
                  setMode('add');
                  setModalEditData(initEditData);
                  setAddVisible(true);
                }}
                size="small"
              >
                <FormattedMessage id="common.add2" />
              </Button>
            </Form.Item>
          </div>
      </Form>
      <div className="table-responsive">
        <Table
          scroll={{ x: 1000 }}
          style={{ marginTop: 10 }}
          loading={getData?.loading}
          pagination={pagination}
          onChange={handleTableChange}
          columns={tableColumns}
          dataSource={messages}
          rowKey={(record) => record.node.message_id}
          size="small"
          footer={() => {
            return (
              <div>
                <FormattedMessage id="common.total.1" />
                {pagination?.total ? formatNumber(pagination.total) : 0}
                <FormattedMessage id="common.total.2" />
              </div>
            );
          }}
        />
        
      </div>
      <Add
        projectId={params.projectId}
        visible={addVisible}
        language={language}
        mode={mode}
        data={modalEditData}
        close={() => setAddVisible(false)}
        refetch_pushs={getData.refetch}
      />
       <DetailView
          visible={detailVisible}
          data={modalEditData}
          close={() => setDetailVisible(false)}
          data={modalEditData}
        />
    </div>
  );
};


export default PushView;
