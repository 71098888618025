import gql from 'graphql-tag';

const CREATE_LOG_EXPORTER = gql`
  mutation createExporter(
    $projectId: String!
    $type: String!
    $value1: String!
    $user_id: String!
    $value2: String
    $startDate: String
    $endDate: String
  ) {
    createExporter(
      input: {
        projectId: $projectId
        type: $type
        value1: $value1
        user_id: $user_id
        value2: $value2
        startDate: $startDate
        endDate: $endDate
      }
    ) {
      exporter {
        id
        project_id
        type
        filelink
        startdate
        enddate
        value1
        value2
        proc_startdate
        proc_enddate
        created_at
        updated_at
        user_id
        expired_at
      }
    }
  }
`;

export { CREATE_LOG_EXPORTER };
