import React from 'react';
import {BrowserRouter}  from "react-router-dom";
import {Route, Routes, Outlet} from 'react-router-dom';
import Views from './views';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
            <Routes>
              <Route exact path="*" element={<Views/>}/>
            </Routes>
       </BrowserRouter>
    </div>
  );
}

export default App;
