import gql from 'graphql-tag';

const UPDATE_PROFILE_MUTATION = gql`
  mutation updateUser(
    $id: ID!
    $name: String
    $state: Int
    $oldPassword: String
    $newPassword: String
    $language: String
    $profile: String
    $timezone: String
    $auto_translate: Boolean
    $auto_detect: Boolean
  ) {
    updateUser(
      input: {
        id: $id
        name: $name
        state: $state
        oldPassword: $oldPassword
        newPassword: $newPassword
        language: $language
        profile: $profile
        timezone: $timezone
        auto_translate: $auto_translate
        auto_detect: $auto_detect
      }
    ) {
      user {
        id
        ncp_project_id
        local {
          email
          password
          name
          phone
        }
        profile
        retries
        ip
        state
        logined_at
        admin
        manager
        fcm_token
        push
        timezone
        deleted
        deleted_at
      }
    }
  }
`;

export { UPDATE_PROFILE_MUTATION };
