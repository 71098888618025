import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
  Table,
  Tooltip,
  message,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Space,
  Grid,
  Modal,
  Badge,
  Image,
  Popconfirm,
} from 'antd';
import {
  EyeOutlined,
  BlockOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
  ExclamationCircleOutlined,
  SearchOutlined,
  ZoomInOutlined,
  DownloadOutlined,
  FileExcelOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import CopyText from 'components/shared-components/CopyText';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import utils from 'utils';

import { ARCHIVES_QUERY } from '../../../graphql/query/archives';
import { UPDATE_ARCHIVES_MUTATION } from '../../../graphql/mutations/archives';
const { Option } = Select;
const { RangePicker } = DatePicker;

const oneMonthAgo = moment().subtract(29, 'days').startOf('day').format();
const today = moment().endOf('day').format();

const searchData = [
  { name: <FormattedMessage id="archive.columns.sender.id" />, 
  id: 'user_id' },
  {
    name: <FormattedMessage id="archive.columns.sender.name" />,
    id: 'sender.name',
  },
  {
    name: <FormattedMessage id="archive.columns.filename" />,
    id: 'original',
  },
  {
    name: <FormattedMessage id="archive.columns.format" />,
    id: 'format',
  },
  {
    name: <FormattedMessage id="archive.columns.channel_id" />,
    id: 'channel_id',
  },
];

const View = ({ match, current_profile }) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  const { useBreakpoint }  = Grid;
  
  const [archives, setArchive] = useState();
  const [userProfileVisible, setUserProfileVisible] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [selectedRowKeys, setSelectedRowKeys] = useState(null);
  const [selectedRows, setSelectedRows] = useState(null);

  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: oneMonthAgo,
    endDate: today,
  });
  const [sort, setSort] = useState({
    sort_id: undefined,
    sort: undefined,
  });

  // Queries
  const getData = useQuery(ARCHIVES_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({
        project_id: params.projectId,
        [search.search]: search.query,
      }),
      sort: JSON.stringify({
        [sort.sort_id || 'created_at']: sort.sort === 'asc' ? 1 : -1,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
    },
    fetchPolicy: 'network-only',
  });


  const [updateArchive] = useMutation(UPDATE_ARCHIVES_MUTATION, {
    onCompleted(result) {
      getData.refetch();
      if (result?.updateArchive?.archives?.length > 0) {
        message.success({
          content: formatMessage({
            id: 'common.message.block.success',
          }),
          key: 'updateArchive',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.block.fail',
          }),
          key: 'updateArchive',
        });
      }
    },
  });

  useEffect(() => {
    if (getData?.data?.archives) {
      let entries = getData.data.archives;

      setArchive(entries.edges);
      setPagination({ ...pagination, total: entries.totalCount });
    }
  }, [getData]);

  const onDownload = (id) => {

  };
  

  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSort({
        sort_id: sorter.columnKey,
        sort: sorter.order === 'ascend' ? 'asc' : 'desc',
      });
    } else {
      setSort({
        sort_id: undefined,
        sort: undefined,
      });
    }

    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    if (
      values?.search === 'content' &&
      values?.query &&
      values?.query?.length < 3
    ) {
      message.error({
        content: formatMessage({
          id: 'search.range.message.error2',
        }),
      });
      return;
    }

    let startDate = undefined;
    let endDate = undefined;

    if(values.range !== undefined && values.range)
    {
      startDate = values?.range[0];
      endDate = values?.range[1];
    }
    
    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
      startDate,
      endDate,
    });
  };

  const showUserProfile = (userInfo) => {
    setUserProfileVisible(true);
    setSelectedUser(userInfo);
  };


  const tableColumns = [
    {
      title: <FormattedMessage id="search.columns.sender" />,
      key: 'user_id',
      dataIndex: ['node', 'user_id'],
      sorter: true,
      render: (row) => (
        <div className="d-flex" style={{ maxWidth: '500px' }}>
          <AvatarStatus
            src={row?.profile}
            name={row?.name}
            subTitle={utils.getID(row?.id)}
            icon={<UserOutlined />}
            copyTitle
            copySubtitle
          />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="archive.columns.status" />,
      key: 'status',
      dataIndex: ['node', 'status'],
      className: 'td-break',
      render: (row) => {
        if (row === 1) {
          return (
            <div style={{ minWidth: '80px' }}>
            <div>
              <Badge status="success" /> &nbsp;
              <FormattedMessage id="member.block.status.1" />
            </div>
            </div>
          );
        }
        return (
          <div style={{ minWidth: '80px' }}>
          <div>
            <Badge status="error" />&nbsp;
            <FormattedMessage id="member.block.status.0" />
          </div>
          </div>
        );
        },
    },
    {
      title: <FormattedMessage id="archive.columns.channel_id" />,
      key: 'channel_id',
      dataIndex: ['node', 'channel_id'],
      className: 'td-break',
      render: (row) => (
        <div style={{ minWidth: '100px' }}>
          {row}
          <CopyText text={row} />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="archive.columns.preview" />,
      className: 'td-break',
      dataIndex: ['node', 'id'],
      key: 'id',
      render: (id) => (
        <center><Image        
        width={64}
        height={64}        
        src={`${process.env.REACT_APP_API_ENDPOINT_URL}/archive/${utils.getID(id)}?admin=true&w=64&h=64`}
        preview={{          
          src: `${process.env.REACT_APP_API_ENDPOINT_URL}/archive/${utils.getID(id)}?admin=true`,
        }}
        
      /></center>
      )
      
    },
    {
      title: <FormattedMessage id="archive.columns.filename" />,
      dataIndex: ['node', 'original'],
      key: 'original',
      className: 'td-break',
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '400px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
   
    {
      title: <FormattedMessage id="archive.columns.format" />,
      dataIndex: ['node', 'format'],
      key: 'format',
      className: 'td-break',
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '100px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="archive.columns.size" />,
      dataIndex: ['node', 'size'],
      key: 'size',
      className: 'td-break',
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '100px' }}
            text={utils.getFormatFileSize(utils.getMessage(row))}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="archive.columns.hit" />,
      dataIndex: ['node', 'hit'],
      key: 'hit',
      className: 'td-break text-center',
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '100px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="archive.columns.created_at" />,
      key: undefined,
      dataIndex: ['node', 'created_at'],
      render: (row) => (
        <span>{moment(row).format('YYYY-MM-DD HH:mm:ss')} </span>
      ),
    },
    {
      title: <FormattedMessage id="archive.columns.expired_at" />,
      key: undefined,
      dataIndex: ['node', 'expired_at'],
      render: (row) => (
        <span>{moment(row).format('YYYY-MM-DD HH:mm:ss')} </span>
      ),
    },
    // {
    //   title: '',
    //   dataIndex: 'actions',
    //   width: 90,
    //   render: (_, elm) => (
    //     <div className="text-right">
    //       <Tooltip title={<FormattedMessage id="archive.tooltip.download" />}>
    //         <Button
    //           type="primary"
    //           className="mr-2"
    //           icon={<DownloadOutlined />}
    //           onClick={() => {
    //             onDownload(elm.node);
    //           }}
    //           size="small"
    //         />
    //       </Tooltip>
    //       <Tooltip title={<FormattedMessage id="archive.tooltip.delete" />}>
    //         <Popconfirm
    //           placement="bottomRight"
    //           title={<FormattedMessage id="common.message.delete.confirm" />}
    //           onConfirm={() => onDelete(elm?.node?.id)}
    //           okText={<FormattedMessage id="common.ok" />}
    //           cancelText={<FormattedMessage id="common.no" />}
    //         >
    //           <Button danger icon={<DeleteOutlined />} size="small" />
    //         </Popconfirm>
    //       </Tooltip>
    //     </div>
    //   ),
    // },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedRowKeys, selectedRows) => {
      const selectedRowsArray = selectedRows.map((item) => item.node);
      setSelectedRowKeys(selectedRowKeys);
      setSelectedRows(selectedRowsArray);
    },
    getCheckboxProps: (record) => ({
      disabled: record?.node?.state === 2,
    }),
  };

  const onClickBlock = () => {
    Modal.confirm({
      title: `${formatMessage({
        id: 'common.message.confirm',
      })}`,
      icon: <QuestionCircleOutlined />,
      content: `${formatMessage({
        id: 'archive.block.confirm',
      })}`,
      okText: 'OK',
      onOk() {
        message.loading({
          content: formatMessage({
            id: 'common.message.blocking',
          }),
          key: 'blockArchives',
          duration: 0,
        });
    
        const variables = {
          projectId: params.projectId,
          ids: selectedRowKeys,
          fields: JSON.stringify({'status':2}),
        };
    
        updateArchive({ variables });
        
      },
      cancelText: 'Canel',
    });
  };

  return (
    <div>
      <Form
        form={form}
        className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile' : ''}`}
        layout="inline"
        onFinish={onSearch}
        initialValues={{
          search: 'user_id',
          period: [moment(search.startDate), moment(search.endDate)],
        }}
      >
        <div>
          <Form.Item name="period">
            <RangePicker allowClear={false} showTime size="small" locale />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="search">
            <Select
              className="w-100"
              style={{ minWidth: 150 }}
              placeholder="Category"
              size="small"
            >
              {searchData.map((elm) => (
                <Option key={elm.id} value={elm.id}>
                  {elm.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item name="query">
            <Input placeholder="Search" size="small" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="button_submit">
            <Button
              type="primary"
              icon={<SearchOutlined className="mr-2" />}
              block
              htmlType="submit"
              size="small"
              loading={getData?.loading}
            >
              <FormattedMessage id="common.search" />
            </Button>
          </Form.Item>
        </div>
        <div>
              <Form.Item>
              
                <Button
              type="primary"
              danger
              icon={<BlockOutlined  className="mr-2" />}
              block
              size="small"
              disabled={utils.isEmpty(selectedRowKeys)}
              loading={getData?.loading}
              onClick={onClickBlock}
            >
                <FormattedMessage id="common.block" />
               </Button>
                </Form.Item>
          </div>
      </Form>
      <div className="table-responsive">
        <Table
          scroll={{ x: 1000 }}
          style={{ marginTop: 10 }}
          loading={getData?.loading}
          pagination={pagination}
          onChange={handleTableChange}
          rowSelection={{
            type: 'checkbox',
            ...rowSelection,
          }}
          columns={tableColumns}
          dataSource={archives}
          rowKey={(record) => record.node.id}
          size="small"
          footer={() => {
            return (
              <div>
                <FormattedMessage id="common.total.1" />
                {pagination?.total ? formatNumber(pagination.total) : 0}
                <FormattedMessage id="common.total.2" />
              </div>
            );
          }}
        />
        
      </div>
    </div>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(View);
