import React, { useState, useEffect } from 'react';
import { Modal, Checkbox, Button, Row, Col } from 'antd';
import { WarningOutlined } from '@ant-design/icons';
import { FormattedMessage } from 'react-intl';
import utils from 'utils';

export const IEModal = () => {
  const [isIE, setIsIE] = useState(false);
  const [isienevershow, setIsienevershow] = useState(
    localStorage.getItem('cloudchat-ienevershow') == null
      ? false
      : localStorage.getItem('cloudchat-ienevershow') === 'true'
  );

  useEffect(() => {
    setIsIE(utils.checkInternetExpoler(navigator) && isienevershow === false);
  }, []);

  return (
    <Modal
      open={isIE}
      closable={false}
      footer={[
        <Checkbox
          key="check"
          style={{ float: 'left' }}
          onChange={(e) => setIsienevershow(e.target.checked)}
        >
          <FormattedMessage id="ie.dontshow" />
        </Checkbox>,
        <Button
          key="ok"
          type="primary"
          size="small"
          onClick={() => {
            localStorage.setItem('cloudchat-ienevershow', isienevershow);

            setIsIE(false);
          }}
        >
          <FormattedMessage id="common.ok2" />
        </Button>,
      ]}
    >
      <Row align="middle">
        <Col xs={24} sm={5} md={5} lg={5}>
          <WarningOutlined style={{ color: '#fac70d', fontSize: 90 }} />
        </Col>
        <Col xs={24} sm={19} md={19} lg={19}>
          <div style={{ marginLeft: 10, marginRight: 10 }}>
            <span style={{ color: '#f74d4f', fontWeight: 'bold' }}>
              <FormattedMessage id="ie.des0" />
            </span>
            <FormattedMessage id="ie.des1" />
            <br />
            <span style={{ color: '#187ed6', fontWeight: 'bold' }}>
              <FormattedMessage id="ie.des2" />
            </span>
          </div>
        </Col>
      </Row>
    </Modal>
  );
};

export default IEModal;
