import React, { useEffect, useState } from 'react';
import { Form, Button, Row, Radio, Col, Modal,  message, Alert, Space, Input, Tooltip,Typography,InputNumber} from 'antd';
import { SaveOutlined, QuestionCircleOutlined , EditOutlined, ExclamationCircleOutlined, EllipsisOutlined, SettingOutlined} from '@ant-design/icons';
import { Card, List } from 'antd';
const { Paragraph, Text } = Typography;
import { useQuery, useMutation } from '@apollo/client';
import { ROW_GUTTER } from 'constants/ThemeConstant';
import { FormattedMessage, useIntl } from 'react-intl';
import KeyGroup from './KeyGroup';
import { useParams } from 'react-router-dom';
import styles from './Settings.less';
import utils from 'utils';
import { INTEGRATION_QUERY, INTEGRATIONCONFIG_QUERY} from '../../../graphql/query/integration';
import { CREATE_INTEGRATION_CONFIG_MUTATION } from '../../../graphql/mutations/integration';
import IntegrationConfig from './IntegrationConfig';

const Integration = ({ match }) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const [loading, setLoading] = useState(false);
  const [ellipsis, setEllipsis] = useState(true);
  const [integrations, setIntegration] = useState([]);
  const [selectedItem, setSelectedItem] = useState(null);

  const [keyvalues, setKeyValues] = useState([]);
  const [ modalVisible, setModalVisible ] = useState(false);
  const params = useParams();

  const integrationData = useQuery(INTEGRATION_QUERY, {
    variables:{	
      "filter":JSON.stringify({status:1}),
      "sort" : JSON.stringify({createdAt:1}),
      "option": JSON.stringify({offset:0,per_page:10})
    },
    fetchPolicy: 'network-only',
  });


  useEffect(() => {
    if (integrationData.data && integrationData.data) {
      setIntegration(integrationData.data.integrations.edges);
    }
  }, [integrationData]);

 

  const onView = (node) => {   
    setLoading(true);
    setSelectedItem(node);
    setModalVisible(true);
    
  }
 
  return (
    <div className="mt-4">
      <div>
        <div className={styles.cardList}>
          <List
            rowKey="id"            
            grid={{ gutter: 24, lg: 3, md: 2, sm: 1, xs: 1 }}
            dataSource={integrations}
            renderItem={item =>
              item ? (
                <List.Item key={item.node.id}  >
                  <Card hoverable className={styles.card}  actions={[
                      <SettingOutlined key="setting" onClick={() => onView(item.node)}/>,
                    ]}>
                    <Card.Meta
                      avatar={<img alt="" className={styles.cardAvatar} src={item.node.image} width="32"/>}
                      title={<a>{item.node.name}</a>}
                      description={
                        <div className={styles.item} lines={3}>
                          <Text
        style={ellipsis ? { width: 400 } : undefined}
        ellipsis={ellipsis ? { tooltip: item.node.description} : false}
      >
                          {item.node.description}
                          </Text>
                        </div>
                      }
                    />
                  </Card>
                </List.Item>
              ) : (
                <div></div>
              )
            }
          />
          <IntegrationConfig 
            projectId={params.projectId}
            modalVisible={modalVisible}
            selectedItem={selectedItem}
            setModalVisible={(result) => setModalVisible(result)}
            />
        </div>
      </div>
      
    </div>
  );
};

export default Integration;
