import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import {
  Table,
  Select,
  Input,
  Button,
  Badge,
  Menu,
  message,
  Grid,
  Form,
  Modal,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';

import CopyText from 'components/shared-components/CopyText';
import EllipsisDropdown from 'components/shared-components/EllipsisDropdown';
import Flex from 'components/shared-components/Flex';
import AddUser from './AddUser';
import EditUser from './EditUser';
import utils from 'utils';
import { useParams } from 'react-router-dom';

import { USERS_QUERY } from '../../../graphql/query/users';
import { DELETE_USERS_MUTATION } from '../../../graphql/mutations/users';
// import { ADMINS_QUERY } from '../../../graphql/query/admins';

const { Option } = Select;
const { confirm } = Modal;

const searchData = [
  {
    name: <FormattedMessage id="settings.user.local.email" />,
    id: 'local.email',
  },
  {
    name: <FormattedMessage id="settings.user.local.name" />,
    id: 'local.name',
  },
];

const ProductList = (props) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const { current_project, current_profile } = props;

  const { useBreakpoint } = Grid;
  const currentLoginUserId = current_profile?.local?.email;

  const company_id = localStorage.getItem('company_id');

  const [visibleAdd, setVisibleAdd] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
  });
  const params = useParams();

  const usersData = useQuery(USERS_QUERY, {
    variables: {
      projectId: params.projectId,
      sort: JSON.stringify({ createdAt: -1 }),
      filter: JSON.stringify({
        project_id: params.projectId,
        [search.search]: search.query,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
      // ncpId: company_id,
      // per_page: pagination.pageSize,
      // offset: (pagination.current - 1) * 20,
      // search: search.search,
      // query: search.query,
    },
    fetchPolicy: 'network-only',
  });

  // const adminsData = useQuery(ADMINS_QUERY, {
  //   variables: {
  //     ncpId: company_id,
  //     filter: JSON.stringify({
  //       project_id: params.projectId,
  //       deleted: false,
  //     }),
  //   },
  // });

  useEffect(() => {
    if (usersData?.data?.users) {
      setPagination({
        ...pagination,
        total: usersData?.data?.users?.totalCount,
      });
    }
  }, [usersData]);

  // useEffect(() => {
  //   if (adminsData?.data?.admins?.edges) {
  //     if (adminsData?.data?.admins?.edges.length > 0) {
  //       setSAdminUser(
  //         adminsData?.data?.admins?.edges[0]?.node?.ncp_admin_account
  //       );
  //     }
  //   }
  // }, [adminsData]);

  const [deleteUser] = useMutation(DELETE_USERS_MUTATION, {
    onCompleted(result) {
      if (
        result?.deleteUser?.error_user &&
        result?.deleteUser?.error_user.length < 1
      ) {
        message.success({
          content: formatMessage({
            id: 'common.message.delete.success',
          }),
          key: 'deleteUser',
        });
        usersData.refetch();
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.delete.fail',
          }),
          key: 'deleteUser',
        });
      }
    },
  });

  // Functions
  const dropdownMenu = (row) => (
    <Menu>
      <Menu.Item key="user_menu_1" onClick={() => openDetails(row)}>
        <Flex alignItems="center">
          <EyeOutlined />
          <span className="ml-2">
            <FormattedMessage id="settings.user.menu1" />
          </span>
        </Flex>
      </Menu.Item>
      {isCurrentLoginUser(row) === false &&
      isAdmin(row) === false ? (
        <Menu.Item key="user_menu_2" onClick={() => deleteRow(row)}>
          <Flex alignItems="center">
            <DeleteOutlined />
            <span className="ml-2">
              <FormattedMessage id="settings.user.menu2" />
            </span>
          </Flex>
        </Menu.Item>
      ) : (
        ''
      )}
    </Menu>
  );

  const handleTableChange = (pagination, filters, sorter) => {
    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query || undefined,
      search: values.query ? values.search : undefined,
    });
  };

  const deleteRow = (row) => {
    confirm({
      title: formatMessage({ id: 'settings.user.delete.confirm.title' }),
      content: formatMessage({ id: 'settings.user.delete.confirm.content' }),
      okText: formatMessage({ id: 'common.delete' }),
      cancelText: formatMessage({ id: 'common.cancel' }),
      okType: 'danger',
      onOk() {
        ondelete(row);
      },
    });
  };

  const ondelete = (user) => {
    message.loading({
      content: formatMessage({
        id: 'common.message.deleting',
      }),
      key: 'deleteUser',
      duration: 0,
    });

    deleteUser({
      variables: {
        id: utils.getID(user.node.id),
        ncp_project_id: company_id,
      },
    });
  };

  const openUser = () => {
    setVisibleAdd(true);
  };

  const closeUser = () => {
    setVisibleAdd(false);
  };

  const openDetails = (row) => {
    setVisibleEdit(true);
    setSelectedUser(row);
  };

  const closeDetails = (row) => {
    setVisibleEdit(false);
    setSelectedUser(null);
  };

  const isCurrentLoginUser = (selectedUser) => {
    return currentLoginUserId === selectedUser?.node?.local?.email;
  };

  const isAdmin = (selectedUser) => {
    return selectedUser?.node?.admin;
  };

  const tableColumns = [
    {
      title: <FormattedMessage id="settings.user.state" />,
      dataIndex: 'node',
      className: 'td-nowrap',
      render: (record) => (
        <Flex alignItems="center">
          <Badge status={record.state === 1 ? 'success' : 'error'} /> &nbsp;
          <span>
            {record.state === 1 ? (
              <FormattedMessage id="settings.user.state.1" />
            ) : (
              <FormattedMessage id="settings.user.state.0" />
            )}
          </span>
        </Flex>
      ),
    },
    {
      title: <FormattedMessage id="settings.user.admin" />,
      dataIndex: 'node',
      className: 'td-nowrap',
      render: (record) => (
        <span>
          {record.admin === true ? (
            <FormattedMessage id="settings.user.admin.true" />
          ) : (
            <FormattedMessage id="settings.user.admin.false" />
          )}
        </span>
      ),
    },
    {
      title: <FormattedMessage id="settings.user.local.email" />,
      dataIndex: 'node',
      className: 'td-break',
      render: (record) => (
        <div style={{ minWidth: 200 }}>
          {record.local.email}
          <CopyText text={record.local.email} />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="settings.user.local.name" />,
      className: 'td-break',
      dataIndex: 'node',
      render: (record) => (
        <div style={{ minWidth: 200 }}>{record.local.name}</div>
      ),
    },
    {
      title: <FormattedMessage id="settings.user.logined_at" />,
      dataIndex: 'node',
      width: 160,
      render: (record) =>
        record.logined_at
          ? moment(record.logined_at).format('YYYY-MM-DD HH:mm:ss')
          : '-',
    },
    {
      title: '',
      dataIndex: 'actions',
      render: (_, elm) => (
        <div className="text-right">
          <EllipsisDropdown menu={dropdownMenu(elm)} />
        </div>
      ),
    },
  ];

  return (
    <>
      <Flex alignItems="center" justifyContent="between" mobileFlex={false}>
        <Form
          form={form}
          className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile w-100' : 'w-100'}`}
          layout="inline"
          onFinish={onSearch}
          initialValues={{ search: 'local.email' }}
        >
          <div>
            <Form.Item name="search">
              <Select
                className="w-100"
                style={{ minWidth: 100 }}
                placeholder="Category"
                size="small"
              >
                {searchData.map((elm) => (
                  <Option key={elm.id} value={elm.id}>
                    {elm.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
          </div>
          <div>
            <Form.Item name="query">
              <Input placeholder="Search" size="small" />
            </Form.Item>
          </div>
          <div>
            <Form.Item name="button_submit">
              <Button
                type="primary"
                icon={<SearchOutlined className="mr-2" />}
                block
                htmlType="submit"
                size="small"
              >
                <FormattedMessage id="common.search" />
              </Button>
            </Form.Item>
          </div>
          <div style={{ marginLeft: 'auto' }}>
            <Form.Item name="button_add" style={{ marginRight: 0 }}>
              <Button
                className={`${utils.isTablet(useBreakpoint()) ? 'mt-1 mb-1' : ''}`}
                type="primary"
                icon={<PlusCircleOutlined className="mr-2" />}
                block
                onClick={() => openUser()}
                size="small"
              >
                <FormattedMessage id="common.add" />
              </Button>
            </Form.Item>
          </div>
        </Form>
      </Flex>
      <div className="table-responsive">
        <Table
          // scroll={{ x: 'max-content' }}
          scroll={{ x: 1000 }}
          style={{ marginTop: 10 }}
          dataSource={usersData?.data?.users?.edges}
          columns={tableColumns}
          rowKey={(record) => record.node.id}
          pagination={pagination}
          onChange={handleTableChange}
          loading={usersData?.loading}
          size="small"
          footer={() => {
            return (
              <div>
                <FormattedMessage id="common.total.1" />
                {pagination?.total ? formatNumber(pagination.total) : 0}
                <FormattedMessage id="common.total.2" />
              </div>
            );
          }}
        />
      </div>
      <AddUser
        visible={visibleAdd}
        onClose={closeUser}
        refetch={usersData.refetch}
        contextState={{ ...current_profile, ...current_project }}
      />
      <EditUser
        visible={visibleEdit}
        onClose={closeDetails}
        userData={selectedUser}
        refetch={usersData.refetch}
        ondelete={ondelete}
        isDisable={isCurrentLoginUser(selectedUser)}
        contextState={{ ...current_profile, ...current_project }}
      />
    </>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_project, current_profile } = project;
  return { current_project, current_profile };
};

export default connect(mapStateToProps)(ProductList);
