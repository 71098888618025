const version = '1.0.0';

const isBeta = process.env.REACT_APP_BETA;

const consoleConfig = {
  sens: {
    ko: 'https://console.ncloud.com/sens/project',
  },
  papago: {
    ko: 'https://console.ncloud.com/papago-translation',
  },
};

const guideConfig = {
  dashboard: {
    pay: `https://www.ncloud.com/charge/region`,
    guide: {
      ko: 'https://guide.ncloud-docs.com/docs/bizapp-ncloudchat-dashboard',
      en: 'https://guide.ncloud-docs.com/docs/en/bizapp-ncloudchat-dashboard',
    },
  },
  papago: {
    guide: `https://www.ncloud.com/product/aiService/papagoTranslation`,
  },
  openapi: {
    ko: 'https://api.ncloud-docs.com/docs/bizapp-ncloudchat-overview',
    en: 'https://api.ncloud-docs.com/docs/en/bizapp-ncloudchat-overview',
  },
  aos: {
    ko: 'https://guide.ncloud-docs.com/docs/bizapp-ncloudchat-androidsdk',
    en: 'https://guide.ncloud-docs.com/docs/en/bizapp-ncloudchat-androidsdk',
  },
  ios: {
    ko: 'https://guide.ncloud-docs.com/docs/bizapp-ncloudchat-iossdk',
    en: 'https://guide.ncloud-docs.com/docs/en/bizapp-ncloudchat-iossdk',
  },
  javascript: {
    ko: 'https://guide.ncloud-docs.com/docs/bizapp-ncloudchat-javascriptsdk',
    en: 'https://guide.ncloud-docs.com/docs/en/bizapp-ncloudchat-javascriptsdk',
  },
};

if (isBeta === 'true') {
  // config
  consoleConfig.sens.ko = 'https://beta-console.ncloud.com/sens/project';
  consoleConfig.papago.ko =
    'https://beta-console.ncloud.com/papago-translation';

  // guide
  guideConfig.dashboard.pay = `https://beta-www.ncloud.com/charge/region`;
  guideConfig.dashboard.guide.ko = `https://guide.ncloud-docs.com/beta/docs/bizapp-ncloudchat-dashboard`;
  guideConfig.dashboard.guide.en = `https://guide.ncloud-docs.com/beta/docs/en/bizapp-ncloudchat-dashboard`;

  guideConfig.papago.guide = `https://beta-www.ncloud.com/product/aiService/papagoTranslation`;

  guideConfig.openapi.ko =
    'https://api.ncloud-docs.com/beta/docs/bizapp-ncloudchat-overview';
  guideConfig.openapi.en =
    'https://api.ncloud-docs.com/beta/docs/en/bizapp-ncloudchat-overview';
  guideConfig.aos.ko =
    'https://guide.ncloud-docs.com/beta/docs/bizapp-ncloudchat-androidsdk';
  guideConfig.aos.en =
    'https://guide.ncloud-docs.com/beta/docs/en/bizapp-ncloudchat-androidsdk';
  guideConfig.ios.ko =
    'https://guide.ncloud-docs.com/beta/docs/bizapp-ncloudchat-iossdk';
  guideConfig.ios.en =
    'https://guide.ncloud-docs.com/beta/docs/en/bizapp-ncloudchat-iossdk';
  guideConfig.javascript.ko =
    'https://guide.ncloud-docs.com/beta/docs/bizapp-ncloudchat-javascriptsdk';
  guideConfig.javascript.en =
    'https://guide.ncloud-docs.com/beta/docs/en/bizapp-ncloudchat-javascriptsdk';
}

export { version, consoleConfig, guideConfig };
