import {
  DashboardOutlined,
  UserOutlined,
  WechatOutlined,
  FileImageOutlined,
  SettingOutlined,
  MessageOutlined,
  BarChartOutlined,
  BookOutlined,
  ReadOutlined,
  FacebookOutlined,
  AuditOutlined,
  DownloadOutlined,
  SearchOutlined,
} from '@ant-design/icons';

const dashBoardNavTree = [
  {
    key: 'cloudchat',
    title: 'sidenav',
    submenu: [
      {
        key: 'dashboards',
        path: '/:companyId/:projectId/dashboards',
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'users',
        title: 'sidenav.member',
        path: '/:companyId/:projectId/user',
        icon: UserOutlined,
        submenu: [
          {
            key: 'users-list',
            path: '/:companyId/:projectId/user/list',
            title: 'sidenav.member.list',
            breadcrumb: false,
            submenu: [],
          },
          {
            key: 'users-block',
            path: '/:companyId/:projectId/user/block',
            title: 'sidenav.member.block',
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: 'chat',
        path: '/:companyId/:projectId/chat',
        title: 'sidenav.chat',
        subtitle: 'sidenav.subtitle.chats',
        icon: WechatOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'analytics',
        title: 'sidenav.analytics',
        path: '/:companyId/:projectId/analytics',
        icon: MessageOutlined,
        submenu: [
          {
            key: 'analutics-user',
            path: '/:companyId/:projectId/analytics/user',
            title: 'sidenav.analytics.user',
            subtitle: 'sidenav.subtitle.analytics.user',
            breadcrumb: true,
            submenu: [],
          },
          {
            key: 'analytics-ccu',
            path: '/:companyId/:projectId/analytics/ccu',
            title: 'sidenav.analytics.ccu',
            subtitle: 'sidenav.subtitle.analytics.ccu',
            breadcrumb: true,
            submenu: [],
          },
        ],
      },
      {
        key: 'searchs',
        path: '/:companyId/:projectId/search',
        title: 'sidenav.search',
        icon: SearchOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'messages',
        title: 'sidenav.message',
        path: '/:companyId/:projectId/message',
        icon: MessageOutlined,
        submenu: [
          {
            key: 'push',
            path: '/:companyId/:projectId/message/push',
            title: 'sidenav.message.push',
            breadcrumb: true,
            submenu: [],
          },
          // {
          //   key: 'sms',
          //   path: '/:companyId/:projectId/message/sms',
          //   title: 'sidenav.message.sms',
          //   breadcrumb: true,
          //   submenu: [],
          // },
        ],
      },
      {
        key: 'archive',
        path: '/:companyId/:projectId/archive',
        title: 'sidenav.archive',
        subtitle: 'sidenav.subtitle.archive',
        icon: FileImageOutlined,
        breadcrumb: true,
        submenu: [],
      },
      // {
      //   key: 'story',
      //   path: '/:companyId/:projectId/story',
      //   title: 'sidenav.story',
      //   subtitle: 'sidenav.subtitle.story',
      //   icon: BookOutlined,
      //   breadcrumb: true,
      //   submenu: [],
      // },
      // {
      //   key: 'friendship',
      //   path: '/:companyId/:projectId/friendship',
      //   title: 'sidenav.friendship',
      //   subtitle: 'sidenav.subtitle.friendship',
      //   icon: FacebookOutlined,
      //   breadcrumb: true,
      //   submenu: [],
      // },
      // {
      //   key: 'audit',
      //   path: '/:companyId/:projectId/audit',
      //   title: 'sidenav.audit',
      //   subtitle: 'sidenav.subtitle.audit',
      //   icon: AuditOutlined,
      //   breadcrumb: true,
      //   submenu: [],
      // },

      {
        key: 'settings',
        path: '/:companyId/:projectId/settings',
        title: 'sidenav.settings',
        subtitle: 'sidenav.subtitle.settings',
        icon: SettingOutlined,
        breadcrumb: true,
        submenu: [],
      },
      {
        key: 'jobmanagement',
        path: '/:companyId/:projectId/jobmanagement',
        title: 'sidenav.jobmanagement',
        subtitle: 'sidenav.subtitle.jobmanagement',
        icon: DownloadOutlined,
        breadcrumb: true,
        submenu: [],
      },

      {
        key: 'guide',
        path: '/:companyId/:projectId/guide',
        title: 'sidenav.guide',
        subtitle: 'sidenav.subtitle.guide',
        icon: ReadOutlined,
        breadcrumb: true,
        submenu: [],
      },
    ],
  },
];

export default [...dashBoardNavTree];
