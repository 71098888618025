import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import Utils from '../../../../utils';
import { Form, Input, Button, Col, Row, Select } from "antd";

import { SaveOutlined, CloseCircleOutlined, PlusCircleOutlined,MinusCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';

const AddComponent = (props) => {
  const { messages, language } = props;
  const { formatMessage } = useIntl();
  const [ formValues, setFormValues ] = useState([{default: false}]);
  useEffect(() => {
    if (messages) {
      setFormValues(messages);
    } else {
      setFormValues([{ default: false }]);
    }
  }, [messages]);
  const handleChange = (i, e) => {
    let newFormValues = [...formValues];
    newFormValues[i][e.target.name] = e.target.value;
    setFormValues(newFormValues);
    props.onChange(newFormValues);
   }

  const addFormFields = () => {
      if(formValues.length == language.length) return;
      setFormValues([...formValues, { default: false }])
 }

 const removeFormFields = (i) => {
      let newFormValues = [...formValues];
      newFormValues.splice(i, 1);
      setFormValues(newFormValues)
  }
  const setDataFields = (data, index) => {
    const newFormValues = formValues;
    newFormValues.splice(index,1, data);
    setFormValues(newFormValues);    
    props.onChange(newFormValues);
  }

  const handleSubmit = (event) => {
      event.preventDefault();
      alert(JSON.stringify(formValues));
  }

  const checkSelectedLang = (lang) => {
    
    let result = false;
    formValues.map((item) => {
      if (lang === item.lang) {
        result = true;
      }
    });

    return result;
  };

  return (
    <>
      {formValues.map((element, index) => (
        <div className="form-inline" key={index}>
        <Row
            style={
              formValues.length - 1 === index ? { marginBottom: 0 } : { marginBottom: 15 }
            }
            gutter={12}
          >
          <Col span={15}>
            <Input
                  name="value"
                  placeholder={formatMessage({ id: 'message.push.write.message.placeholder' })}
                  size="small"
                  value={
                    Utils.isEmpty(formValues[index]?.value)
                      ? undefined
                      : formValues[index].value
                  }
                  onChange={(e) => {
                    handleChange(index, e);
                  }}
                />
        </Col>
        <Col span={9}>
              <Row>
              <Col span={17}>
                <Select
                        placeholder={formatMessage({
                          id: 'message.push.write.message.lang.placeholder',
                        })}
                        size="small"
                        style={{ width: '100%', marginBottom: 0 }}
                        value={element.lang}
                        onChange={(value) => {
                          setDataFields({ ...formValues[index], lang: value }, index);
                        }}
                      >
                        {Array.isArray(language) && language.map((lang) => (
                          <Select.Option
                            key={lang}
                            value={lang}
                            disabled={checkSelectedLang(lang)}
                          >
                            <FormattedMessage id={`language.${lang}`} />
                          </Select.Option>
                        ))}
                      </Select>
                </Col>
                <Col style={{ marginTop: 2.5 }} span={7}>
                <a
                        style={{ marginLeft: 5 }}
                        onClick={() => {
                          addFormFields();
                        }}
                      >
                        <PlusCircleOutlined style={{ fontSize: 18 }} />
                      </a>
                {
                  index ? 
                  <a style={{ marginLeft: 5 }}
                      onClick={() => {
                        removeFormFields(index);
                      }}
                    >
                      <MinusCircleOutlined style={{ fontSize: 18, color: 'red' }} />
                    </a>
                  : null
                }
              </Col>
              </Row>
          </Col>
          </Row>
        </div>
      ))}
      </>
  );  
}

export default AddComponent;
