import React, { useEffect } from 'react';
import { message } from 'antd';
import { useNavigate , useParams} from 'react-router-dom';
import { useMutation } from '@apollo/client';
import queryString from 'query-string';
import { useIntl } from 'react-intl';
import Loading from 'components/shared-components/Loading';
import {
  COMPANY_ID,
  AUTH_TOKEN,
} from '../../../../redux/constants/Auth'
import { CREATE_PROJECT_FROM_MAIL_MUTATION } from '../../../../graphql/mutations/project';

const ProjectFromMail = (props) => {
  const { formatMessage } = useIntl();

  let navigate = useNavigate();
  const params = useParams();
  const queryObj = queryString.parse(window.location.search);
  const key = queryObj?.key;
  const projectName = queryObj?.projectName;

  const companyId = params?.companyId;
  localStorage.setItem(COMPANY_ID, companyId);
  useEffect(() => {
    localStorage.removeItem('auth_token');
    // localStorage.removeItem('company_id');
  }, []);

  const [createProjectFromMail] = useMutation(
    CREATE_PROJECT_FROM_MAIL_MUTATION,
    {
      onCompleted(result) {
        if (result?.createProjectFromMail?.project?.id) {
          message.success({
            content: formatMessage({ id: 'verify.project.message.success' }),
            key: 'createProjectFromMail',
          });
          navigate(
            `/${companyId}/verify/account?key=${key}&projectName=${projectName}`
          );
        } else {
          message.error({
            content: formatMessage({ id: 'verify.project.message.error' }),
            key: 'createProjectFromMail',
          });
          navigate(`/${companyId}/verify`);
        }
      },
      onError() {
        navigate(`/${companyId}/verify`);
      },
    }
  );

  useEffect(() => {
    if (!key || !projectName || !companyId) {
      navigate(`/${companyId}/verify`);
      return;
    }

    message.loading({
      content: formatMessage({ id: 'verify.project.message.updating' }),
      key: 'createProjectFromMail',
      duration: 0,
    });

    createProjectFromMail({
      variables: {
        name: projectName,
        push: 'sens',
        ncpid: companyId,
        tempkey: key,
      },
    });
  }, [key, projectName, companyId]);

  return <Loading cover="content" />;
};

export default ProjectFromMail;
