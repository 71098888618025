import { gql } from '@apollo/client';
const STORY = `
    id
    status
    title {
        lang
        value
        default
    }
    text {
        lang
        value
        default
    }
    url
    createdAt
    updatedAt
    category_id
    status
    required
    hit
`;


const STORYS_QUERY = gql`
  query stories(
    $projectId: String!
    $filter: String!
    $option: String
    $sort: String
  ) {
    stories(
      projectId: $projectId
      filter: $filter
      option: $option
      sort: $sort
    ) {
      totalCount
      edges {
        node {
          ${STORY}
        }
      }
    }
  }
`;

export { STORYS_QUERY };
