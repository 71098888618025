import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Grid, Drawer } from 'antd';
import utils from 'utils';
import { MenuOutlined } from '@ant-design/icons';

const SideContent = (props) => {
  const { sideContent, sideContentWidth = 250, border } = props;
  return (
    <div
      className={`side-content ${border ? 'with-border' : ''}`}
      style={{ width: `${sideContentWidth}px` }}
    >
      {sideContent}
    </div>
  );
};

const SideContentMobile = (props) => {
  const { sideContent, visible, onSideContentClose } = props;
  const { useBreakpoint } = Grid;
  
  return (
    <Drawer
      width={utils.isTablet(useBreakpoint()) ? '80%' : 320}
      placement="left"
      closable={false}
      onClose={onSideContentClose}
      open={visible}
      bodyStyle={{ padding: '0px' }}
    >
      <div className="h-100">{sideContent}</div>
    </Drawer>
  );
};

export const InnerAppLayout = (props) => {
  const { useBreakpoint } = Grid;
  
  const {
    mainContent,
    pageHeader,
    sideContentGutter = true,
    menuOpen,
    setMenuOpen,
  } = props;
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
  const [visible, setVisible] = useState(false);

  useEffect(() => {}, []);
  const close = (e) => {
    setVisible(false);
    if (menuOpen !== undefined) {
      setMenuOpen(false);
    }
  };

  const openSideContentMobile = () => {
    setVisible(true);
  };

  useEffect(() => {
    if (menuOpen !== undefined) {
      setVisible(menuOpen);
    }
  }, [menuOpen]);

  return (
    <div className="inner-app-layout">
      {isMobile ? (
        <SideContentMobile
          visible={visible}
          onSideContentClose={close}
          {...props}
        />
      ) : (
        <SideContent {...props} />
      )}
      <div
        className={`main-content ${pageHeader ? 'has-page-header' : ''} ${
          sideContentGutter ? 'gutter' : 'no-gutter'
        }`}
      >
        {isMobile && menuOpen === undefined ? (
          <div
            className={`font-size-lg mb-3 ${
              !sideContentGutter ? 'pt-3 px-3' : ''
            }`}
          >
            <MenuOutlined onClick={() => openSideContentMobile()} />
          </div>
        ) : null}
        {mainContent}
      </div>
    </div>
  );
};

InnerAppLayout.propTypes = {
  sideContent: PropTypes.node,
  mainContent: PropTypes.node,
  pageHeader: PropTypes.bool,
  sideContentWidth: PropTypes.number,
  border: PropTypes.bool,
  sideContentGutter: PropTypes.bool,
};

export default InnerAppLayout;
