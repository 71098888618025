import React, { useEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Drawer,
  Button,
  Input,
  message,
  Form,
  Col,
  Select,
  Row,
  TimePicker,
  DatePicker,
  Radio,
  Grid,
  Checkbox,
} from 'antd';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import utils from 'utils';

import { CREATE_SMS_MUTATION, UPDATE_SMS_MUTATION, DELETE_SMS_MUTATION} from '../../../../graphql/mutations/sms';
const options = [
  {
    label: <FormattedMessage id="member.list.block.form.label.period.1" />,
    value: '1',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.2" />,
    value: '7',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.3" />,
    value: '30',
  },
];


const { Option } = Select;
const RadioGroup = Radio.Group;

const AddView = (props) => {
  const { current_profile, visible, close, refetch_sms, projectId, mode, language, data } = props;
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [schedulerStatus, setSchedulerStatus] = useState('schedule');
  const [ sendType, setSendType] = useState('schedule');
  const [ sendDate, setSendDate] = useState(moment());
  const [ sendTime, setSendTime] = useState(moment());
  const [ channel, setChannel] = useState('all');
  const [ messages, setMessages] = useState([]);  
  const { useBreakpoint } = Grid;

  // Mutation
  const [createSms] = useMutation(CREATE_SMS_MUTATION, {
    onCompleted(result) {
      if (result?.createPush?.push?.id) {
        refetch_sms();
        onChildrenModalClose();

        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'createSms',
        });
      }
    },
    onError(error) {
      message.error({
        content:
          error.message ||
          formatMessage({
            id: 'common.message.save.fail',
          }),
        key: 'createSms',
      });
    },
  });  

  const [updateSms] = useMutation(UPDATE_SMS_MUTATION, {
    onCompleted(result) {
      if (result?.updateSms?.sms?.id) {
        refetch_sms();
        onChildrenModalClose();

        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateSms',
        });
      }
    },
    onError(error) {
      message.error({
        content:
          error.message ||
          formatMessage({
            id: 'common.message.save.fail',
          }),
        key: 'updateSms',
      });
    },
  }); 
  // UseEffect
  useEffect(() => {
      form.resetFields();
      setSchedulerStatus(data.send_type);      
      setMessages(data.message);
      setSendDate(data.scheduleAt !== undefined && data.scheduleAt !== null
        ? moment(new Date(data.scheduleAt), 'YYYY-MM-DD')
        : moment(new Date(), 'YYYY-MM-DD').add(10, 'minutes'));
      setSendTime(data.scheduleAt !== undefined && data.scheduleAt !== null
        ? moment(new Date(data.scheduleAt), 'HH:mm')
        : moment(new Date(), 'HH:mm').add(10, 'minutes'));
      data.defaultLang = getDefaultLang(data.message);
      if(Array.isArray(data.title))
        data.title = data.title[0].value;
      form.setFieldsValue(data);
    
  }, [visible]);


  const showErrorModal = (content) => {
    message.error(content || formatMessage({ id: 'message.push.error2' }));
  };
  const getDefaultLang = (textArray) => {
    if (textArray === null || textArray === undefined) {
      return undefined;
    }

    if (textArray.length <= 0) {
      return undefined;
    }

    for (let index = 0; index < textArray.length; index++) {
      if (textArray[index].default === true) {
        if (language.includes(textArray[index].lang)) {
          return textArray[index].lang;
        }
        return undefined;
      }
    }

    return undefined;
  };
  const setDefaultLang = (textArray, lang) => {
    if (
      textArray === null ||
      textArray === undefined ||
      textArray.length <= 0 ||
      lang === undefined
    ) {
      return undefined;
    }

    let isExist = false;

    for (let index = 0; index < textArray.length; index++) {
      if (textArray[index].lang === lang) {
        textArray[index].default = true;
        isExist = true;
      } else {
        textArray[index].default = false;
      }
    }

    if (isExist === false) {
      return undefined;
    }

    return textArray;
  };

  // function
  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        
        if (messages === undefined || messages === '' || messages.length < 1) {
          showErrorModal(formatMessage({ id: 'message.push.error1' }));
          return;          
        }
        setDefaultLang(messages, values.defaultLang);
        const reservDate = sendDate;
        reservDate.hour(sendTime.hour());
        reservDate.minute(sendTime.minute());
        reservDate.second(0);
    
        if (values.send_type !== 'direct' && reservDate.diff(moment()) < 0) {
          showErrorModal(formatMessage({ id: 'message.push.error3' }));
          return;
        }

        message.loading({
          content: formatMessage({
            id: 'common.message.updating',
          }),
          key: 'createSms',
          duration: 0,
        });
        let variables = {
          projectId: projectId,
          channel: values.channel,
          message: utils.omitDeep(messages, '__typename'),
          title: [{ lang: 'en', value: values.title, default: true }],
          sendType: values.send_type,
          msgType: 'text',
          target: [],
          data: '',
          scheduleAt:
          values.send_type === 'schedule' || values.send_type === 'schedule_global' ? reservDate : '',
          timezone: values.send_type === 'schedule_global' ? values.timezone : '',
        };
        if (mode === 'add') {
          createPush({ variables });
        }
        else if (mode === 'edit') {
          variables.id = utils.getID(data.id);
          updatePush({ variables });
        }

        return;
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const onChildrenModalClose = () => {        
    form.resetFields();
    close();
  };

  return (
    <Drawer
      title={
        mode === 'add'
            ? <FormattedMessage id="message.push.write.add" />
            : <FormattedMessage id="message.push.write.modify" />
      }
      open={visible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      bodyStyle={{ paddingBottom: 80 }}
      closable={false}
      onClose={onChildrenModalClose}
      forceRender
      destroyOnClose
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            size="small"
            onClick={onChildrenModalClose}
            style={{ marginRight: 8 }}
            icon={<CloseCircleOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="small"
            type="primary"
            onClick={() => form.submit()}
            icon={<SaveOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.save" />
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={onSave}
        initialValues={{          
        }}
      >
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="send_type"
              label={
                <FormattedMessage id="message.push.write.schedule" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="message.push.write.schedule.rules" />
                  ),
                },
              ]}
            >
              <Radio.Group
                value={schedulerStatus}
                onChange={(e) => setSchedulerStatus(e.target.value)}
              >
                <Radio  value="direct">
                  <FormattedMessage id="message.push.write.schedule.direct" />
                </Radio>
                <Radio  value="schedule">
                  <FormattedMessage id="message.push.write.schedule.schedule" />
                </Radio>
              </Radio.Group>
            </Form.Item>
          </Col>
        </Row>
        {schedulerStatus === 'schedule' ||
        schedulerStatus === 'schedule_global' ? (
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name= "date"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <DatePicker
                size="small"
                style={{ width: '110px', marginRight: '5px' }}
                format="YYYY-MM-DD"
                allowClear={false}
                value={sendDate}
                onChange={(date, dateString) => {
                  setSendDate(date);
                }}
              />
                <TimePicker
                    size="small"
                    style={{ width: '80px', marginRight: 5 }}
                    format="HH:mm"
                    allowEmpty={false}
                    value={sendTime}
                    onChange={(time, timeString) => {
                      setSendTime(time);
                    }}
                    allowClear={false}
                />
                <Checkbox
                  disabled={mode === 'edit'}
                  value={sendType}
                  onChange={(e) => {
                    const { checked } = e.target;
                    setSendType(checked ? "schedule_global" : "schedule");
                  }}
                >
                   <FormattedMessage id="message.push.write.schedule.localtime" />
                </Checkbox>
            </Form.Item>
          </Col>
        </Row>
        ) : null}
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="channel"
              label={
                  <FormattedMessage id="message.push.write.platform" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="message.push.write.platform.rules" />
                  ),
                },
              ]}
            >
              <RadioGroup
                    size="small"
                    value={
                      channel !== 'default' && channel !== 'ios'
                        ? 'android'
                        : channel
                    }
                    onChange={(e) => {        
                      setChannel(e.target.value);
                    }}
                  >
                    <Radio value="default">
                      <FormattedMessage id="message.push.write.platform.all" />
                    </Radio>
                    <Radio value="android">
                      <FormattedMessage id="message.push.write.platform.android" />
                    </Radio>
                    <Radio value="ios">
                      <FormattedMessage id="message.push.write.platform.ios" />
                    </Radio>
                  </RadioGroup>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="defaultLang"
              label={
                  <FormattedMessage id="message.push.write.basiclang" />
              }
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="message.push.write.basiclang.rules" />
                  ),
                },
              ]}
            >
             <Select
              style={{ width: 247 }}
              size="small"
              placeholder={formatMessage({id: 'maintenance.check.basiclang.placeholder'})}
            >
              {Array.isArray(language) && language &&
                language.map((item) => (
                  <Option key={item} value={item}>
                    <FormattedMessage id={`language.${item}`} />
                  </Option>
                ))}
            </Select>
            </Form.Item>
          </Col>
        </Row>

        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              name="title"
              label={
                  <FormattedMessage id="message.push.write.title" />
              }              
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <Input
                size="small"
                placeholder={formatMessage({id: 'message.push.write.title.placeholder'})}                
                />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            
            <p style={{ color: '#aaa' }}>
              <br />
              <FormattedMessage id="message.push.write.message.extra1" />
              <br />
              <FormattedMessage id="message.push.write.message.extra2" />
              <br />
              <FormattedMessage id="message.push.write.message.extra3" />
            </p>
            <p style={{ color: '#aaa' }} />
          </Col>
        </Row>

      </Form>
    </Drawer>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(AddView);
