import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  Avatar,
  Drawer,
  Divider,
  Button,
  Input,
  message,
  Badge,
  Collapse,
  Row,
  Col,
  Checkbox,
  Grid,
  Switch,
  Popconfirm,
} from 'antd';
import {
  UserOutlined,
  SaveOutlined,
  CloseCircleOutlined,
  FileExcelOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import CountryData from 'country-data';
import CopyText from 'components/shared-components/CopyText';
import utils from 'utils';
import UserDelete from './UserDelete';
import UserBlock from './UserBlock';
import userutils from '../userutils';

import { MEMBER_QUERY } from '../../../../graphql/query/member';
import { SUBSCRIPTIONS_QUERY } from '../../../../graphql/query/chatting';
import { MEMBER_MUTATION } from '../../../../graphql/mutations/member';
import { CREATE_LOG_EXPORTER } from '../../../../graphql/mutations/exporter';

const { Panel } = Collapse;

const options = [
  { label: 'Andorid', value: 'gcm' },
  { label: 'IOS', value: 'apns' },
];

const memberDateInfo = {
  logined_at: <FormattedMessage id="member.list.view.logined_at" />,
  created_at: <FormattedMessage id="member.list.view.created_at" />,
  updated_at: <FormattedMessage id="member.list.view.updated_at" />,
  deleted_at: <FormattedMessage id="member.list.view.deleted_at" />,
};

const UserView = (props) => {
  const { data, visible, close, refetch_members } = props;
  const { formatMessage } = useIntl();
  const { current_profile } = props;

  const { useBreakpoint } = Grid;
  const [loading, setLoading] = useState(false);
  const [member, setMember] = useState();
  const [subscriptionsCount, setSubscriptionsCount] = useState([]);
  const [memberNickname, setMemberNickname] = useState();
  const [memberURL, setMemberURL] = useState();
  const [memberRemoteIP, setMemberRemoteIP] = useState();
  const [memberMemo, setMemberMemo] = useState();
  const [memberModel, setMemberModel] = useState();
  const [memberDeviceType, setMemberDeviceType] = useState([]);
  const [memberDevice, setMemberDevice] = useState();
  const [memberNetwork, setMemberNetwork] = useState();
  const [memberVersion, setMemberVersion] = useState();
  const [memberAdid, setMemberAdid] = useState();
  const [memberCustom, setMemberCustom] = useState();
  const [exportLoading, setExportLoading] = useState(false);
  const [memberPush, setMemberPush] = useState();

  const memberData = useQuery(MEMBER_QUERY, {
    variables: {
      projectId: data?.project_id,
      id: utils.getID(data?.id),
    },
    skip: !data,
    fetchPolicy: !data ? 'cache-only' : 'cache-first',
  });

  const subscriptionsData = useQuery(SUBSCRIPTIONS_QUERY, {
    variables: {
      projectId: data?.project_id,
      filter: JSON.stringify({
        project_id: data?.project_id,
        user_id: utils.getID(data?.id),
      }),
      sort: JSON.stringify({ createdAt: -1 }),
      option: JSON.stringify({ offset: 0, per_page: 1000 }),
    },
    skip: !data,
    fetchPolicy: !data ? 'cache-only' : 'cache-first',
  });

  const [updateMember] = useMutation(MEMBER_MUTATION, {
    onCompleted(result) {
      setLoading(false);

      if (result?.updateMember?.member?.id) {
        memberData.refetch();
        close();
        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateMember',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'updateMember',
          duration: 2,
        });
      }
    },
    onError(error) {
      setLoading(false);
    },
  });

  const [createExporter] = useMutation(CREATE_LOG_EXPORTER, {
    onCompleted(result) {
      setExportLoading(false);
      if (result?.createExporter?.exporter?.id) {
        message.success({
          content: formatMessage({
            id: 'common.message.success',
          }),
          key: 'createExporter',
        });
      } else {
        setExportLoading(false);
        message.error({
          content: formatMessage({
            id: 'common.message.fail',
          }),
          key: 'createExporter',
        });
      }
    },
  });

  useEffect(() => {
    if (memberData?.data?.member) {
      const membertemp = memberData.data.member;

      setMember(membertemp);
      setMemberNickname(membertemp?.name);
      setMemberURL(membertemp?.profile);
      setMemberRemoteIP(membertemp?.remoteip);
      setMemberMemo(membertemp?.memo);
      setMemberModel(membertemp?.model);
      setMemberDeviceType(membertemp?.device_type);
      setMemberDevice(membertemp?.device);
      setMemberNetwork(membertemp?.network);
      setMemberVersion(membertemp?.version);
      setMemberAdid(membertemp?.adid);
      setMemberPush(membertemp?.push);
      setMemberCustom(membertemp?.customField);
    }
  }, [memberData]);

  useEffect(() => {
    if (subscriptionsData?.data?.subscriptions) {
      const { totalCount } = subscriptionsData.data.subscriptions;

      setSubscriptionsCount(totalCount);
    }
  }, [subscriptionsData]);

  //functions
  const onSave = () => {
    if (
      
      utils.isEmpty(memberRemoteIP) === false &&
      utils.isIP(memberRemoteIP) === false
    ) {
      message.error(formatMessage({ id: 'settings.security.ip.error1' }));
      return;
    }

    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateMember',
      duration: 0,
    });

    setLoading(true);

    const variables = {
      projectId: data?.project_id,
      id: utils.getID(data?.id),
      name: memberNickname,
      profile: memberURL,
      remoteip: memberRemoteIP,
      memo: memberMemo,
      model: memberModel,
      deviceType: memberDeviceType,
      push: memberPush,
      device: memberDevice,
      network: memberNetwork,
      version: memberVersion,
      adid: memberAdid,
      customField: memberCustom,
    };

    updateMember({ variables });
  };

  const requestExport = () => {
    setExportLoading(true);

    message.loading({
      content: formatMessage({
        id: 'common.message.loading',
      }),
      key: 'createExporter',
      duration: 0,
    });

    const exporterData = {
      type: 'member_list',
      projectId: data?.project_id,
      user_id: current_profile?.local?.email,
    };

    const value1 = {
      project_id: data?.project_id,
      id: utils.getID(data?.id),
    };

    exporterData.value1 = JSON.stringify(value1);

    createExporter({
      variables: exporterData,
    });
  };

  const getMemberInfoData = (key) => {
    if (member == null) {
      return '';
    }

    if (member[key] == null) {
      return '';
    }

    if (key === 'country') {
      if (CountryData.countries[member[key].toUpperCase()]) {
        return `${CountryData.countries[member[key].toUpperCase()].name} [${
          CountryData.countries[member[key].toUpperCase()].alpha2
        }]`;
      } else {
        return '';
      }
    } else {
      return (
        <>
          {member[key]} <CopyText text={member[key]} />
        </>
      );
    }
  };

  return (
    <Drawer
    open={visible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      placement="right"
      bodyStyle={{ paddingBottom: 80 }}
      closable={false}
      onClose={close}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <div
            className={`${utils.isTablet(useBreakpoint()) ? 'mb-1' : ''}`}
            style={{ display: 'inline-block' }}
          >
            <Popconfirm
              title={<FormattedMessage id="common.message.export.confirm" />}
              onConfirm={() => {
                requestExport();
              }}
              okText={<FormattedMessage id="common.ok" />}
              cancelText={<FormattedMessage id="common.no" />}
            >
              <Button
                size="small"
                className="ml-1"
                icon={<FileExcelOutlined className="mr-2" />}
                loading={exportLoading}
              >
                <FormattedMessage id="common.export" />
              </Button>
            </Popconfirm>
          </div>
          <div
            style={{ display: 'inline-block' }}
            className={`${utils.isTablet(useBreakpoint()) ? 'mb-1' : ''}`}
          >
            <Button
              size="small"
              className="ml-1"
              icon={<CloseCircleOutlined className="mr-2" />}
              onClick={close}
            >
              <FormattedMessage id="common.cancel" />
            </Button>

            <UserBlock
              extraClassName="ml-1"
              projectId={data?.project_id}
              rowData={[member]}
              blockData={memberData?.data?.member?.memberblock_id}
              deleted={member?.deleted}
              disabled={member?.deleted}
              refetch_member={memberData.refetch}
              refetch_members={refetch_members}
            />
          </div>
          <div style={{ display: 'inline-block' }}>
            <UserDelete
              projectId={data?.project_id}
              id={utils.getID(data?.id)}
              deleted={member?.deleted}
              refetch={memberData.refetch}
            />
            <Button
              size="small"
              className="ml-1"
              icon={<SaveOutlined className="mr-2" />}
              type="primary"
              loading={loading}
              disabled={member?.deleted}
              onClick={onSave}
            >
              <FormattedMessage id="common.save" />
            </Button>
          </div>
        </div>
      }
    >
      <div className="text-center mt-3">
        <Avatar
          size={80}
          src={member?.profile || null}
          icon={<UserOutlined />}
        />
        <h3 className="mt-2 mb-0">{member?.name}</h3>
        <span className="text-muted">{utils.getID(data?.id)}</span>
      </div>

      <Divider dashed />

      {/* ACCOUNT DETAILS */}
      <h6 className="text-muted text-uppercase">
        <FormattedMessage id="member.list.view.subtitle1" />
      </h6>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.columns.id" />
          </span>
        </Col>
        <Col span={19}>
          <span className="text-dark">
            {utils.getID(member?.id)} <CopyText text={utils.getID(data?.id)} />
          </span>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.columns.name" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            size="small"
            className="text-dark"
            disabled={member?.deleted}
            placeholder={formatMessage({
              id: 'member.list.view.name.placeholder',
            })}
            value={memberNickname}
            onChange={(e) => setMemberNickname(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.profile" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            size="small"
            className="text-dark"
            disabled={member?.deleted}
            placeholder={formatMessage({
              id: 'member.list.view.profile.placeholder',
            })}
            value={memberURL}
            onChange={(e) => setMemberURL(e.target.value)}
          />
        </Col>
      </Row>

      <div className="mt-5">
        <h6 className="text-muted text-uppercase">
          <FormattedMessage id="member.list.view.subtitle2" />
        </h6>
      </div>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.country" />
          </span>
        </Col>
        <Col span={19}>
          <span className="ml-2 text-dark text-break">
            {getMemberInfoData('country')}
          </span>
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.remoteip" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberRemoteIP} />}
            value={memberRemoteIP}
            onChange={(e) => setMemberRemoteIP(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.memo" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberMemo} />}
            value={memberMemo}
            onChange={(e) => setMemberMemo(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.model" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberModel} />}
            value={memberModel}
            onChange={(e) => setMemberModel(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.devicetype" />
          </span>
        </Col>
        <Col span={19}>
          <Checkbox.Group
            options={options}
            disabled={member?.deleted}
            value={memberDeviceType}
            onChange={(checkedValues) => setMemberDeviceType(checkedValues)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.device" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberDevice} />}
            value={memberDevice}
            onChange={(e) => setMemberDevice(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.network" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberNetwork} />}
            value={memberNetwork}
            onChange={(e) => setMemberNetwork(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.version" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberVersion} />}
            value={memberVersion}
            onChange={(e) => setMemberVersion(e.target.value)}
          />
        </Col>
      </Row>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.customfield" />
          </span>
        </Col>
        <Col span={19}>
          <Input
            style={{ padding: '0px 7px' }}
            className="text-dark"
            size="small"
            disabled={member?.deleted}
            suffix={<CopyText text={memberCustom} />}
            value={memberCustom}
            onChange={(e) => setMemberCustom(e.target.value)}
          />
        </Col>
      </Row>


      {Object.keys(memberDateInfo).map((item) => (
        <Row className="mt-3" key={item}>
          <Col span={5}>
            <span className="text-muted">{memberDateInfo[item]}</span>
          </Col>
          <Col span={19}>
            <span className="ml-2 text-dark">
              {member && member[item]
                ? moment(member[item]).format('YYYY-MM-DD HH:mm:ss')
                : ''}
            </span>
          </Col>
        </Row>
      ))}

      <h6 className="text-muted text-uppercase mt-5">Chatting</h6>

      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.columns.id" />
          </span>
        </Col>
        <Col span={19}>
          {userutils?.getBlockStatus(
            member?.memberblock_id,
            member?.deleted_at
          )}
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="chat.channel.label.notice" />
          </span>
        </Col>
        <Col span={19}>
          <Switch
            checkedChildren={<CheckOutlined />}
            unCheckedChildren={<CloseOutlined />}
            checked={memberPush || false}
            onChange={(e) => setMemberPush(e)}
          />
        </Col>
      </Row>
      <Row className="mt-2">
        <Col span={5}>
          <span className="text-muted">
            <FormattedMessage id="member.list.view.channel" />
          </span>
        </Col>
        <Col span={19}>{subscriptionsCount ?? 0} Channel</Col>
      </Row>
    </Drawer>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(UserView);
