import React from 'react';
import { useIntl } from 'react-intl';
import { useParams } from 'react-router';
import { Routes, Route } from 'react-router-dom';
import { signOut } from 'redux/actions/Auth';
import { connect } from 'react-redux';
import { Layout, Grid } from 'antd';

import SideNav from 'components/layout-components/SideNav';
import TopNav from 'components/layout-components/TopNav';
import MobileNav from 'components/layout-components/MobileNav';
import HeaderNav from 'components/layout-components/HeaderNav';
import PageHeader from 'components/layout-components/PageHeader';
import FooterPay from 'components/layout-components/FooterPay';
import IEModal from 'components/layout-components/IEModal';
import AppViews from 'views/app-views';
import navigationConfig from 'configs/NavigationConfig';
import {
  SIDE_NAV_WIDTH,
  SIDE_NAV_COLLAPSED_WIDTH,
  NAV_TYPE_SIDE,
  NAV_TYPE_TOP,
} from 'constants/ThemeConstant';
import utils from 'utils';

const { Content } = Layout;

export const AppLayout = ({
  navCollapsed,
  navType,
  location,
  current_project,
  signOut,
}) => {
  const { formatMessage } = useIntl();
  const params = useParams();
  let path = window.location.pathname.replace(params.companyId,':companyId');
  path = path.replace(params.projectId,':projectId');
  const currentRouteInfo = utils.getRouteInfo(
    navigationConfig,
    path,
  );
  const { useBreakpoint } = Grid;
  const screens = utils.getBreakPoint(useBreakpoint());
  const isMobile = !screens.includes('lg');
  const isNavSide = navType === NAV_TYPE_SIDE;
  const isNavTop = navType === NAV_TYPE_TOP;
  const getLayoutGutter = () => {
    if (isNavTop || isMobile) {
      return 0;
    }
    return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH;
  };

  // const showOtherProjectLogin = () => {
  //   if (
  //     window.sessionStorage.getItem('cloudchat-alert') === 'false' ||
  //     window.sessionStorage.getItem('cloudchat-alert') == null
  //   ) {
  //     window.sessionStorage.setItem('cloudchat-alert', 'true');
  //     window.alert(formatMessage({ id: 'auth.otherprojectlogin' }));
  //     signOut(projectId);
  //     localStorage.setItem('company_id', projectId);
  //     window.location.assign('/');
  //   } else {
  //     signOut(projectId);
  //     localStorage.setItem('company_id', projectId);
  //     window.location.assign('/');
  //   }
  // };

    return (
      <Layout>
        <HeaderNav isMobile={isMobile} />
        {isNavTop && !isMobile ? <TopNav routeInfo={currentRouteInfo} /> : null}
        <Layout className="app-container">
          {isNavSide && !isMobile ? (
            <SideNav routeInfo={currentRouteInfo} />
          ) : null}
          <Layout
            className="app-layout"
            style={{ paddingLeft: getLayoutGutter() }}
          >
            <div className={`app-content ${isNavTop ? 'layout-top-nav' : ''}`}>
              <PageHeader
                display={currentRouteInfo?.breadcrumb}
                title={currentRouteInfo?.title}
                subtitle={currentRouteInfo?.subtitle}
                isTablet={utils.isTablet(useBreakpoint())}
              />
              <Content>
                <Routes>
                  <Route path="*" element=<AppViews /> />
                </Routes>
              </Content>
            </div>
            <IEModal />
            {current_project?.paid !== 2 ? null : (
              <FooterPay isMobile={isMobile} />
            )}
            {/* <Footer /> */}
          </Layout>
        </Layout>
        {isMobile && <MobileNav />}
      </Layout>
    );
};

const mapStateToProps = ({ theme, project }) => {
  const { navCollapsed, navType, locale } = theme;
  const { current_project } = project;
  return { navCollapsed, navType, locale, current_project };
};

export default connect(mapStateToProps, { signOut })(AppLayout);
