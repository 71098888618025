import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Input,
  Grid,
  Tooltip as AntDToolTip,
  Spin,
  DatePicker,
  Select,
  Tabs,
  message,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ChartWidget from 'components/shared-components/ChartWidget';

import {
  Analytics_QUERY,
  CCU_ANALYTICS_QUERY,
  MESSAGE_ANALYTICS_QUERY,
  USAGES_QUERY,
} from '../../../../graphql/query/analytics';
const { Option } = Select;
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;

const MAX_SEARCH_MONTH = 3;

const colResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  style: { marginTop: 24 },
};

const oneMonthAgo = moment().subtract(29, 'days').startOf('day').format();
const oneYearAgo = moment().subtract(1, 'years').startOf('day').format();
const today = moment().endOf('day').format();


const Analytics = () => {
  const { useBreakpoint }  = Grid;

  const { formatMessage, formatNumber } = useIntl();
  
  const [startDate, setStartDate] = useState(oneMonthAgo);
  const [endDate, setEndDate] = useState(today);
  
  const [mauChartData, setMauChartData] = useState();
  const [dauChartData, setDauChartData] = useState();
  const [ccuChartData, setCcuChartData] = useState();
  const [newuserChartData, setNewUserChartData] = useState();
  const [sessionChartData, setSessionChartData] = useState();
  const [messageChartData, setMessageChartData] = useState();
  const [visitorChartData, setVisitorChartData] = useState();


  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: oneMonthAgo,
    endDate: today,
  });
  const [form] = Form.useForm();
  const params = useParams();
  const height = 400;
  const usagesData = useQuery(USAGES_QUERY, {
    variables: {
      projectId: params.projectId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const MauAnalyticsData = useQuery(Analytics_QUERY, {
    variables: {
      projectId: params.projectId,
      type: 'activeuser_monthly',
      startDate: oneYearAgo,
      endDate: today,
    },
    fetchPolicy: 'network-only',
  });
  const DauAnalyticsData = useQuery(Analytics_QUERY, {
    variables: {
      projectId: params.projectId,
      type: 'activeuser_daily',
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const CcuAnalyticsData = useQuery(CCU_ANALYTICS_QUERY, {
    variables: {
      projectId: params.projectId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const MessageAnalyticsData = useQuery(MESSAGE_ANALYTICS_QUERY, {
    variables: {
      projectId: params.projectId,
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const NewUserAnalyticsData = useQuery(Analytics_QUERY, {
    variables: {
      projectId: params.projectId,
      type: 'newuser_daily',
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });
  const SessionAnalyticsData = useQuery(Analytics_QUERY, {
    variables: {
      projectId: params.projectId,
      type: 'session_daily',
      startDate: startDate,
      endDate: endDate,
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (MessageAnalyticsData?.loading === true) {
      setMessageChartData({ loading: true });
      return;
    }

    const edges = MessageAnalyticsData?.data?.messageAnalytics?.edges;

    let dataArray = [];
    let categories = [];

    if (edges) {
      edges.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let data = {
      name: formatMessage({ id: 'dashboards.chart.title.messages' }),
      data: dataArray.sort(),
    };

    setMessageChartData({
      series: [data],
      categories,
      loading: false,
    });
  }, [MessageAnalyticsData]);

  // DAU
  useEffect(() => {
    if (DauAnalyticsData?.loading === true) {
      setDauChartData({ loading: true });
      return;
    }

    const edges = DauAnalyticsData?.data?.analytics?.edges;
    let dataArray = [];
    let categories = [];

    if (edges) {
      edges.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let data = {
      name: formatMessage({ id: 'dashboards.chart.title.dau' }),
      data: dataArray.sort(),
    };

    setDauChartData({
      series: [data],
      categories,
      loading: false,
    });
  }, [DauAnalyticsData]);


  // MAU
  useEffect(() => {
    if (MauAnalyticsData?.loading === true) {
      setMauChartData({ loading: true });
      return;
    }

    const edges = DauAnalyticsData?.data?.analytics?.edges;
    let dataArray = [];
    let categories = [];

    if (edges) {
      edges.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let data = {
      name: formatMessage({ id: 'dashboards.chart.title.mau' }),
      data: dataArray.sort(),
    };

    setMauChartData({
      series: [data],
      categories,
      loading: false,
    });
  }, [MauAnalyticsData]);


  // CCU
  useEffect(() => {
    if (CcuAnalyticsData?.loading === true) {
      setCcuChartData({ loading: true });
      return;
    }

    const edges = CcuAnalyticsData?.data?.ccuAnalytics?.edges;
    let dataArray = [];
    let categories = [];

    if (edges) {
      edges.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let data = {
      name: formatMessage({ id: 'dashboards.chart.title.ccu' }),
      data: dataArray.sort(),
    };
    setCcuChartData({
      series: [data],
      categories,
      loading: false,
    });
  }, [CcuAnalyticsData]);

  // NEW User
  useEffect(() => {
    if (NewUserAnalyticsData?.loading === true) {
      setNewUserChartData({ loading: true });
      return;
    }

    const edges = NewUserAnalyticsData?.data?.analytics?.edges;
    let dataArray = [];
    let categories = [];

    if (edges) {
      edges.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let data = {
      name: formatMessage({ id: 'dashboards.chart.title.newuser' }),
      data: dataArray.sort(),
    };

    setNewUserChartData({
      series: [data],
      categories,
      loading: false,
    });
  }, [NewUserAnalyticsData]);

  // SESSION
  useEffect(() => {
    if (SessionAnalyticsData?.loading === true) {
      setSessionChartData({ loading: true });
      return;
    }

    const edges = SessionAnalyticsData?.data?.analytics?.edges;
    let dataArray = [];
    let categories = [];

    if (edges) {
      edges.map((item) => {
        dataArray.push([item.node.date, item.node.count]);
        categories.push(item.node.date);
      });
    }

    let data = {
      name: formatMessage({ id: 'dashboards.chart.title.session' }),
      data: dataArray.sort(),
    };

    setSessionChartData({
      series: [data],
      categories,
      loading: false,
    });
  }, [SessionAnalyticsData]);



  const onChange = (date, dateString) => {
    if (!date) {
      return;
    }
    
    setStartDate(date[0].startOf('day').format());
    setEndDate(date[1].endOf('day').format());
  };
  

  return (
    <div>
      <Row gutter={24}>
        <Col span={24}>
        <div>
             <Form 
              form={form}
        className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile' : ''}`}
        layout="inline"
        onFinish={onChange}
        >
                    <Form.Item>
                    <RangePicker
                      allowClear={false}
                      onChange={onChange}
                      value={[moment(startDate), moment(endDate)]}
                      size="small"
                    />
                    </Form.Item>
             
          </Form>
          </div>
        </Col>
      
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          {dauChartData ? (
                <ChartWidget
                  title={formatMessage({ id: 'dashboards.chart.title.dau' })}
                  type="area"
                  extra={dauChartData.updatedAt}
                  series={dauChartData.series}
                  categories={dauChartData.categories}
                  loading={dauChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
          <Col span={12}>
          {messageChartData ? (
                <ChartWidget
                title={formatMessage({ id: 'dashboards.chart.title.messages' })}
                type="area"
                  extra={messageChartData.updatedAt}
                  series={messageChartData.series}
                  categories={messageChartData.categories}
                  loading={messageChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          {mauChartData ? (
                <ChartWidget
                title={formatMessage({ id: 'dashboards.chart.title.mau' })}
                type="area"
                  extra={mauChartData.updatedAt}
                  series={mauChartData.series}
                  categories={mauChartData.categories}
                  loading={mauChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
          <Col span={12}>
          {ccuChartData ? (
                <ChartWidget
                title={formatMessage({ id: 'dashboards.chart.title.ccu' })}
                type="area"
                  extra={ccuChartData.updatedAt}
                  series={ccuChartData.series}
                  categories={ccuChartData.categories}
                  loading={ccuChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
      </Row>
      <Row gutter={24}>
        <Col span={12}>
          {newuserChartData ? (
                <ChartWidget
                title={formatMessage({ id: 'dashboards.chart.title.newuser' })}
                type="area"
                
                  extra={newuserChartData.updatedAt}
                  series={newuserChartData.series}
                  categories={newuserChartData.categories}
                  loading={newuserChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
          <Col span={12}>
          {sessionChartData ? (
                <ChartWidget
                  title={formatMessage({ id: 'dashboards.chart.title.session' })}
                  type="area"
                  extra={sessionChartData.updatedAt}
                  series={sessionChartData.series}
                  categories={sessionChartData.categories}
                  loading={sessionChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
      </Row>
    </div>
  );
};

export default Analytics;