import React, { useState } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import moment from 'moment';
import {
  Modal,
  Button,
  Input,
  message,
  Form,
  Col,
  Row,
  Grid,
  Radio,
  Switch,
  InputNumber,
} from 'antd';
import {
  SaveOutlined,
  CloseCircleOutlined,
  CheckOutlined,
  CloseOutlined,
} from '@ant-design/icons';
import ChatService from 'services/ChatService';
import utils from 'utils';

const ChatBan = (props) => {
  const {
    visible,
    setVisible,
    channelId,
    userId,
    banData,
    subscriptions_refetch,
  } = props;

  const [form] = Form.useForm();
  const { useBreakpoint } = Grid;
  const { formatMessage } = useIntl();

  const [loading, setLoading] = useState(false);

  const onSave = () => {
    setLoading(true);

    form
      .validateFields()
      .then((values) => {
        ChatService.getInstance()
          .banUser(channelId, userId, values.timeout, values.reason)
          .then((result) => {
            setLoading(false);
            if (result.id) {
              onChildrenModalClose();

              message.success({
                content: formatMessage({
                  id: 'common.message.save.success',
                }),
                key: 'setBan',
              });
            } else {
              message.error({
                content: formatMessage({
                  id: 'common.message.save.fail',
                }),
                key: 'setBan',
              });
            }
          })
          .catch((e) => {
            setLoading(false);

            message.error({
              content: e.message,
              key: 'setBan',
            });
          });
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const unbanUser = () => {
    setLoading(true);

    ChatService.getInstance()
      .unbanUser(channelId, userId)
      .then((result) => {
        setLoading(false);

        if (result?.memberBlock?.id) {
          onChildrenModalClose();

          message.success({
            content: formatMessage({
              id: 'common.message.save.success',
            }),
            key: 'setBan',
          });
        } else {
          message.error({
            content: formatMessage({
              id: 'common.message.save.fail',
            }),
            key: 'setBan',
          });
        }
      })
      .catch((e) => {
        setLoading(false);

        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'setBan',
        });
      });
  };

  const onChildrenModalClose = () => {
    form.resetFields();

    // if (subscriptions_refetch) subscriptions_refetch();

    setVisible(false);
  };

  const isbanned = () => {
    if (banData?.id) {
      return true;
    }

    return false;
  };

  return (
    <Modal
    open={visible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      closable={false}
      forceRender
      destroyOnClose
      onCancel={onChildrenModalClose}
      title={
        isbanned() !== true ? (
          <FormattedMessage id="chat.ban" />
        ) : (
          <FormattedMessage id="chat.ban.unban" />
        )
      }
      footer={
        <div className="alignRight">
          <Button
            size="small"
            onClick={onChildrenModalClose}
            icon={<CloseCircleOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          {isbanned() !== true ? (
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              loading={loading}
              onClick={() => form.submit()}
            >
              <FormattedMessage id="common.send" />
            </Button>
          ) : (
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              loading={loading}
              onClick={() => unbanUser()}
            >
              <FormattedMessage id="common.send" />
            </Button>
          )}
        </div>
      }
    >
      {isbanned() !== true ? (
        <Form
          form={form}
          layout="vertical"
          initialValues={{ timeout: 86400 }}
          onFinish={onSave}
        >
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="timeout"
                label={<FormattedMessage id="chat.ban.timeout" />}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="chat.ban.timeout.placeholder" />
                    ),
                  },
                ]}
              >
                <InputNumber style={{ width: 150 }} size="small" min={1} />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="reason"
                label={<FormattedMessage id="chat.ban.reason" />}
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="chat.ban.reason.placeholder" />
                    ),
                  },
                ]}
              >
                <Input
                  className="w-100"
                  size="small"
                  placeholder={formatMessage({
                    id: 'chat.ban.reason.placeholder',
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      ) : (
        <>
          <Row gutter={16} className="mb-3">
            <Col span={8}>
              <FormattedMessage id="search.columns.sender.id" />
            </Col>
            <Col span={16}>{banData?.member_id}</Col>
          </Row>
          <Row gutter={16} className="mb-3">
            <Col span={8}>
              <FormattedMessage id="chat.ban.startedAt" />
            </Col>
            <Col span={16}>
              {moment(banData?.started_at).format('YYYY-MM-DD HH:mm:ss')}
            </Col>
          </Row>
          <Row gutter={16} className="mb-3">
            <Col span={8}>
              <FormattedMessage id="chat.ban.endedAt" />
            </Col>
            <Col span={16}>
              {moment(banData?.ended_at).format('YYYY-MM-DD HH:mm:ss')}
            </Col>
          </Row>
          <Row gutter={16} className="mb-5">
            <Col span={8}>
              <FormattedMessage id="chat.ban.reason" />
            </Col>
            <Col span={16} className="text-break">
              {banData?.messageMulti?.[0]?.value}
            </Col>
          </Row>

          <FormattedMessage id="chat.ban.unban.confirm" />
        </>
      )}
    </Modal>
  );
};

export default ChatBan;
