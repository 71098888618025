import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Table,
  Button,  
  Tooltip,
  Form,
} from 'antd';
import {
  QuestionCircleOutlined,
  PlusCircleOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';

import { STORYS_QUERY } from '../../../graphql/query/story';

import Add from './Add';


const List = ({ }) => {
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  // States
  const [stories, setStory] = useState();
  const [modalVisible, setModalVisible] = useState(false);
  const [item, setItem] = useState({});
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
 
  const [sort, setSort] = useState({
    sort_id: undefined,
    sort: undefined,
  });

  // Queries
  const getData = useQuery(STORYS_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({
        project_id: params.projectId,
      }),
      sort: JSON.stringify({
        [sort.sort_id || 'created_at']: sort.sort === 'asc' ? 1 : -1,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
    },
    fetchPolicy: 'network-only',
  });



  useEffect(() => {
    if (getData?.data?.stories) {
      let entries = getData.data.stories;

      setStory(entries.edges);
      setPagination({ ...pagination, total: entries.totalCount });
    }
  }, [getData]);

  const onEdit = (item) => {    
    if(!item) {
      return false;
    }
    setItem(item);
    setModalVisible(true);
  };
  const onAdd = () => {   
    setItem([]); 
    setModalVisible(true);
  };

  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSort({
        sort_id: sorter.columnKey,
        sort: sorter.order === 'ascend' ? 'asc' : 'desc',
      });
    } else {
      setSort({
        sort_id: undefined,
        sort: undefined,
      });
    }

    setPagination({ ...pagination, current: pagination.current });
  };


  const columns = [
    
    {
      title: (
        <div>
          {formatMessage({ id: 'story.status' })}{' '}
          <Tooltip
            className="ml-1"
            overlayStyle={{ whiteSpace: 'pre-line' }} 
            title={formatMessage({ id: 'story.status.use.desc' })}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      ),
      dataIndex: 'status',
      render: (text, record) => {
        if (utils.isEmpty(record)) {
          return '';
        }
        return (
          <span>           
            {record.node.status === 1
              ? formatMessage({ id: 'story.status.use' })
              : formatMessage({ id: 'story.status.nouse' })}
          </span>
        );
      },
    },
    {
      title: formatMessage({ id: 'story.subject' }),
      dataIndex: 'title',
      width: "60%",
      render: (text, record) => {
        return <a style={{ wordBreak: 'break-all' }} onClick={() => onEdit(record.node)}>
          <LinesEllipsis
            style={{ whiteSpace: 'pre-wrap' }}
            text={utils.getLanguageDec(record.node.title)}
            maxLine="1"
            ellipsis="..."
            trimRight
            basedOn="letters"
          />
        </a>
      },
    },
    {
      title: formatMessage({ id: 'story.hit' }),
      dataIndex: 'hit',
      render: (text, record) => {
        if (utils.isEmpty(record) || utils.isEmpty(record.node.hit)) {
          return '';
        }

        return record.node.hit;
      },
    },
    {
      title: formatMessage({ id: 'story.createdat' }),
      dataIndex: 'createdAt',
      render: (text, record) => {
        if (utils.isEmpty(record) || utils.isEmpty(record.node.createdAt)) {
          return '';
        }

        return utils.getDate(record.node.createdAt);
      },
    },
    
  ];

  return (
    <div>
      <div style={{ marginLeft: 'auto' }}>
        <Form.Item name="button_add" style={{ marginRight: 0 }}>
        <Button
          type="primary"
          size="small"
          icon={<PlusCircleOutlined className="mr-2" />}
          onClick={onAdd}
        >
          <FormattedMessage id="story.add" />
        </Button>
       
        </Form.Item>
      </div>
      <div className="table-responsive">
      <Add
        visible={modalVisible}
        setVisible={(result) => setModalVisible(result)}
        item={item}
        lists_refetch={getData?.refetch}
      />
        <Table
          scroll={{ x: 1000 }}
          style={{ marginTop: 10 }}
          loading={getData?.loading}
          pagination={pagination}
          columns={columns}

          onChange={handleTableChange}
          dataSource={stories}
          rowKey={(record) => record.node.id}
          size="small"
          footer={() => {
            return (
              <div>
                <FormattedMessage id="common.total.1" />
                {pagination?.total ? formatNumber(pagination.total) : 0}
                <FormattedMessage id="common.total.2" />
              </div>
            );
          }}
        />
        
        
      </div>
      
    </div>
  );
};
export default List;
