import gql from 'graphql-tag';

const APIKEY_QUERY = gql`
  query APIKEY_QUERY(
    
    $projectId: String!
    $filter: String!
    $sort: String
    $option: String
  ) {
    apikeys(
      projectId: $projectId
      filter: $filter
      sort: $sort
      option: $option
    ) {
      totalCount
      edges {
        node {
          id
          project_id
          user_id
          status
          expired
          key
          description
          expiredAt
          usedAt
          createdAt
          updatedAt
          deleted
          deletedAt
        }
      }
    }
  }
`;

export { APIKEY_QUERY };
