import React, { useEffect, useState } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';
import ReactGA from 'react-ga';

import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import HeaderLayout from 'layouts/header-layout';
import VerifyLayout from 'layouts/verify-layout';
import BlankLayout from 'layouts/blank-layout';
import CompanyLayout from 'layouts/company-layout';
import loadable from '@loadable/component';

import Utils from '../utils';
import { AUTH_TOKEN } from 'redux/constants/Auth';
import { Header } from 'antd/lib/layout/layout';


const NotFound = loadable(() => import('./errors/404'));

function PrivateRoute({ children }) {
  const storagetoken = localStorage.getItem(AUTH_TOKEN);
  const auth = storagetoken ? true : false;
  return auth ? children : <Navigate to="/auth/login" />;
}

const Views = (props) => {
  const [isAuthenticated, setAuthenticated] = useState(undefined);

  const { locale, redirect, token } = props;

  const currentAppLocale = Utils.getAppLocaleData(locale);
  const storagetoken = localStorage.getItem(AUTH_TOKEN);
  if (process.env.REACT_APP_GA) {
    ReactGA.initialize(process.env.REACT_APP_GA);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  useEffect(() => {
    if (storagetoken && token) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
    }
  }, [storagetoken, token]);

  // const RouteInterceptor = ({ path, children, isAuthenticated, ...rest }) => {
  //   if (isAuthenticated == null) {
  //     return <div></div>;
  //   }
  //   if (path === '/auth') {
  //     return (
  //       <Route
  //         path={path}
  //         render={({ location }) => {
  //           if (isAuthenticated) {
  //             return (
  //               <Navigate
  //                 to={{
  //                   pathname: '/',
  //                 }}
  //               />
  //             );
  //           } else {
  //             return children;
  //           }
  //         }}
  //       />
  //     );
  //   } else {
  //     return (
  //       <Route
  //         path={path}
  //         render={({ location }) => {
  //           if (isAuthenticated) {
  //             return children;
  //           } else {
  //             return (
  //               <Navigate
  //                 to={{
  //                   pathname: '/auth/login',
  //                   state: { from: location, redirect: redirect },
  //                 }}
  //               />
  //             );
  //           }
  //         }}
  //       />
  //     );
  //   }
  // };
  
  return (
    
    <div>
      <IntlProvider
      locale={currentAppLocale.locale}
      messages={currentAppLocale.messages}
    >
      <ConfigProvider locale={currentAppLocale.antd}>
      <Routes>
        <Route exact path="/error" element={<BlankLayout />}/>
        <Route exact path="/monitor/l7check/*" element={<BlankLayout />}/>
        <Route exact path="/:companyId" element={<CompanyLayout />}/>
        <Route exact path="/:companyId/verify/*" element={<VerifyLayout />}/>
        <Route exact path="/:companyId/:projectId/*" element={
            <PrivateRoute>
              <AppLayout />
            </PrivateRoute>
          }
          />
        <Route exact path="/auth/*" element={<AuthLayout />}/>
        <Route exact path='/' element={<Navigate to="/auth/login" />}/>
        <Route exact path="*" element={<NotFound />}/>
      </Routes>
      </ConfigProvider>
    </IntlProvider>
    </div>
  );
};

// <IntlProvider
//       locale={currentAppLocale.locale}
//       messages={currentAppLocale.messages}
//     >
//       <ConfigProvider locale={currentAppLocale.antd}>
//         <Routes>
//           <Route path="/auth">
//             {RouteInterceptor({
//               path: '/auth',
//               children: <AuthLayout />,
//               isAuthenticated,
//             })}
//           </Route>
//           <Route path="/monitor/l7check" exact>
//             <BlankLayout />
//           </Route>
//           <Route path="/error">
//             <BlankLayout />
//           </Route>
//           <Route path="/:companyId/verify">
//             <VerifyLayout />
//           </Route>
//           <Route path="/profile">
//             {RouteInterceptor({
//               path: '/profile',
//               children: <HeaderLayout />,
//               isAuthenticated,
//             })}
//           </Route>
//           <Route path="/:projectid">
//             {RouteInterceptor({
//               path: '/:projectid',
//               children: <AppLayout props={props} />,
//               isAuthenticated,
//             })}
//           </Route>
//           <Route path="/">
//             {RouteInterceptor({
//               path: '/',
//               children: <HeaderLayout />,
//               isAuthenticated,
//             })}
//           </Route>
//         </Routes>
//       </ConfigProvider>
//     </IntlProvider>

const mapStateToProps = ({ theme, auth }) => {
  const { locale } = theme;
  const { token, redirect } = auth;
  return { locale, token, redirect };
};

export default connect(mapStateToProps)(Views);
