import { gql } from '@apollo/client';

const PROJECTS_QUERY = gql`
  query projects(
    $ncpId: String
    $filter: String!
    $sort: String
    $option: String
  ) {
    projects(
      ncpId: $ncpId
      filter: $filter
      sort: $sort
      option: $option
    ) {
      totalCount
      edges {
        node {
          id
          ncp_project_id
          name
          message_retention_hours
          paid
          max_message_length
          profanity_deny_type
          profanity_filter
          id_regex
          name_regex
          name_denied
          language
          created_at
          updated_at
          ncp {
            objectstorage_enable
            objectstorage_bucket
            objectstorage_access_key
            objectstorage_secret_key
            sens_push_enable
            sens_push_secret_key
            sens_push_service_id
            sens_push_limit
            sens_sms_enable
            sens_sms_access_key
            sens_sms_service_id
            sens_sms_secret_key
            sens_sms_limit
            papago_enable
            ai_filter_enable
            papago_client_id
            papago_client_secret
            papago_limit
          }
        }
      }
    }
  }
`;

const PROJECT_QUERY = gql`
  query project($id: ID!, $ncpId: String!) {
    project(id: $id, ncpId: $ncpId) {
      id
      ncp_project_id
      name
      message_retention_hours
      paid
      max_message_length
      profanity_deny_type
      profanity_filter
      id_regex
      name_regex
      name_denied
      language
      created_at
      updated_at
      ncp {
        objectstorage_enable
        objectstorage_bucket
        objectstorage_access_key
        objectstorage_secret_key
        sens_push_enable
        sens_push_secret_key
        sens_push_service_id
        sens_push_limit
        sens_sms_enable
        sens_sms_access_key
        sens_sms_service_id
        sens_sms_secret_key
        sens_sms_limit
        papago_enable
        ai_filter_enable
        papago_client_id
        papago_client_secret
        papago_limit
      }
    }
  }
`;

const PROJECT_ACCESSS = gql`
  query project($id: ID!, $ncpId: String!) {
    project(id: $id, ncpId: $ncpId) {
      id
      anonymous_enable
      access {
        permissions
        domains
        ips
        exts
        max_upload_size
        download_expire_days
        url_deny_type
        url_allows
      }
    }
  }
`;

export { PROJECTS_QUERY, PROJECT_QUERY, PROJECT_ACCESSS };
