import { CURRENT_PROJECT, CURRENT_PROFILE } from '../constants/Project';
const initProject = { current_project: {}, current_profile: {} };

const project = (state = initProject, action) => {
  switch (action.type) {
    case CURRENT_PROJECT:
      return {
        ...state,
        current_project: action.project,
      };
    case CURRENT_PROFILE:
      return {
        ...state,
        current_profile: action.profile,
      };

    default:
      return state;
  }
};

export default project;
