import React from 'react';
import { Routes, Route } from 'react-router-dom';
import BlankViews from 'views/blank-views';

export const BlankLayout = () => {
  return (
    <div className="auth-container">
      <Routes>
        <Route path="*" component={BlankViews} />
      </Routes>
    </div>
  );
};

export default BlankLayout;
