import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { message } from 'antd';
import { CopyOutlined } from '@ant-design/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';

export const CopyText = (props) => {
  const { formatMessage } = useIntl();
  const { text } = props;
  if (text) {
    return (
      <CopyToClipboard
        text={text}
        onCopy={(text, result) =>
          result
            ? message.success(
                formatMessage({ id: 'common.message.copytoclipboard.success' })
              )
            : message.error(
                formatMessage({ id: 'common.message.copytoclipboard.fail' })
              )
        }
      >
        <a
          className="copyicon"
          style={{ color: 'rgba(0, 0, 0, 0.65)', paddingLeft: '5px' }}
          onClick={(event) => event.stopPropagation()}
        >
          <CopyOutlined />
        </a>
      </CopyToClipboard>
    );
  }

  return '';
};

export default CopyText;
