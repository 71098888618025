import gql from 'graphql-tag';

const UPDATE_PROJECT_MUTATION = gql`
  mutation updateProject(
    $id: ID!
    $name: String
    $message_retention_hours: Int
    $max_message_length: Int
    $profanity_filter: String
    $profanity_deny_type: String
    $ncp: NcpInput
    $id_regex: String
    $name_denied: [String]
    $name_regex: String
    $language: String
  ) {
    updateProject(
      input: {
        id: $id
        name: $name
        message_retention_hours: $message_retention_hours
        max_message_length: $max_message_length
        profanity_filter: $profanity_filter
        profanity_deny_type: $profanity_deny_type
        ncp: $ncp
        name_regex: $name_regex
        id_regex: $id_regex
        name_denied: $name_denied
        language: $language
      }
    ) {
      project {
        id
        ncp_project_id
        name
        message_retention_hours
        max_message_length
        profanity_deny_type
        profanity_filter
        paid
        name_regex
        id_regex
        name_denied
        created_at
        updated_at
        language
        ncp {
          objectstorage_enable
          objectstorage_bucket
          objectstorage_access_key
          objectstorage_secret_key
          sens_push_enable
          sens_push_secret_key
          sens_push_service_id
          sens_push_limit
          sens_sms_enable
          sens_sms_access_key
          sens_sms_service_id
          sens_sms_secret_key
          sens_sms_limit
          papago_enable
          ai_filter_enable
          papago_client_id
          papago_client_secret
          papago_limit
        }
      }
    }
  }
`;

const UPDATE_PROJECT_ACCESS_MUTATION = gql`
  mutation (
    $id: ID!
    $name: String
    $anonymous_enable: Boolean
    $access: AccessInput
  ) {
    updateProject(
      input: {
        id: $id
        name: $name
        anonymous_enable: $anonymous_enable
        access: $access
      }
    ) {
      project {
        id
        name
        anonymous_enable
        access {
          permissions
          domains
          ips
          max_upload_size
          download_expire_days
          url_deny_type
          url_allows
        }
      }
    }
  }
`;

const CREATE_PROJECT_FROM_MAIL_MUTATION = gql`
  mutation createProjectFromMail(
    $ncpid: String!
    $tempkey: String!
    $name: String
  ) {
    createProjectFromMail(
      input: { ncpid: $ncpid, tempkey: $tempkey, name: $name }
    ) {
      project {
        id
        name
      }
    }
  }
`;

const PAID_PROJECT_MUTATION = gql`
  mutation paidProject($id: ID!) {
    paidProject(input: { id: $id }) {
      project {
        paid
      }
    }
  }
`;

export {
  UPDATE_PROJECT_ACCESS_MUTATION,
  UPDATE_PROJECT_MUTATION,
  CREATE_PROJECT_FROM_MAIL_MUTATION,
  PAID_PROJECT_MUTATION,
};
