import gql from 'graphql-tag';
const MESSAGE = `
          id
          message_id
          channel_id
          sort_id
          message_type
          attachment_filenames {
            name
            size
            type
            url
          }
          sender {
            senderid: id
            name
            profile
          }
          admin {
            adminid: id
            name
          }
          mentions
          mentions_everyone
          content
          sended_at
          created_at
        `;
const DELETE_MESSAGE_MUTATION = gql`
  mutation ($projectId: String!, $id: ID!) {
    deleteMessage(input: { projectId: $projectId, id: $id }) {
      message {
        ${MESSAGE}
      }
    }
  }
`;

const DELETE_MESSAGES_MUTATION = gql`
  mutation ($projectId: String!, $ids: [ID]!) {
    deleteMessages(input: { projectId: $projectId, ids: $ids }) {
      messages {
        ${MESSAGE}
      }
    }
  }
`;

const CREATE_CHANNEL_MESSAGE = gql`
  mutation (
    $projectId: String!
    $channelId: String!
    $content: String!
  ) {
    createChannelMessage(
      input: {
        projectId: $projectId
        channelId: $channelId
        content: $content
      }
    ) {
      message {
        content
        
        created_at
        sended_at
      }
    }
  }
`;

export { DELETE_MESSAGE_MUTATION, DELETE_MESSAGES_MUTATION, CREATE_CHANNEL_MESSAGE };
