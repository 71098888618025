import gql from 'graphql-tag';

const JOBMANAGEMENTS_QUERY = gql`
  query JOBMANAGEMENTS_QUERY(
    $projectId: String!
    $offset: Int
    $per_page: Int
    $search: String
    $query: String
    $startDate: String
    $endDate: String
  ) {
    exporters(
      projectId: $projectId
      offset: $offset
      per_page: $per_page
      startDate: $startDate
      endDate: $endDate
      search: $search
      query: $query
    ) {
      totalCount
      edges {
        node {
          id
          type
          status
          proc_startdate
          proc_enddate
          user_id
          filelink
          expired_at
        }
      }
    }
  }
`;

export { JOBMANAGEMENTS_QUERY };
