import gql from 'graphql-tag';

const ARCHIVE_FIELD = `
id
  project_id
status
storageType
project_id
members
user_id
user { 
    id
    name    
    profile
    device_type
    language
  }
  hit
  filepath
  filename
  original
  format
  size
  created_at
  expired_at
  updated_at
`;

const DELETE_ARCHIVE_MUTATION = gql`
  mutation deleteArchive ($projectId: String!, $messageId: String!) {
    deleteArchive(input: { projectId: $projectId, messageId: $messageId }) {
      result
    }
  }
`;


const DELETE_ARCHIVES_MUTATION = gql`
  mutation deleteArchives ($projectId: String!, $messageId: String!) {
    deleteArchives(input: { projectId: $projectId, messageId: $messageId }) {
      result
    }
  }
`;

const UPDATE_ARCHIVES_MUTATION = gql`
  mutation updateArchive($projectId: String!, $ids: [ID]!, $fields: String!) {
    updateArchive(input: { projectId: $projectId, ids: $ids, fields: $fields }) {
      archives
      {
        ${ARCHIVE_FIELD}
      }
    }
  }
`;

export { DELETE_ARCHIVE_MUTATION, DELETE_ARCHIVES_MUTATION, UPDATE_ARCHIVES_MUTATION };
