import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Badge, Grid } from 'antd';
import CopyText from 'components/shared-components/CopyText';
import utils from 'utils';

const colors = ['#00a2ae', '#ffbf00', '#f56a00', '#7265e6'];

const handleBrokenImage = e => (e.target.src = "http://ia.media-imdb.com/images/M/MV5BMzFlZjVmY2YtYWRiNi00NGM1LTk4NGUtYjg5ZGEzZTY5ZmRkXkEyXkFqcGdeQXVyMzU0NzkwMDg@._V1_SX300.jpg");

const renderAvatar = (props) => {
  return (
    <Avatar
      {...props}
      className={`ant-avatar-${props.type}`}
      style={{ minWidth: '40px' }}
    >
      {props.text}
    </Avatar>
  );
};

const renderAvatarColor = (props) => {
  return (
    <Avatar
      {...props}
      className={`ant-avatar-${props.type}`}
      style={{
        backgroundColor: colors[props.index % 4],
        verticalAlign: 'middle',
        minWidth: '40px',
      }}
    >
    
    </Avatar>
  );
};

const renderAvatarBadge = (props) => {
  return (
    <Badge dot={true} offset={[-4, 35]} status={props?.badge || 'default'}>
      <Avatar
        {...props}
        className={`ant-avatar-${props.type}`}
        style={{ minWidth: '40px' }}
      >
        {props.text}
      </Avatar>
    </Badge>
  );
};

export const AvatarStatus = (props) => {
  const {
    color,
    badge,
    name,
    suffix,
    subTitle,
    id,
    type,
    src,
    icon,
    size,
    shape,
    gap,
    text,
    index,
    onNameClick,
    copyTitle,
    copySubtitle,
    onError: handleBrokenImage,
  } = props;

  const { useBreakpoint } = Grid;
  return (
    <div className="avatar-status d-flex align-items-center">
      {color
        ? renderAvatarColor({ icon, src, type, size, shape, gap, text, index })
        : badge
        ? renderAvatarBadge({
            icon,
            src,
            type,
            size,
            shape,
            gap,
            text,
            index,
            badge,
          })
        : renderAvatar({ icon, src, type, size, shape, gap, text, index })}
      <div className="ml-2">
        {name ? (
          <div>
            {onNameClick ? (
              <div
                onClick={() => onNameClick({ name, subTitle, src, id })}
                className="avatar-status-name clickable"
              >
                {name}
              </div>
            ) : (
              <div className="avatar-status-name text-break">
                {name}
                {copyTitle ? <CopyText text={name} /> : ''}
              </div>
            )}
            <span>{suffix}</span>
          </div>
        ) : null}
        {subTitle ? (
          <div
            className={`text-muted ${
              utils.isTablet(useBreakpoint) ? '' : 'avatar-status-subtitle'
            } text-break`}
          >
            {utils.string_cut(subTitle,12)}
          </div>
        ) : null}
      </div>
    </div>
  );
};

AvatarStatus.propTypes = {
  src: PropTypes.string,
  type: PropTypes.string,
  onNameClick: PropTypes.func,
};

export default AvatarStatus;
