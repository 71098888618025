import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import NavLanguage from 'components/layout-components/NavLanguage';
import Loading from 'components/shared-components/Loading';
import loadable from '@loadable/component';

const Login = loadable(() => import('./authentication/login'));
const Company = loadable(() => import('./authentication/company'));
const Forgot = loadable(() => import('./authentication/forgot-password'));
const NotFound = loadable(() => import('../errors/findProject'));
const Error = loadable(() => import('../errors/404'));

export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <div
        style={{
          position: 'absolute',
          left: '100%',
          marginLeft: -60,
          zIndex: 1,
        }}>
        <NavLanguage dark />
      </div>
      <Routes>
        <Route
          path={`/login`}
          element={<Login />} 
        />
        <Route
          path={`/company`}
          element={<Company />}
        />
        <Route
          path={`/forgot-password`}
          element={<Forgot />}
        />
        <Route
          path={`/notfound`}
          element={<NotFound />}
        />
        <Route
          path={`*`}
          element={<Error />}
        />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
