import React, { useState, useEffect, useContext } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';
import {
  Card,
  Row,
  Col,
  Form,
  Button,
  Input,
  Grid,
  Tooltip as AntDToolTip,
  Spin,
  DatePicker,
  Select,
  Tabs,
  message,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  UserOutlined,
} from '@ant-design/icons';
import ChartWidget from 'components/shared-components/ChartWidget';

import {
  CCULOGS_QUERY,
} from '../../../../graphql/query/analytics';
const { Option } = Select;
const { TabPane } = Tabs;

const MAX_SEARCH_MONTH = 3;

const colResponsiveProps = {
  xs: 24,
  sm: 24,
  md: 12,
  lg: 12,
  xl: 12,
  style: { marginTop: 24 },
};

const today = moment().endOf('day').format();


const AppViews = () => {
  const { useBreakpoint }  = Grid;

  const { formatMessage, formatNumber } = useIntl();
  
  const [firstDate, setFirstDate] = useState(moment().subtract(0, 'days').startOf('day').format('YYYY-MM-DD'));
  const [secondDate, setSecondDate] = useState(moment().subtract(1, 'days').startOf('day').format('YYYY-MM-DD'));
  const [threeDate, setThreeDate] = useState(moment().subtract(2, 'days').startOf('day').format('YYYY-MM-DD'));
  
  const [ccuChartData, setCcuChartData] = useState();


  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: firstDate,
    endDate: secondDate,
  });
  const [form] = Form.useForm();
  const params = useParams();
  const height = 600;
  
  const CcuAnalyticsData = useQuery(CCULOGS_QUERY, {
    variables: {
      projectId: params.projectId,
      firstDate: firstDate,
      secondDate: secondDate,
      threeDate: threeDate,
    },
    fetchPolicy: 'network-only',
  });

  // CCU
  useEffect(() => {
    if (CcuAnalyticsData?.loading === true) {
      setCcuChartData({ loading: true });
      return;
    }
    
    const edges = CcuAnalyticsData?.data?.cculogs?.edges;
    let dataArray = [];
    let categories = [];

    const seriesData = [
      {
        name: CcuAnalyticsData.variables.firstDate,
        data: [],
      },
      {
        name: CcuAnalyticsData.variables.secondDate,
        data: [],
      },
      {
        name: CcuAnalyticsData.variables.threeDate,
        data: [],
      },
    ];

    if (edges) {
      edges.map((item) => {
        categories.push(item.node.createdAt);
        seriesData[0].data.push(item.node.one ?? 0);
        seriesData[1].data.push(item.node.two ?? 0);
        seriesData[2].data.push(item.node.three ?? 0);
      });
    }

    setCcuChartData({
      series: seriesData,
      categories,
      loading: false,
    });
  }, [CcuAnalyticsData]);



  const onFirstChange = (date) => {
    if (!date) {
      return;
    }
   
    setFirstDate(moment(date).format('YYYY-MM-DD'));
  };
  const onSecondChange = (date) => {
    if (!date) {
      return;
    }
   
    setSecondDate(moment(date).format('YYYY-MM-DD'));
  };
  const onThreeChange = (date) => {
    if (!date) {
      return;
    }
   
    setThreeDate(moment(date).format('YYYY-MM-DD'));
  };

  return (
    <div>
      <Row gutter={24}>
        <Col span={24}>
        <div>
             <Form 
              form={form}
        className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile' : ''}`}
        layout="inline"
        >
                    <Form.Item>
                      <DatePicker
                        size="small"
                        placeholder={formatMessage({
                          id: 'member.ccu.datepicker.placeholder',
                        })}
                        style={{ marginRight: '5px', width: 130, marginBottom: 5 }}
                        format="YYYY-MM-DD"
                        onChange={onFirstChange}
                        value={moment(firstDate)}
                      />
                       <DatePicker
                        size="small"
                        placeholder={formatMessage({
                          id: 'member.ccu.datepicker.placeholder',
                        })}
                        style={{ marginRight: '5px', width: 130, marginBottom: 5 }}
                        format="YYYY-MM-DD"
                        onChange={onSecondChange}
                        value={moment(secondDate)}
                      />
                       <DatePicker
                        size="small"
                        placeholder={formatMessage({
                          id: 'member.ccu.datepicker.placeholder',
                        })}
                        style={{ marginRight: '5px', width: 130, marginBottom: 5 }}
                        format="YYYY-MM-DD"
                        onChange={onThreeChange}
                        value={moment(threeDate)}
                      />
                    
                    </Form.Item>
             
          </Form>
          </div>
        </Col>
      
      </Row>
      
      <Row gutter={24}>
          <Col span={24}>
          {ccuChartData ? (
                <ChartWidget
                title={formatMessage({ id: 'dashboards.chart.title.ccu' })}
                type="area"
                  extra={ccuChartData.updatedAt}
                  series={ccuChartData.series}
                  categories={ccuChartData.categories}
                  loading={ccuChartData.loading}
                  height={height}
                />
              ) : (
                ''
              )}
          </Col>
      </Row>

    </div>
  );
};

export default AppViews;