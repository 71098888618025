import React, { useState, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Badge, Grid, Checkbox, Divider, message } from 'antd';
import { CommentOutlined } from '@ant-design/icons';
import moment from 'moment';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import Loading from 'components/shared-components/Loading';
import { COLOR_1 } from 'constants/ChartConstant';
import utils from '../../../utils';
import InfiniteScroll from 'react-infinite-scroller';

const { useBreakpoint } = Grid;

const ChatMenu = (props) => {
  const { formatMessage } = useIntl();
  const isTablet = !utils.getBreakPoint(useBreakpoint()).includes('lg');

  const [channels, setChannels] = useState(props.channels);
  const [currentChannel, setCurrentChannel] = useState();
  const [channelsID, setChannelsID] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (props.channels) {
      const idArr = props.channels.map((item) => utils.getID(item.id));
      setChannelsID(idArr);
      setIndeterminate(
        !!props.checkedList.length && props.checkedList.length < idArr.length
      );
    }

    if (channels !== props.channels) {
      setChannels(props.channels);

      if (
        props.channels.length <= props.maxChannelSize &&
        props.hasMore === true
      ) {
        const elem = document.querySelector('#channel');
        if (elem) {
          elem && (elem.scrollTop = 0);
        }
      }
    }
  }, [props.channels]);

  //funtions
  const openChat = (channel) => {
    
      setCurrentChannel(utils.getID(channel.id));
      props.setChannel(channel);
    
  };

  const onChange = (list) => {
    props.setCheckedList(list);
    setIndeterminate(!!list.length && list.length < channelsID.length);
    setCheckAll(list.length === channelsID.length);
  };

  const onCheckAllChange = (e) => {
    props.setCheckedList(e.target.checked ? channelsID : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  };

  return (
    <div className="chat-menu">
      <div className="d-flex justify-content-between" style={{ padding: 10 }}>
        <Checkbox
          indeterminate={indeterminate}
          onChange={onCheckAllChange}
          checked={checkAll}
        >
          <FormattedMessage id="chat.selecteAll" />
        </Checkbox>
        {props.checkedList.length} <FormattedMessage id="chat.selected" />
      </div>

      <Divider className="m-0" />

      <div className={`chat-menu-list ${isTablet && 'mobile'}`} id="channel">
        <InfiniteScroll
          initialLoad={false}
          pageStart={0}
          threshold={40}
          loadMore={() => {
            if (props.loadChannel) props.loadChannel();
          }}
          isReverse={false}
          useWindow={false}
          hasMore={props.hasMore}
        >
          <Checkbox.Group
            className="w-100"
            value={props.checkedList}
            onChange={onChange}
          >
            {channels ? (
              channels.map((item, i) => (
                <div
                  key={`chat-item-${item.id}`}
                  className={`chat-menu-list-item ${
                    i === channels.length - 1 ? 'last' : ''
                  } ${
                    utils.getID(item.id) === currentChannel ? 'selected' : ''
                  }`}
                >
                  <Checkbox value={utils.getID(item?.id)} />
                  <div
                    className="d-flex justify-content-between"
                    onClick={() => openChat(item)}
                  >
                    <div>
                      <AvatarStatus
                        color={utils.isEmpty(item?.image_url)}
                        src={item?.image_url}
                        icon={
                          utils.isEmpty(item?.image_url) ? undefined : (
                            <CommentOutlined />
                          )
                        }
                        index={i}
                        text={item?.name ? item.name.substr(0, 1) : ''}
                        name={item.name}
                        subTitle={
                          <div
                            className={`text-muted ${
                              isTablet ? '' : 'avatar-status-subtitle'
                            }`}
                          >
                            <div
                              className={`text-muted ${
                                isTablet ? '' : 'avatar-status-subtitle'
                              }`}
                            >
                              {utils.getID(item.id)}
                            </div>
                            {item.unique_id ? (
                              <div
                                className={`text-muted ${
                                  isTablet ? '' : 'avatar-status-subtitle'
                                }`}
                              >
                                Unique ID : {item.unique_id}
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        }
                      />
                    </div>

                    <div className="text-right">
                      <div className="chat-menu-list-item-time">
                        {moment(item.updated_at).format('YYYY-MM-DD HH:mm:ss')}
                      </div>
                      {item.unread === 0 ? (
                        <span></span>
                      ) : (
                        <Badge count={0} style={{ backgroundColor: COLOR_1 }} />
                      )}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div>
                <Loading cover="content" />
              </div>
            )}
          </Checkbox.Group>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default ChatMenu;
