import React, { lazy, Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import Loading from 'components/shared-components/Loading';
import Apps from '../app-views/apps/';
export const AppViews = ({ match }) => {
  return (
    <Suspense fallback={<Loading cover="page" />}>
      <Routes>
        <Route path='*' element={<Apps />} />
      </Routes>
    </Suspense>
  );
};

export default AppViews;
