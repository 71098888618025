import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Card, Tooltip as AntDToolTip } from 'antd';
import { useIntl } from 'react-intl';
import { CSVLink } from 'react-csv';
import moment from 'moment';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { InfoCircleOutlined, DownloadOutlined } from '@ant-design/icons';


const titleStyle = {
  position: 'absolute',
  zIndex: '1',
};

const extraStyle = {
  position: 'absolute',
  zIndex: '1',
  right: '0',
  top: '-2px',
};

const ChartWidget = ({ title, series, description, categories, card, extra, loading, type, csv = true }) => {
  const intl = useIntl();
  const isMobile = window.innerWidth < 768;
  const extraRef = useRef(null);
  const chartRef = useRef(null);
  const { formatMessage, formatNumber } = useIntl();
  
  const csvHeaders = [
    {
      label: formatMessage({ id: 'statistics.table.date' }),
      key: 'date',
    },
    {
      label: formatMessage({ id: 'statistics.table.count' }),
      key: 'count',
    },
  ];
  const getCsvData = () => {
   
    let csvData = [];
    
    if (series) {
      series.map((item) => {
        item.data.map((dt) => {
          csvData.push({ "date": dt[0], "count": dt[1]});
        });
        
      });
    }
    return csvData;
  };

  useEffect(() => {
    let chartObj = chartRef.current?.chart;

    if (chartObj && loading) {
      chartObj.showLoading(
        intl.formatMessage({ id: 'common.message.loading' })
      );
      return;
    }
    chartObj.hideLoading();
  }, [loading]);

  let chartOptions = {
    title: {
      text: undefined,
    },
    credits: false,
    chart: {
      type,
      backgroundColor: 'transparent',
      zoomType: 'x',
      marginTop: 50,
      
    },
    xAxis: {
      type: 'category',
      categories: categories,
    },
    yAxis: {
      title: { text: null },
      min: 0,
    },
    legend: {
      enabled: true,
    },
   
    tooltip: {
      formatter: function () {
        return `<span style="font-size: 10px">${
          this.key
        }</span><br/><span style="color:${this.point.color}">●</span> ${
          this.series.name
        }: <b>${intl.formatNumber(this.point.y)}</b><br/>`;
      },
    },
    series: series,
    plotOptions: {
      
      series: {
        fillOpacity: 0.4,
        lineWidth: 1,
        animation: false,
      },
      column: {
        minPointLength: 2,
        pointPadding: 0,
      },
    },
    navigation: {
      buttonOptions: {
        enabled: true
      },
    },
  };

  return (
    <>
      {card ? (
        <Card
         title={
          <div>
            <span style={{ color: '#187ed6' }}>
            {title}
            </span>
            {/* <span style={{ marginLeft: 10, color: '#187ed6' }}>
              <AntDToolTip title={description}>
                <InfoCircleOutlined />
              </AntDToolTip>
            </span> */}
          </div>
        }
        extra=
          {csv == true ? (
          <CSVLink
              filename={`cloudchat_analytics_${title}_${moment().format('YYYYMMDDHHmmss')}.csv`}
              headers={csvHeaders}
              asyncOnClick={true}
              data={getCsvData()}
              onClick={(event, done) => {
                  done(true); // REQUIRED to invoke the logic of component
              }}
            >
              <DownloadOutlined style={{ fontSize: 15, fontStyle: 'bold' }} />
            </CSVLink>
          ) : null}
        >
          <div className="position-relative">
           
            {extra && (
              <div ref={extraRef} style={!isMobile ? extraStyle : {}}>
                {extra}
              </div>
            )}
            <div>
              <HighchartsReact
                highcharts={Highcharts}
                options={chartOptions}
                ref={(el) => {
                  chartRef.current = el;
                }}
              />
            </div>
          </div>
        </Card>
      ) : (
        <div>
          <HighchartsReact highcharts={Highcharts} options={chartOptions} />
        </div>
      )}
    </>
  );
};

ChartWidget.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  series: PropTypes.array.isRequired,
  xAxis: PropTypes.array,
  customOptions: PropTypes.object,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  card: PropTypes.bool,
  type: PropTypes.string,
};

ChartWidget.defaultProps = {
  series: [],
  height: 300,
  width: '100%',
  card: true,
  type: 'line',
};

export default ChartWidget;
