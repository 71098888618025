import React from 'react';
import { UserOutlined, LockOutlined } from '@ant-design/icons';
import { Menu } from 'antd';
import { Link, Navigate, Route, Routes } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import InnerAppLayout from 'layouts/inner-app-layout';
import EditProfile from './EditProfile';
import ChangePassword from './ChangePassword';

const ProfileMenu = ({  }) => {
  return (
    <Menu
      defaultSelectedKeys={`/edit-profile`}
      defaultOpenKeys={`/edit-profile`}
      mode="inline"
      selectedKeys={[window?.location?.pathname?.split('/').pop()]}
    >
      <Menu.Item key={`edit-profile`}>
        <UserOutlined />
        <span>
          <FormattedMessage id="profile.menu1" />
        </span>
        <Link to={'edit-profile'} />
      </Menu.Item>
      <Menu.Item key={`change-password`}>
        <LockOutlined />
        <span>
          <FormattedMessage id="profile.menu2" />
        </span>
        <Link to={'change-password'} />
      </Menu.Item>
    </Menu>
  );
};

const ProfileContent = ({ match }) => {
  return (
    <Routes>
      <Route exact path='/' element={<Navigate to="edit-profile" />}/>
      <Route path='/edit-profile/*' element={<EditProfile />} />
      <Route path='/change-password/*' element={<ChangePassword />} />
    </Routes>
  );
};

const Profile = (props) => {
  return (
    <InnerAppLayout
      sideContentWidth={320}
      sideContent={<ProfileMenu {...props} />}
      mainContent={<ProfileContent {...props} />}
    />
  );
};


export default Profile;
