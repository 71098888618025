import React from 'react';
import { Avatar, Form, Drawer, Divider, Button, Grid, Row, Col } from 'antd';
import {
  CalendarOutlined,
  MessageOutlined,
  TeamOutlined,
  InfoCircleOutlined,
  CloseCircleOutlined,
  UserOutlined,
} from '@ant-design/icons';
import CopyText from 'components/shared-components/CopyText';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';



const DetailView = (props) => {
  const { data, visible, close } = props;
  const { useBreakpoint } = Grid;

  const { formatMessage, formatNumber } = useIntl();


const status = [
  formatMessage({ id: 'message.push.default' }),
  formatMessage({ id: 'message.push.success' }),
  formatMessage({ id: 'message.push.error' }),
  formatMessage({ id: 'message.push.processing' }),
];
  const getStatus = record => {
    if (record.status === 2 || record.status === 1) {
      const time = moment(record.scheduleAt);
      const current = moment();

      let sending = false;

      // console.log(time.diff(current));
      if (record.send_type === 'schedule_global') {
        if (time.diff(current) < 10 * 60 * 1000) {
          if (record.status === 1) {
            // console.log("ddddd")
            sending = true;
          }
        }
      }

      // console.log(sending)

      if (sending) {
        return <FormattedMessage id="message.push.sent" />;
      }

      return record.status === 1 && record.send_type === 'schedule_global'
        ? formatMessage({ id: 'message.push.sending' })
        : status[record.status];
    }
    return status[record.status];
  };
  return (
    <Drawer
      width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
      placement="right"
      onClose={close}
      closable={false}
      open={visible}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            size="small"
            className="ml-1"
            icon={<CloseCircleOutlined className="mr-2" />}
            onClick={close}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
        </div>
      }
    >
     
       {data.status === 1 ? (
        <Form
          layout="vertical"
        >
             <Row className="mt-2">
              <Col span={5}>
                <FormattedMessage id="message.push.detail.status" />
              </Col>
              <Col >{getStatus(data)}</Col>
            </Row>
            {data.target &&
              Array.isArray(data.target) &&
              data.target.length > 0 && (
                <Row className="mt-2">
                  <Col span={5}>
                    <FormattedMessage id="message.push.detail.target" />
                  </Col>
                  <Col >{data.target.join(', ')}</Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col span={5}>
                <FormattedMessage id="message.push.detail.platform" />
              </Col>
              <Col span={5}>
                {data.channel === 'default' ? (
                  <div>
                    <img
                      title="android"
                      alt="android"
                      style={{ width: '16px' }}
                      src={utils.getPlatformIcon('android')}
                    />
                    <img
                      title="ios"
                      alt="ios"
                      style={{ width: '16px' }}
                      src={utils.getPlatformIcon('ios')}
                    />
                  </div>
                ) : (
                  <img
                    title={data.channel}
                    alt={data.channel}
                    style={{ width: '16px' }}
                    src={utils.getPlatformIcon(data.channel)}
                  />
                )}
              </Col>
            </Row>
            {data.title &&
              data.title.length > 0 &&
              !utils.isEmpty(data.title[0].value) && (
                <Row className="mt-2">
                  <Col span={5}>
                    <FormattedMessage id="message.push.detail.subject" />
                  </Col>
                  <Col >{data.title.map(item => item.value)}</Col>
                </Row>
              )}
              <Row className="mt-2">
                <Col span={5}>
                <FormattedMessage id="message.push.detail.message" />
              </Col>
              <Col span={5}>
                {data.message.map(item => {
                  return (
                    <div>
                      <span style={{ color: '#187ed6', fontWeight: 'bold' }}>
                        {formatMessage({ id: `language.${item.lang}` })}
                      </span>
                      <br />
                      {data.image.map(imageItem => {
                        if (imageItem.lang === item.lang) {
                          return (
                            <img
                              style={{ width: '16px' }}
                              src={`${props.baseUrl}${imageItem.value}`}
                              alt={imageItem.value}
                            />
                          );
                        }
                      })}
                      <br />
                      <span>{item.value}</span>
                    </div>
                  );
                })}
              </Col>
            </Row>
            <Row className="mt-2">
                <Col span={5}>
                <FormattedMessage id="message.push.detail.type" />
              </Col>
              <Col span={5}>
                {data.send_type === 'direct'
                  ? formatMessage({ id: 'message.push.detail.type.direct' })
                  : data.send_type === 'schedule'
                    ? formatMessage({ id: 'message.push.detail.type.reserved' })
                    : formatMessage({ id: 'message.push.schduletype.schedule_global' })}
              </Col>
            </Row>
            {data.send_type !== 'direct' && (
              <Row className="mt-2">
                <Col span={5}>
                  <FormattedMessage id="message.push.detail.scheduleat" />
                </Col>
                <Col >{utils.getDate(data.scheduleAt)}</Col>
              </Row>
            )}
            {data.send_type === 'schedule_global' && (
              <Row className="mt-2">
                <Col span={5}>Schedule ID : </Col>
                <Col span={5}>{data.sens_schedule_id}</Col>
              </Row>
            )}
            <Row className="mt-2">
              <Col span={5}>
                <FormattedMessage id="message.push.detail.completeat" />
              </Col>
              <Col span={5}>{utils.getDate(data.completedAt)}</Col>
            </Row>
        </Form>
        ) : null}
        <Row>
          <Col span={5}>
            <FormattedMessage id="message.push.detail.response" />
          </Col>
          <Col span={10}>{data.response}</Col>
        </Row>
        
    </Drawer>
  );
};

export default DetailView;
