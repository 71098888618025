import React, { useEffect, useState, useRef } from 'react';
import validator from 'validator';
import { PlusOutlined } from '@ant-design/icons';
import { Tag, Input, Tooltip, message } from 'antd';
import { FormattedMessage, useIntl } from 'react-intl';
import Utils from 'utils';

const KeyGroup = (props) => {
  const { formatMessage } = useIntl();
  const inputRef = useRef(null);
  const editRef = useRef(null);

  const [keyList, setKeyList] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [editInputIndex, seteditInputIndex] = useState(-1);
  const [editInputValue, seteditInputValue] = useState('');
  const [refState, setRefState] = useState(null);
  const [editRefState, setEditRefState] = useState(null);

  useEffect(() => {
    let keyList = [];

    if (props.data) keyList = props.data;

    setKeyList(keyList);
  }, [props.data]);

  useEffect(() => {
    setInputVisible(false);
    setInputValue('');
    seteditInputIndex(-1);
    seteditInputValue('');
  }, [props.isMutation]);

  useEffect(() => {
    inputRef.current = refState;
    if (inputRef?.current) inputRef.current.focus();
  }, [refState]);

  useEffect(() => {
    editRef.current = editRefState;
    if (editRef?.current) editRef.current.focus();
  }, [editRefState]);

  // Functions
  const showInput = () => {
    setInputVisible(true);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleEditInputChange = (e) => {
    seteditInputValue(e.target.value);
  };

  const handleClose = (removedTag) => {
    const keyListData = keyList.filter((tag) => tag !== removedTag);

    setKeyList(keyListData);

    if (props.handleChange) props.handleChange(keyListData);
  };

  const handleEditInputConfirm = () => {


    if (Utils.isEmpty(editInputValue)) {
      seteditInputIndex(-1);
      seteditInputValue('');
      return;
    }

    if (props.type === 'domain') {
      if (
        editInputValue.startsWith('http://') === false &&
        editInputValue.startsWith('https://') === false
      ) {
        message.error(formatMessage({ id: 'settings.security.domain.error1' }));
        return;
      }

      if (!Utils.isHttpURL(editInputValue)) {
        if (editInputValue.includes('localhost')) {
          message.error(
            formatMessage({ id: 'settings.security.domain.error3' })
          );
          return;
        }

        message.error(formatMessage({ id: 'settings.security.domain.error2' }));
        return;
      }
    }

    if (props.type === 'ip' && Utils.isIP(editInputValue) === false) {
      message.error(formatMessage({ id: 'settings.security.ip.error1' }));
      return;
    }
   

    const newTags = [...keyList];
    newTags[editInputIndex] = editInputValue;

    setKeyList(newTags);
    seteditInputIndex(-1);
    seteditInputValue('');
  };

  const handleInputConfirm = () => {
    let temp = '';
    let list = keyList;


    if (inputValue) {
      if (Utils.isEmpty(inputValue)) return;

      if (props.type === 'domain') {
        if (
          inputValue.startsWith('http://') === false &&
          inputValue.startsWith('https://') === false
        ) {
          message.error(
            formatMessage({ id: 'settings.security.domain.error1' })
          );
          return;
        }

        if (!Utils.isHttpURL(inputValue)) {
          if (inputValue.includes('localhost')) {
            message.error(
              `${formatMessage({ id: 'settings.security.domain.error3' })}`
            );
            return;
          }

          message.error(
            `${formatMessage({ id: 'settings.security.domain.error2' })}`
          );
          return;
        }
      }

      if (props.type === 'ip' && Utils.isIP(inputValue) === false) {
        message.error(formatMessage({ id: 'settings.security.ip.error1' }));
        return;
      }
      if (props.type == 'ext' && inputValue.startsWith("image", 0 ) === false) {
        message.error(formatMessage({ id: 'settings.security.ext.notimage' }));
        return;
      }

      temp = inputValue;

      keyList.map((item) => {
        if (item === inputValue) {
          temp = '';
        }
      });

      if (!Utils.isEmpty(temp)) list = [...keyList, temp];
    }

    setKeyList(list);
    setInputVisible(false);
    setInputValue('');

    if (props.handleChange) props.handleChange(list);
  };

  return (
    <div>
      {keyList?.length > 0 &&
        keyList?.map((hashKey, index) => {
          if (editInputIndex === index) {
            return (
              <Input
                style={{ width: 150, marginRight: 10 }}
                ref={(ref) => {
                  if (ref && editRefState === null) setEditRefState(ref);
                }}
                key={hashKey}
                type="text"
                size="small"
                value={editInputValue}
                onChange={handleEditInputChange}
                onBlur={handleEditInputConfirm}
                onPressEnter={handleEditInputConfirm}
              />
            );
          }

          const isLongTag = hashKey.length > 30;
          const tagElem = (
            <Tag key={hashKey} closable onClose={() => handleClose(hashKey)}>
              <span
                onDoubleClick={(e) => {
                  seteditInputIndex(index);
                  seteditInputValue(hashKey);
                  e.preventDefault();
                }}
              >
                {isLongTag ? `${hashKey.slice(0, 30)}...` : hashKey}
              </span>
            </Tag>
          );
          return isLongTag ? (
            <Tooltip title={hashKey} key={hashKey}>
              {tagElem}
            </Tooltip>
          ) : (
            tagElem
          );
        })}
      {inputVisible && (
        <Input
          style={{ width: 150 }}
          ref={(ref) => {
            if (ref && refState === null) setRefState(ref);
          }}
          type="text"
          size="small"
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && (
        <Tag
          onClick={showInput}
          style={{ background: '#fff', borderStyle: 'dashed' }}
        >
          <PlusOutlined /> <FormattedMessage id="common.add2" />
        </Tag>
      )}
    </div>
  );
};

export default KeyGroup;
