import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { useParams } from 'react-router';
import {
  Table,
  Tooltip,
  message,
  Button,
  Input,
  Select,
  DatePicker,
  Form,
  Grid,
  Popconfirm,
} from 'antd';
import {
  EyeOutlined,
  DeleteOutlined,
  SearchOutlined,
  FileExcelOutlined,
  UserOutlined,
} from '@ant-design/icons';
import moment from 'moment';
import { FormattedMessage, useIntl } from 'react-intl';
import LinesEllipsis from 'react-lines-ellipsis';
import CopyText from 'components/shared-components/CopyText';
import AvatarStatus from 'components/shared-components/AvatarStatus';
import utils from 'utils';

import { AUDITS_QUERY } from '../../../graphql/query/audit';
const { Option } = Select;
const { RangePicker } = DatePicker;

const oneMonthAgo = moment().subtract(29, 'days').startOf('day').format();
const today = moment().endOf('day').format();

const searchData = [
  { name: <FormattedMessage id="audit.columns.sender.id" />, id: 'sender.id' },
  {
    name: <FormattedMessage id="audit.columns.sender.name" />,
    id: 'sender.name',
  },
  {
    name: <FormattedMessage id="audit.columns.filename" />,
    id: 'filename',
  },
  {
    name: <FormattedMessage id="audit.columns.channel_id" />,
    id: 'channel_id',
  },
];

const View = ({ match, current_profile }) => {
  const [form] = Form.useForm();
  const { formatMessage, formatNumber } = useIntl();
  const params = useParams();
  const { useBreakpoint }  = Grid;
  // States
  const [users, setUsers] = useState();
  const [userProfileVisible, setUserProfileVisible] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [pagination, setPagination] = useState({
    pageSize: 20,
    current: 1,
    total: 0,
    showSizeChanger: false,
  });
  const [search, setSearch] = useState({
    search: undefined,
    query: undefined,
    startDate: oneMonthAgo,
    endDate: today,
  });
  const [sort, setSort] = useState({
    sort_id: undefined,
    sort: undefined,
  });

  // Queries
  const getData = useQuery(AUDITS_QUERY, {
    variables: {
      projectId: params.projectId,
      filter: JSON.stringify({
        project_id: params.projectId,
        [search.search]: search.query,
        startDate: search.startDate,
        endDate: search.endDate,
      }),
      sort: JSON.stringify({
        [sort.sort_id || 'created_at']: sort.sort === 'asc' ? 1 : -1,
      }),
      option: JSON.stringify({
        offset: (pagination.current - 1) * 20,
        per_page: pagination.pageSize,
      }),
    },
    fetchPolicy: 'network-only',
  });



  useEffect(() => {
    if (getData?.data?.messages) {
      let messages = getData.data.messages;

      setUsers(messages.edges);
      setPagination({ ...pagination, total: messages.totalCount });
    }
  }, [getData]);

  // Functions
  const handleTableChange = (pagination, filters, sorter) => {
    if (sorter?.order) {
      setSort({
        sort_id: sorter.columnKey,
        sort: sorter.order === 'ascend' ? 'asc' : 'desc',
      });
    } else {
      setSort({
        sort_id: undefined,
        sort: undefined,
      });
    }

    setPagination({ ...pagination, current: pagination.current });
  };

  const onSearch = (values) => {
    if (
      values?.search === 'content' &&
      values?.query &&
      values?.query?.length < 3
    ) {
      message.error({
        content: formatMessage({
          id: 'search.range.message.error2',
        }),
      });
      return;
    }

    let startDate = undefined;
    let endDate = undefined;
    if(values.range !== undefined && values.range)
    {
      startDate = values?.range[0];
      endDate = values?.range[1];
    }
   

    setPagination({ ...pagination, current: 1 });
    setSearch({
      query: values.query,
      search: values.search,
      startDate,
      endDate,
    });
  };

  const showUserProfile = (userInfo) => {
    setUserProfileVisible(true);
    setSelectedUser(userInfo);
  };

  const closeUserProfile = () => {
    setUserProfileVisible(false);
    setSelectedUser(null);
  };

  const tableColumns = [
    {
      title: <FormattedMessage id="audit.columns.id" />,
      dataIndex: 'node',
      key: 'sender.id',
      sorter: true,
      render: (row) => (
        <div className="d-flex" style={{ maxWidth: '500px' }}>
          <AvatarStatus
            src={row?.sender?.profile}
            name={row?.sender?.name}
            subTitle={row?.sender?.senderid}
            icon={<UserOutlined />}
            copyTitle
            copySubtitle
          />
        </div>
      ),
    },
    {
      title: <FormattedMessage id="audit.columns.service" />,
      dataIndex: ['node', 'service'],
      key: 'service',
      className: 'td-break',
      sorter: true,
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '700px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="audit.columns.task" />,
      dataIndex: ['node', 'task'],
      key: 'task',
      className: 'td-break',
      sorter: true,
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '700px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="audit.columns.ip" />,
      dataIndex: ['node', 'ip'],
      key: 'ip',
      className: 'td-break',
      sorter: true,
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '700px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    {
      title: <FormattedMessage id="audit.columns.detail" />,
      dataIndex: ['node', 'detail'],
      key: 'detail',
      className: 'td-break',
      sorter: true,
      render: (row) => {
        return (
          <LinesEllipsis
            style={{ maxWidth: '700px' }}
            text={utils.getMessage(row)}
            maxLine="3"
            basedOn="letters"
          />
        );
      },
    },
    
    {
      title: <FormattedMessage id="audit.columns.created_at" />,
      key: undefined,
      dataIndex: ['node', 'created_at'],
      sorter: true,
      render: (row) => (
        <span>{moment(row).format('YYYY-MM-DD HH:mm:ss')} </span>
      ),
    },
    {
      title: '',
      dataIndex: 'actions',
      width: 90,
      render: (_, elm) => (
        <div className="text-right">
          <Tooltip title={<FormattedMessage id="audit.tooltip.view" />}>
            <Button
              type="primary"
              className="mr-2"
              icon={<EyeOutlined />}
              onClick={() => {
                showUserProfile(elm.node);
              }}
              size="small"
            />
          </Tooltip>
          
        </div>
      ),
    },
  ];

  return (
    <div>
      <Form
        form={form}
        className={`${utils.isTablet(useBreakpoint()) ? 'search-mobile' : ''}`}
        layout="inline"
        onFinish={onSearch}
        initialValues={{
          search: 'sender.id',
          period: [moment(search.startDate), moment(search.endDate)],
        }}
      >
        <div>
          <Form.Item name="period">
            <RangePicker allowClear={false} showTime size="small" locale />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="search">
            <Select
              className="w-100"
              style={{ minWidth: 150 }}
              placeholder="Category"
              size="small"
            >
              {searchData.map((elm) => (
                <Option key={elm.id} value={elm.id}>
                  {elm.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </div>
        <div>
          <Form.Item name="query">
            <Input placeholder="Search" size="small" />
          </Form.Item>
        </div>
        <div>
          <Form.Item name="button_submit">
            <Button
              type="primary"
              icon={<SearchOutlined className="mr-2" />}
              block
              htmlType="submit"
              size="small"
              loading={getData?.loading}
            >
              <FormattedMessage id="common.search" />
            </Button>
          </Form.Item>
        </div>
      </Form>
      <div className="table-responsive">
        <Table
          scroll={{ x: 1000 }}
          style={{ marginTop: 10 }}
          loading={getData?.loading}
          pagination={pagination}
          onChange={handleTableChange}
          columns={tableColumns}
          dataSource={users}
          rowKey={(record) => record.node.message_id}
          size="small"
          footer={() => {
            return (
              <div>
                <FormattedMessage id="common.total.1" />
                {pagination?.total ? formatNumber(pagination.total) : 0}
                <FormattedMessage id="common.total.2" />
              </div>
            );
          }}
        />
        
      </div>
    </div>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(View);
