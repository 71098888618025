import React from 'react';
import { useMutation } from '@apollo/client';
import { Button, message, Popconfirm } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import utils from 'utils';

import {
  DELETE_MEMBER_MUTATION,
  RECOVER_MEMBER_MUTATION,
} from '../../../../graphql/mutations/member';

const UserDelete = (props) => {
  const { formatMessage } = useIntl();
  const { projectId, id, deleted, refetch } = props;

  // Mutation
  const [deleteMember] = useMutation(DELETE_MEMBER_MUTATION, {
    onCompleted(result) {
      if (result?.deleteMember?.member?.id) {
        message.success({
          content: formatMessage({
            id: 'member.list.delete.message.withdraw.success',
          }),
          key: 'deleteMember',
        });

        if (refetch) refetch();
      } else {
        message.error({
          content: formatMessage({
            id: 'member.list.delete.message.withdraw.fail',
          }),
          key: 'deleteMember',
        });
      }
    },
  });

  const [recoverMember] = useMutation(RECOVER_MEMBER_MUTATION, {
    onCompleted(result) {
      if (result?.recoverMember?.member?.id) {
        refetch();
        message.success({
          content: formatMessage({
            id: 'member.list.delete.message.recover.success',
          }),
          key: 'recoverMember',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'member.list.delete.message.recover.fail',
          }),
          key: 'recoverMember',
        });
      }
    },
  });

  const onDelete = () => {
    message.loading({
      content: formatMessage({
        id: 'member.list.delete.message.withdraw',
      }),
      key: 'deleteMember',
      duration: 0,
    });

    deleteMember({ variables: { projectId, id } });
  };

  const onRecover = () => {
    message.loading({
      content: formatMessage({
        id: 'member.list.delete.message.recovering',
      }),
      key: 'recoverMember',
      duration: 0,
    });

    recoverMember({ variables: { projectId, id } });
  };

  return (
    <Popconfirm
      title={
        deleted === true ? (
          <FormattedMessage id="member.list.delete.popconfirm.title1" />
        ) : (
          <>
            <FormattedMessage id="member.list.delete.message.withdraw.confirm1" />
            <br />
            <FormattedMessage id="member.list.delete.message.withdraw.confirm2" />
          </>
        )
      }
      onConfirm={deleted === true ? onRecover : onDelete}
      placement={'topRight'}
      okText={<FormattedMessage id="common.ok" />}
      cancelText={<FormattedMessage id="common.no" />}
    >
      <Button
        className="ml-1"
        size="small"
        icon={<DeleteOutlined className="mr-2" />}
        danger
      >
        {deleted === true ? (
          <FormattedMessage id="member.list.delete.button1" />
        ) : (
          <FormattedMessage id="member.list.delete.button2" />
        )}
      </Button>
    </Popconfirm>
  );
};

export default UserDelete;
