import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import moment from 'moment';
import {
  Drawer,
  Button,
  Input,
  message,
  Form,
  Col,
  Row,
  DatePicker,
  Checkbox,
  Popconfirm,
  Grid,
  Radio,
} from 'antd';
import {
  SaveOutlined,
  DeleteOutlined,
  CloseCircleOutlined,
} from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';
import { connect } from 'react-redux';
import utils from 'utils';

import {
  CREATE_MEMBERBLOCK_MUTATION,
  DELETE_MEMBERBLOCK_MUTATION,
} from '../../../../graphql/mutations/member';

const options = [
  {
    label: <FormattedMessage id="member.list.block.form.label.period.1" />,
    value: '1',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.2" />,
    value: '7',
  },
  {
    label: <FormattedMessage id="member.list.block.form.label.period.3" />,
    value: '30',
  },
];

const UserView = (props) => {
  const {
    current_profile,
    blockData,
    projectId,
    refetch_members,
    visible,
    close,
  } = props;

  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const [periodOption, setPeriodOption] = useState('1');
  const [blockStatus, setBlockStatus] = useState(1);
  const { useBreakpoint } = Grid;
  const [createMemberBlock] = useMutation(CREATE_MEMBERBLOCK_MUTATION, {
    onCompleted(result) {
      if (result?.createMemberBlock?.memberBlock?.id) {
        refetch_members();
        onChildrenModalClose();

        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'createBlock',
        });
      }
    },
    onError(error) {
      message.error({
        content: formatMessage({
          id: 'common.message.save.fail',
        }),
        key: 'createBlock',
      });
    },
  });

  const [deleteMemberBlock] = useMutation(DELETE_MEMBERBLOCK_MUTATION, {
    onCompleted(result) {
      if (result?.deleteMemberBlock?.memberBlock?.id) {
        refetch_members();
        onChildrenModalClose();
        message.success({
          content: formatMessage({
            id: 'common.message.delete.success',
          }),
          key: 'deleteBlock',
        });
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.delete.fail',
          }),
          key: 'deleteBlock',
        });
      }
    },
  });

  // UseEffect
  useEffect(() => {
    if (utils.isEmpty(blockData)) {
      form.resetFields();
      return;
    }

    setField();
  }, [blockData]);

  useEffect(() => {
    if (utils.isEmpty(periodOption) === false) {
      form.setFieldsValue({
        period: [moment(), moment().add(periodOption, 'days')],
      });
    }
  }, [periodOption]);

  useEffect(() => {
    // 일시 정지
    if (blockStatus === 1) {
      setPeriodOption('1');
    }

    // 영구 정지
    if (blockStatus === 2) {
      setPeriodOption(null);

      form.setFieldsValue({
        period: [moment(), moment('2099').endOf('year')],
      });
    }
  }, [blockStatus]);

  // function
  const setField = () => {
    setPeriodOption(blockData ? null : '1');
    setBlockStatus(blockData?.ended_at.substr(0, 4) === '2099' ? 2 : 1);

    form.setFieldsValue({
      kick: false,
      message: blockData?.messageMulti[0]?.value,
      period: blockData
        ? [moment(blockData?.started_at), moment(blockData?.ended_at)]
        : [moment(), moment().add('1', 'days')],
    });
  };

  const deleteBlock = () => {
    message.loading({
      content: formatMessage({
        id: 'common.message.deleting',
      }),
      key: 'deleteBlock',
      duration: 0,
    });
    const variables = {
      projectId,
      id: utils.getID(blockData.id),
    };

    deleteMemberBlock({ variables });
  };

  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        if (utils.getID(current_profile?.id) === blockData?.member_id) {
          message.error({
            content: formatMessage({
              id: 'member.list.block.form.error1',
            }),
            key: 'createBlock',
          });
          return;
        }

        message.loading({
          content: formatMessage({
            id: 'common.message.updating',
          }),
          key: 'createBlock',
          duration: 0,
        });

        let variables = {
          projectId,
          id: utils.getID(blockData?.id),
          memberId: blockData?.member_id,
          status: 1,
          kick: values?.kick,
          startedAt: values?.period?.[0] || undefined,
          endedAt: values?.period?.[1] || undefined,
          messageMulti: [{ default: true, value: values?.message, lang: 'ko' }],
        };

        createMemberBlock({ variables });

        return;
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const onChildrenModalClose = () => {
    setField();
    close();
  };

  return (
    <>
      <Drawer
        title={<FormattedMessage id="member.list.block.title" />}
        open={visible}
        width={utils.isTablet(useBreakpoint()) ? '80%' : 500}
        bodyStyle={{ paddingBottom: 80 }}
        closable={false}
        onClose={close}
        forceRender
        destroyOnClose
        footer={
          <div className="text-right">
            <div
              style={{ display: 'inline-block' }}
              className={`${utils.isTablet(useBreakpoint()) ? 'mb-1' : ''}`}
            >
              <Button
                size="small"
                onClick={close}
                style={{ marginRight: 8 }}
                icon={<CloseCircleOutlined className="mr-2" />}
              >
                <FormattedMessage id="common.cancel" />
              </Button>
              <Popconfirm
                title={<FormattedMessage id="common.message.delete.confirm" />}
                onConfirm={deleteBlock}
                okText={<FormattedMessage id="common.ok" />}
                cancelText={<FormattedMessage id="common.no" />}
              >
                <Button
                  size="small"
                  danger
                  style={{ marginRight: 8 }}
                  icon={<DeleteOutlined className="mr-2" />}
                >
                  <FormattedMessage id="common.delete" />
                </Button>
              </Popconfirm>
            </div>
            <div style={{ display: 'inline-block' }}>
              <Button
                size="small"
                type="primary"
                style={{ marginRight: 8 }}
                onClick={() => form.submit()}
                icon={<SaveOutlined className="mr-2" />}
              >
                <FormattedMessage id="common.save" />
              </Button>
            </div>
          </div>
        }
      >
        <Form form={form} layout="vertical" onFinish={onSave}>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                label={
                  <FormattedMessage id="member.list.block.form.label.status" />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="member.list.block.form.rules.status" />
                    ),
                  },
                ]}
              >
                <Radio.Group
                  value={blockStatus}
                  onChange={(e) => setBlockStatus(e.target.value)}
                >
                  <Radio value={1}>
                    <FormattedMessage id="member.list.block.form.label.status.1" />
                  </Radio>
                  <Radio value={2}>
                    <FormattedMessage id="member.list.block.form.label.status.2" />
                  </Radio>
                </Radio.Group>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="kick"
                label={
                  <FormattedMessage id="member.list.block.form.label.kick" />
                }
                valuePropName="checked"
              >
                <Checkbox>
                  <FormattedMessage id="member.list.block.form.checkbox.kick" />
                </Checkbox>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="message"
                label={
                  <FormattedMessage id="member.list.block.form.label.message" />
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="member.list.block.form.rules.message" />
                    ),
                  },
                ]}
              >
                <Input
                  size="small"
                  style={{ width: '100%' }}
                  addonBefore="KR"
                  placeholder={formatMessage({
                    id: 'member.list.block.form.placeholder.message',
                  })}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={16}>
            <Col span={24}>
              <Form.Item
                name="period"
                label={
                  <div className="justify-content-between d-flex w-100">
                    <FormattedMessage id="member.list.block.form.label.period" />
                    <Radio.Group
                      className="itsb"
                      size="small"
                      optionType="button"
                      disabled={blockStatus === 2 ? true : false}
                      options={options}
                      value={periodOption}
                      onChange={(e) => setPeriodOption(e.target.value)}
                    />
                  </div>
                }
                rules={[
                  {
                    required: true,
                    message: (
                      <FormattedMessage id="member.list.block.form.rules.period" />
                    ),
                  },
                ]}
              >
                <DatePicker.RangePicker
                  className="w-100"
                  size="small"
                  showTime
                  disabled={blockStatus === 2 ? true : false}
                  getPopupContainer={(trigger) => trigger.parentNode}
                />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Drawer>
    </>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(UserView);
