import gql from 'graphql-tag';
const FIELD = `
id
`;
const CREATE_PUSH_MUTATION = gql`
  mutation createPush(
    $projectId: String
    $channel: String
    $message: [LanguageInput]
    $image: [LanguageInput]
    $title: [LanguageInput]
    $target: [String]
    $msgType: String
    $sendType: String
    $data: String
    $scheduleAt: String!
    $timezone: String
  ) {
    createPush(
      input: {
        projectId: $projectId
        channel: $channel
        message: $message
        image: $image
        title: $title
        target: $target
        msgType: $msgType
        sendType: $sendType
        data: $data
        scheduleAt: $scheduleAt
        timezone: $timezone
      }
    ) {
      push {
        ${FIELD}
      }
    }
  }
`;

const UPDATE_PUSH_MUTATION = gql`
  mutation updatePush(
    $id: ID!
    $status: Int
    $projectId: String
    $channel: String
    $message: [LanguageInput]
    $image: [LanguageInput]
    $title: [LanguageInput]
    $sendType: String
    $msgType: String
    $data: String
    $scheduleAt: String!
    $timezone: String
  ) {
    updatePush(
      input: {
        id: $id
        status: $status
        projectId: $projectId
        channel: $channel
        message: $message
        image: $image
        title: $title
        sendType: $sendType
        msgType: $msgType
        data: $data
        scheduleAt: $scheduleAt
        timezone: $timezone
      }
    ) {
      push {
        ${FIELD}
      }
    }
  }
`;

const DELETE_PUSH_MUTATION = gql`
  mutation deletePush($id: ID!, $projectId: String!) {
    deletePush(input: { id: $id, projectId: $projectId }) {
      push {
        ${FIELD}
      }
    }
  }
`;

export {
  CREATE_PUSH_MUTATION,
  UPDATE_PUSH_MUTATION,
  DELETE_PUSH_MUTATION,
};
