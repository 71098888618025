import React from 'react';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
// import AppBreadcrumb from 'components/layout-components/AppBreadcrumb';
import IntlMessage from '../util-components/IntlMessage';
import { FormattedMessage } from 'react-intl';
import utils from 'utils';
import { Grid } from 'antd';

export const PageHeader = ({ title, display, subtitle, isTablet }) => {
  const { useBreakpoint } = Grid;
  return display ? (
    <div className="app-page-header mb-0 mr-3 d-block">
      <span className="font-weight-semibold font-size-lg">
        <IntlMessage id={title ? title : 'home'} />
      </span>
      {subtitle &&
        (isTablet === true ? (
          <Tooltip
            className="ml-1 text-muted"
            placement="topRight"
            title={<FormattedMessage id={subtitle} />}
          >
            <QuestionCircleOutlined />
          </Tooltip>
        ) : (
          <h5 className="text-muted">
            <FormattedMessage id={subtitle} />
          </h5>
        ))}
      {/* <AppBreadcrumb /> */}
    </div>
  ) : null;
};

export default PageHeader;
