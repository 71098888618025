import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Modal, Button, Input, message, Form, Col, Row, Grid } from 'antd';
import { SaveOutlined, CloseCircleOutlined } from '@ant-design/icons';

import { setCurrentProfile } from 'redux/actions/Project';
import utils from 'utils';

import { UPDATE_PROFILE_MUTATION } from '../../../graphql/mutations/profile';

const UserEdit = (props) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();
  const { visible, setVisible, current_profile, setCurrentProfile } = props;
  const { useBreakpoint } = Grid;
  const [profile_name, setProfile_name] = useState('');
  const [profile_url, setProfile_url] = useState('');

  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION, {
    onCompleted(result) {
      if (result?.updateUser?.user?.id) {
        setCurrentProfile({
          ...current_profile,
          profile: profile_url,
          local: { ...current_profile?.local, name: profile_name },
        });

        message.success({
          content: formatMessage({
            id: 'common.message.save.success',
          }),
          key: 'updateProfile',
        });

        onChildrenModalClose();
      } else {
        message.error({
          content: formatMessage({
            id: 'common.message.save.fail',
          }),
          key: 'updateProfile',
        });
      }
    },
  });

  useEffect(() => {
    console.log(current_profile);
    setProfile_name(current_profile?.local?.name);
    setProfile_url(current_profile?.profile);
  }, [current_profile]);

  // Functions
  const onUpdateUser = () => {
    if (!current_profile?.id) {
      message.error({
        content: formatMessage({
          id: 'common.message.error1',
        }),
      });
      return;
    }

    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateProfile',
      duration: 0,
    });

    updateProfile({
      variables: {
        id: utils.getID(current_profile?.id),
        name: profile_name,
        profile: profile_url,
      },
    });
  };

  const onSave = () => {
    form
      .validateFields()
      .then((values) => {
        onUpdateUser(values);
      })
      .catch((info) => {
        console.error('Validate Failed:', info);
      });
  };

  const onChildrenModalClose = () => {
    form.resetFields();

    setProfile_name(current_profile?.local?.name);
    setProfile_url(current_profile?.profile);
    setVisible(false);
  };

  return (
    <Modal
    open={visible}
      width={utils.isTablet(useBreakpoint()) ? '80%' : 600}
      closable={false}
      forceRender
      destroyOnClose
      onCancel={onChildrenModalClose}
      title={
        <>
          <FormattedMessage id="profile.menu1" />
        </>
      }
      footer={
        <div className="alignRight">
          <Button
            size="small"
            onClick={onChildrenModalClose}
            icon={<CloseCircleOutlined className="mr-2" />}
          >
            <FormattedMessage id="common.cancel" />
          </Button>
          <Button
            size="small"
            type="primary"
            icon={<SaveOutlined className="mr-2" />}
            onClick={() => form.submit()}
          >
            <FormattedMessage id="common.update" />
          </Button>
        </div>
      }
    >
      <Form form={form} layout="vertical" onFinish={onSave}>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="profile.edit.label.email" />}
            >
              {current_profile?.local?.email}
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="settings.user.label.user_name" />}
            >
              <Input
                className="w-100"
                size="small"
                placeholder={formatMessage({
                  id: 'settings.user.placeholder.user_name',
                })}
                value={profile_name}
                onChange={(e) => setProfile_name(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col span={24}>
            <Form.Item
              label={<FormattedMessage id="chat.admin.label.profile" />}
            >
              <Input
                className="w-100"
                size="small"
                placeholder={formatMessage({
                  id: 'chat.admin.placeholder.profile',
                })}
                value={profile_url}
                onChange={(e) => setProfile_url(e.target.value)}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </Modal>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps, {
  setCurrentProfile,
})(UserEdit);
