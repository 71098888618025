import gql from 'graphql-tag';

const Analytics_QUERY = gql`
  query Analytics(
    $projectId: String!
    $type: String!
    $startDate: String!
    $endDate: String!
  ) {
    analytics(
      projectId: $projectId
      type: $type
      startDate: $startDate
      endDate: $endDate
    ) {
      totalCount
      edges {
        node {
          date
          count
          updated_at
        }
      }
    }
  }
`;

const CCU_ANALYTICS_QUERY = gql`
  query CCUANALYTICS_QUERY(
    $startDate: String
    $endDate: String
    $projectId: String
  ) {
    ccuAnalytics(
      startDate: $startDate
      endDate: $endDate
      projectId: $projectId
    ) {
      totalCount
      edges {
        node {
          date
          count
        }
      }
    }
  }
`;


const CCULOGS_QUERY = gql`
  query CCULOGS_QUERY($projectId: String!, $firstDate: String!, $secondDate: String!, $threeDate: String!) {
  cculogs(
    projectId: $projectId
    firstDate: $firstDate
    secondDate: $secondDate
    threeDate: $threeDate
  ) {
    totalCount
    edges {
      node {
        value
        store_id
        createdAt
        one
        two
        three
      }
    }
  }
}
`;

const MESSAGE_ANALYTICS_QUERY = gql`
  query MESSAGEANALYTICS_QUERY(
    $startDate: String!
    $endDate: String!
    $projectId: String!
  ) {
    messageAnalytics(
      startDate: $startDate
      endDate: $endDate
      projectId: $projectId
    ) {
      totalCount
      edges {
        node {
          date
          count
        }
      }
    }
  }
`;


const USAGES_QUERY = gql`
  query usages($projectId: String!, $startDate: String!, $endDate: String!) {
    usages(projectId: $projectId, startDate: $startDate, endDate: $endDate) {
      mau_use
      mau_max
      sens_use
      sens_max
      papago_use
      papago_max
      integration_use
      integration_max
    }
  }
`;

export {
  Analytics_QUERY,
  CCULOGS_QUERY,
  CCU_ANALYTICS_QUERY,
  MESSAGE_ANALYTICS_QUERY,
  USAGES_QUERY,
};
