import gql from 'graphql-tag';

const CREATE_SMS_MUTATION = gql`
  mutation createSms(
    $projectId: String
    $sendType: String
    $smsType: String
    $contentType: String
    $from: String
    $to: String
    $title: String
    $body: String
    $userdata: String
    $tag: String
    $file: String
    $scheduleAt: String!
  ) {
    createSms(
      input: {
        projectId: $projectId
        sendType: $sendType
        smsType: $smsType
        contentType: $contentType
        from: $from
        to: $to
        title: $title
        body: $body
        userdata: $userdata
        tag: $tag
        file: $file
        scheduleAt: $scheduleAt
      }
    ) {
      sms {
        id
      }
    }
  }
`;

const UPDATE_SMS_MUTATION = gql`
  mutation updateSms(
    $id: ID!
    $status: Int
    $smsType: String
    $projectId: String
    $contentType: String
    $sendType: String
    $from: String
    $to: String
    $title: String
    $body: String
    $userdata: String
    $tag: String
    $file: String
    $scheduleAt: String!
  ) {
    updateSms(
      input: {
        id: $id
        status: $status
        projectId: $projectId
        smsType: $smsType
        contentType: $contentType
        sendType: $sendType
        from: $from
        to: $to
        title: $title
        body: $body
        userdata: $userdata
        tag: $tag
        file: $file
        scheduleAt: $scheduleAt
      }
    ) {
      sms {
        id
      }
    }
  }
`;

const DELETE_SMS_MUTATION = gql`
  mutation deleteSms($id: ID!, $projectId: String!) {
    deleteSms(input: { id: $id, projectId: $projectId }) {
      sms {
        id
      }
    }
  }
`;


export {
  CREATE_SMS_MUTATION,
  UPDATE_SMS_MUTATION,
  DELETE_SMS_MUTATION,
};
