import React from 'react';
import { useMutation } from '@apollo/client';
import { connect } from 'react-redux';
import { Form, Button, Input, Row, Col, message } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { FormattedMessage, useIntl } from 'react-intl';

import Utils from '../../../utils';

import { UPDATE_PROFILE_MUTATION } from '../../../graphql/mutations/profile';

const ChangePassword = (props) => {
  const [form] = Form.useForm();
  const { formatMessage } = useIntl();

  const { current_profile } = props;

  const [updateProfile] = useMutation(UPDATE_PROFILE_MUTATION, {
    onCompleted(result) {
      if (result?.updateUser?.user?.id) {
        message.success({
          content: formatMessage({
            id: 'profile.changepassword.message.success',
          }),
          key: 'updateProfile',
        });
        form.resetFields();
      } else {
        message.error({
          content: formatMessage({
            id: 'profile.changepassword.message.fail',
          }),
          key: 'updateProfile',
        });
      }
    },
  });

  const onFinish = (value) => {
    message.loading({
      content: formatMessage({
        id: 'common.message.updating',
      }),
      key: 'updateProfile',
      duration: 0,
    });

    if (!Utils.getID(current_profile?.id)) {
      message.error({
        content: formatMessage({
          id: 'common.message.error1',
        }),
        key: 'updateProfile',
      });
      return;
    }

    updateProfile({
      variables: {
        id: Utils.getID(current_profile?.id),
        oldPassword: value.currentPassword,
        newPassword: value.newPassword,
      },
    });
  };

  return (
    <>
      <h2 className="mb-4">
        <FormattedMessage id="profile.changepassword.title" />
      </h2>
      <Row>
        <Col xs={24} sm={24} md={24} lg={12}>
          <Form
            form={form}
            name="changePasswordForm"
            layout="vertical"
            onFinish={onFinish}
          >
            <Form.Item
              label={
                <FormattedMessage id="profile.changepassword.label.currentPassword" />
              }
              name="currentPassword"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="profile.changepassword.rules.currentPassword" />
                  ),
                },
              ]}
            >
              <Input.Password size="small" />
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id="profile.changepassword.label.newPassword" />
              }
              name="newPassword"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="profile.changepassword.rules.newPassword" />
                  ),
                },
                {
                  pattern: Utils.regPW,
                  message: <FormattedMessage id="common.error.password" />,
                },
              ]}
            >
              <Input.Password size="small" />
            </Form.Item>
            <Form.Item
              label={
                <FormattedMessage id="profile.changepassword.label.confirmPassword" />
              }
              name="confirmPassword"
              rules={[
                {
                  required: true,
                  message: (
                    <FormattedMessage id="profile.changepassword.rules.confirmPassword" />
                  ),
                },
                ({ getFieldValue }) => ({
                  validator(rule, value) {
                    if (!value || getFieldValue('newPassword') === value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      <FormattedMessage id="profile.changepassword.error1" />
                    );
                  },
                }),
              ]}
            >
              <Input.Password size="small" />
            </Form.Item>
            <Button
              size="small"
              type="primary"
              icon={<SaveOutlined className="mr-2" />}
              htmlType="submit"
            >
              <FormattedMessage id="common.save" />
            </Button>
          </Form>
        </Col>
      </Row>
    </>
  );
};

const mapStateToProps = ({ project }) => {
  const { current_profile } = project;
  return { current_profile };
};

export default connect(mapStateToProps)(ChangePassword);
