import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Badge } from 'antd';
import moment from 'moment';

class Utils {
  static getBlockStatus(memberblock, deleted_at) {
    // 탈퇴
    if (deleted_at) {
      return (
        <span className="ml-2 text-dark">
          <Badge
            status="default"
            text={<FormattedMessage id="member.list.columns.status.delete" />}
          />
        </span>
      );
    }
    const ttlSeconds = moment(memberblock?.ended_at).diff(moment(), 'second');
    if (memberblock?.status === 1 && ttlSeconds>=0) {
      // 영구정지
      if (memberblock?.ended_at.substr(0, 4) === '2099') {
        return (
          <span className="ml-2 text-dark">
            <Badge
              status="error"
              text={
                <FormattedMessage id="member.list.columns.status.permanent" />
              }
            />
          </span>
        );
      }

      // 일시정지
      return (
        <span className="ml-2 text-dark">
          <Badge
            status="warning"
            text={<FormattedMessage id="member.list.columns.status.pause" />}
          />
        </span>
      );
    }

    // 정상
    return (
      <span className="ml-2 text-dark">
        <Badge
          status="success"
          text={<FormattedMessage id="member.list.columns.status.normal" />}
        />
      </span>
    );
  }
}

export default Utils;
